/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable-next-line react/jsx-boolean-value */
// eslint-disable-next-line no-unneeded-ternary
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-console */

import React, { useCallback, useState, useEffect } from 'react'
// import styles from './VCBCAddFrom.module.css'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@material-ui/core'
import Switch from '@mui/material/Switch'
import WarningIcon from '@mui/icons-material/Warning'
// import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteForeverRounded'
// import { Grid } from '@material-ui/core'

import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
// import { useHistory } from 'react-router-dom'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
// import ValidatingTextFieldNoScrollNSymbol from '../../components/FormComponents/ValidatingTextFieldNoScrollNSymbol';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styles from './AFSForm.module.css'
import AFSstyles from './AnnualFinancialStatement.module.css'
import {
  CustomDatePicker,
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import { Button } from '../../atomicComponents'
import ValidatingTextFieldNoScrollNSymbol from '../../components/FormComponents/ValidatingTextFieldNoScrollNSymbol'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'
import {
  MESSAGE_TYPE,
  MIS_TMLBSL_data_for_PAN_for_both,
  MIS_Purchase_data_for_PAN,
  MIS_TMLBSL_data_for_PAN_for_both_admin,
  MIS_Purchase_data_for_PAN_Admin
} from '../../constants'
import CustomTextareaAutosize from '../../components/FormComponents/TextareaAutosize'
import { Validation_awaited, ReValidation_awaited, Validation_Completed, ReSubmission_Awaited, Not_Submitted, Submitted } from './constants'

const AFSForm = vendorCode => {
  const auth = useSelector(state => state.auth)
  // const history = useHistory()
  const { userRole, userName } = auth
  const { editableByUser } = vendorCode

  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: ''
  })
  const [fileSuccessFlag, setFileSuccessFlag] = useState(false)
  const [acceptedFiles1, setAcceptedFiles1] = useState([])
  const [openL1Dialog, setOpenL1Dialog] = useState(false)
  // const [URLandUserType, setURLandUserType] = useState({})
  const [updateFlag, setUpdateFlag] = useState(false)
  const [ppaDate, setPpaDate] = useState('')
  const [TML_PurchaseValidationCheckFlag, setTML_PurchaseValidationCheckFlag] =
    useState(false)
  const [vendorSubmitFlag, setVendorSubmitFlag] = useState()

  const TMLBSLValidationEmailComment = vendorCode?.manualFormData?.TMLBSL_Validation_Email_comment
  const TMLBSLValidationStatus = vendorCode?.buttonStatusData?.TMLBSL_Validation_Status
  const PurchaseValidationEmailComment = vendorCode?.manualFormData?.Purchase_Validation_Email_comment
  const PurchaseValidationStatus = vendorCode?.buttonStatusData?.Purchase_Validation_status
  const vendorFYValue = vendorCode?.financialYear?.value
  const Supplier_Status = vendorCode?.buttonStatusData?.Supplier_Status

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')

  const renderDisableFlagVendor = () => {
    let result
    if (vendorCode?.buttonStatusData) {
      if (isAdminUser) {
        result = false
      } else if (
        TMLBSLValidationStatus === Validation_Completed &&
        PurchaseValidationStatus === Validation_Completed
      ) {
        result = true
      } else if (
        TMLBSLValidationStatus === Validation_Completed &&
        PurchaseValidationStatus === Validation_awaited
      ) {
        result = true
      } else if (
        TMLBSLValidationStatus === Validation_Completed &&
        PurchaseValidationStatus === ReValidation_awaited
      ) {
        // result = false
        result = true
      } else if (
        TMLBSLValidationStatus === Validation_awaited &&
        PurchaseValidationStatus === Validation_awaited
      ) {
        result = false
      } else if (
        TMLBSLValidationStatus === ReValidation_awaited &&
        PurchaseValidationStatus === Validation_awaited
      ) {
        result = false
      } else if (
        TMLBSLValidationStatus === ReValidation_awaited &&
        PurchaseValidationStatus === ReValidation_awaited
      ) {
        result = false
      }
    }
    return result
  }
  const isButtonDisabled = renderDisableFlagVendor()


  useEffect(() => {
    renderDisableFlagVendor()
  }, [updateFlag])

  const newDate = new Date();
  const formattedDate = newDate.toLocaleDateString('en-GB')

  const baseUrl = useSelector(state => state.counter.baseUrl)

  const { showPopup } = usePopupManager()

  const handleOpenL1 = () => setOpenL1Dialog(true)
  const handleCloseL1 = () => setOpenL1Dialog(false)

  const handlepopup = message => {
    showPopup({
      type: MESSAGE_TYPE.INFORMATION,
      contextText: MESSAGE_TYPE.INFORMATION,
      info: message
    })
  }

  const onDrop = useCallback(
    acceptedFiles => {
      vendorCode?.setAcceptedFiles1(acceptedFiles)

      const fileSizeInBytes = acceptedFiles[0]?.size
      const fileSizeInKB = fileSizeInBytes / 1024 // Convert to kilobytes
      const fileSizeInMB = fileSizeInKB / 1024 // Convert to megabytes

      try {
        if (acceptedFiles.length !== 1) {
          handlepopup('Please upload exactly one PDF file.')
          return
        }

        const isPdf = acceptedFiles[0].type === 'application/pdf'
        if (!isPdf) {
          handlepopup('Please upload a PDF file.')
          return
        }

        const fileName = acceptedFiles[0].name
        const hasDoubleExtension = /\.\w+\./.test(fileName) // Checks for a dot followed by characters followed by another dot
        if (hasDoubleExtension) {
          handlepopup('File cannot have double extensions.')
          return
        }

        if (fileSizeInMB > 150) {
          handlepopup('File size should be less than 150 MB.')
        } else {
          const formData = new FormData()
          formData.append('Vendor_Code', vendorCode?.vendorCode)
          formData.append('file', acceptedFiles[0])
          formData.append('Financial_year', vendorCode?.financialYear?.value)
          setFileInfo({
            name: acceptedFiles[0]?.name,
            size: acceptedFiles[0].size
          })

          axios
            .post(`${baseUrl}Annual_financial_statements1/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              setFileSuccessFlag(true)
              if (response.data) {
                showPopup({
                  type: MESSAGE_TYPE.SUCCESS,
                  contextText: MESSAGE_TYPE.SUCCESS,
                  info: 'AFS Document Uploaded Successfully!'
                })
              }
            })
            .catch(error => {
              console.error('API Error 1:', error)
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Error while uploading document.'
              })
              setFileInfo({})
            })
        }
      } catch (error) {
        console.error('API Error:', error)
      }
    },
    [vendorCode?.financialYear?.value]
  )


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: isButtonDisabled })
  const onChangeHandler = event => {
    const { name, value } = event.target
    // const isNumeric = /^(?!0\d)(?!0*\.0*$)\d*(\.\d{0,2})?$/.test(value)
    const isNumeric = /^(?!0\d)\d{0,5}(\.\d{0,2})?$/.test(value)

    const isAlphanumeric_saTenure = /^[a-zA-Z0-9]{0,30}$/.test(value) // without 10 digit validation
    const isAlphanumeric = /^[a-zA-Z0-9]{0,10}$/.test(value) // with 10 digit validation
    const isAllowed = isNumeric || /^\d+\.$/.test(value)
    const isAlphabetic = /^[a-zA-Z][a-zA-Z ]{0,19}$/.test(value)

    /* For 3 textareas */
    const isAllowedCharacters = /^[a-zA-Z0-9.?, ]*$/.test(value)

    /* For admin comment section */
    const isAllowedCharactersForAdminComment = /^[a-zA-Z0-9.,? ]{0,250}$/.test(value)

    if (isAllowed && name !== 'IEC_Code') {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
    if (isAlphanumeric && name === 'IEC_Code') {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
    if (isAlphabetic && name === 'SA_Name') {
      // console.log('SA_name name: ', name)
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
    if (isAlphanumeric_saTenure && name === 'SA_tenure') {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }

    if (
      (isAllowedCharacters && name === 'TMLBSL_Validation_Email_comment') ||
      (isAllowedCharacters && name === 'Purchase_Validation_Email_comment')
    ) {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
    if (name === 'Admin_Validation_comment' && isAllowedCharactersForAdminComment) {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }

  }

  const handleSelect = (selectedOption, name) => {
    // console.log('Copies name: ', name);
    // console.log('Copies name option: ', selectedOption);
    if (selectedOption == null) {
      vendorCode.setManualFormData(prevData => ({
        ...prevData,
        [name]: null
      }))
      return
    }
    vendorCode.setManualFormData(prevData => ({
      ...prevData,
      [name]: selectedOption
    }))
  }

  // File size calculation function
  // function calculateSize() {
  //   // eslint-disable-next-line no-nested-ternary
  //   return fileInfo.size < 1024
  //     ? `${fileInfo.size} bytes`
  //     : fileInfo.size < 1024 * 1024
  //     ? `${(fileInfo.size / 1024).toFixed(2)} KB`
  //     : `${(fileInfo.size / (1024 * 1024)).toFixed(2)} MB`
  // }

  function calculateSize () {
    console.log('File Size: ', fileInfo)
    
    if (fileInfo.size < 1024) {
      return `${fileInfo.size} bytes`
    }
    if (fileInfo.size < 1024 * 1024) {
      return `${(fileInfo.size / 1024).toFixed(2)} KB`
    }
    if (fileInfo.size < 150 * 1024 * 1024) {
      return `${(fileInfo.size / (1024 * 1024)).toFixed(2)} MB`
    }
    return 'File is larger than 150 MB'
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  }))

  const handleSwitchChange = () => {
    vendorCode.setIecChecked(prevChecked => !prevChecked)
    vendorCode.setManualFormData(prevData => ({
      ...prevData,
      IEC_Code: ''
    }))
  }

  // Download AFS Document API
  const downloadAfsCertificate = () => {
    const formData = new FormData()
    formData.append('Vendor_code', vendorCode?.vendorCode)
    formData.append('Financial_year', vendorCode?.financialYear?.value)

    fetch(`${baseUrl}DownloadSupplierAfsFile/`, {
      method: 'POST',
      body: formData
    })
      .then(async response => {
        const res = await response.json()
        if (res?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Vendor has not uploaded AFS document.'
          })
        } else {
          // eslint-disable-next-line camelcase
          const aws_url = res?.url
          const link = document.createElement('a')
          // eslint-disable-next-line camelcase
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
        }
      })
      .catch(error => {
        console.log('Error while calling DownloadSupplierAfsFile API: ', error)
      })
  }


  // const Copies = vendorCode?.manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted?.value
  // console.log('Copies 121: ', vendorCode?.manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted?.value)
  // console.log('Copies F/T: ', Copies === 'Yes' ? true : false) 

  const confirmL1Popup = () => {
    const apiFormatedData = {
      Vendor_Code: vendorCode?.vendorCode,
      Financial_year: vendorCode?.financialYear?.value,
      Admin_Validation_comment:
        vendorCode.manualFormData?.Admin_Validation_comment,
      TMLBSL_Validator_Name: userName,
      TMLBSL_Validation_Date: formattedDate,
      Test11: vendorCode?.manualFormData?.SA_tenure,
      // Copies_of_AFS_of_suppliers_in_last_FY_submitted: Copies === 'Yes' || 'Yes' ? true : false
      Copies_of_AFS_of_suppliers_in_last_FY_submitted: vendorCode?.manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted?.value === 'Yes' ? true : false
    }
    const apiFormatedDataPurchase = {
      Vendor_Code: vendorCode?.vendorCode,
      Financial_year: vendorCode?.financialYear?.value,
      Admin_Validation_comment:
        vendorCode.manualFormData?.Admin_Validation_comment,
      Purchase_PMT_Validator_Name: userName,
      Purchase_PMT_Validation_Date: formattedDate,
      Test11: vendorCode?.manualFormData?.SA_tenure,
      // Copies_of_AFS_of_suppliers_in_last_FY_submitted: Copies === 'Yes' || 'Yes' ? true : false
      Copies_of_AFS_of_suppliers_in_last_FY_submitted: vendorCode?.manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted?.value === 'Yes' ? true : false
    }

    // console.log('API request: ', apiFormatedData)
    const apiFormatedDataJson = JSON.stringify(
      // vendorCode?.URLandUserType?.apiURL === MIS_TMLBSL_data_for_PAN_for_both
      vendorCode?.URLandUserType?.apiURL === MIS_TMLBSL_data_for_PAN_for_both_admin
        ? apiFormatedData
        : apiFormatedDataPurchase
    )
    const headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}${vendorCode?.URLandUserType?.apiURL}/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(() => {
        vendorCode?.CheckOverallStatusAPI()
        handleCloseL1()
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: `Data is Succesfully Validated on behalf of ${vendorCode?.URLandUserType?.userType}`
        })
        setUpdateFlag(true)
        /* Calling status API after validating on behalf of L1 and L2 */
        vendorCode?.getStatusDataForButtonAPIView()
      })
      .catch(error => {
        console.log('Error while calling CheckOverAllStatus API', error)
      })
  }


  const goNextForm = apiURL => {
    let userType = ''
    // if (apiURL === MIS_TMLBSL_data_for_PAN_for_both) {
    if (apiURL === MIS_TMLBSL_data_for_PAN_for_both_admin) {
      userType = 'TMLBSL User'
    } else {
      // userType = 'Purchase Buyer'
      userType = 'PMT'
    }
    vendorCode?.setURLandUserType({
      apiURL,
      userType
    })
  }

  const sendBackRevalidateAPI = () => {
    let url = ''
    let apiBody = {}
    if (isPurchaseUser) {
      url = 'MIS_Purchase_ReValidationAwaited_Send_Email2'
      apiBody = {
        Financial_year: vendorCode?.financialYear?.value,
        Vendor_Code: vendorCode?.vendorCode,
        Purchase_Validation_Email_comment:
          vendorCode?.manualFormData?.Purchase_Validation_Email_comment,
        Purchase_PMT_Validation_Date: formattedDate,
        Purchase_PMT_Validator_Name: userName
      }
    } else {
      url = 'MIS_TMLBSL_ReValidationAwaited_Send_Email2'
      apiBody = {
        Financial_year: vendorCode?.financialYear?.value,
        Vendor_Code: vendorCode?.vendorCode,
        TMLBSL_Validation_Email_comment:
          vendorCode.manualFormData?.TMLBSL_Validation_Email_comment,
        TMLBSL_Validation_Date: formattedDate,
        TMLBSL_Validator_Name: userName
      }
    }
    const isEmptyOrInvalid = value =>
      value === null || value === undefined || value === '' || value.length < 2
    if (
      (isGDCUser &&
        isEmptyOrInvalid(
          vendorCode.manualFormData?.TMLBSL_Validation_Email_comment
        )) ||
      (isPurchaseUser &&
        isEmptyOrInvalid(
          vendorCode.manualFormData?.Purchase_Validation_Email_comment
        ))
    ) {
      const message =
        vendorCode.manualFormData?.TMLBSL_Validation_Email_comment === '' ||
        vendorCode.manualFormData?.Purchase_Validation_Email_comment === ''
          ? 'Comment cannot be empty!'
          : 'Please enter valid remarks before sending back!'
      handlepopup(message)
    } else {
      const apiBodyJson = JSON.stringify(apiBody)
      const headerss = new Headers()
      headerss.append('Content-Type', 'application/json')
      fetch(`${baseUrl}${url}/`, {
        method: 'POST',
        headers: headerss,
        body: apiBodyJson
      })
        .then(response => response.json())
        .then(() => {
          vendorCode?.getStatusDataForButtonAPIView()
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: `Sent back for Re-Submission.`
          })
          setUpdateFlag(true)
        })
        .catch(error => {
          console.log('Error', error)
        })
    }
  }

  const setButtonColor = buttonType => {
    let buttonColor = ''
    if (vendorCode?.buttonStatusData) {
      // if (buttonType === MIS_TMLBSL_data_for_PAN_for_both) {
      if (buttonType === MIS_TMLBSL_data_for_PAN_for_both_admin) {
        if (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Not submitted' || vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation_awaited') {
          buttonColor = '#59a385'
        } else if (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Re-Validation awaited') {
          buttonColor = '#ffa222'
        } else {
          buttonColor = '#00000042'
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Not submitted' || vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Validation_awaited') {
          buttonColor = '#59a385'
        } else if (vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Re-Validation awaited') {
          buttonColor = '#ffa222'
        } else {
          buttonColor = '#00000042'
        }
      }
    }
    return buttonColor
  }

  const handleDate = (date, name) => {
    //  let newvalue = convert(date);
    // if (name == "reported") {
    //   alert(date.format(DATE_FORMAT.ISO))
    //  alert(date.format(DATE_FORMAT.ISO))
    // setReportedDate(date.format('YYYY-MM-DD'))
    // setState((prevData) => ({ ...prevData, errorFlagReported: false, errorReportedDateMsg: "" }));

    // } else {
    //  setState((prevData) => ({ ...prevData, errorFlagHandoverDate:false}));

    // setHandoverDate(date.format('YYYY-MM-DD'))
    // console.log('date: ', date)
    // console.log('date name: ', name)
    // setPpaDate(date.format('YYYY-MM-DD'))
    // setPpaDate(date.format('dd-mm-yyyy'))
    vendorCode.setManualFormData(prevData => ({
      ...prevData,
      Date_of_PPA: date
    }))
    // setState((prevData) => ({ ...prevData, errorFlagHandoverDate: false, errorHandoverDateMsg: "" }));

    // }
  }

  /* Summation and average calculations */
  // const parsedTotalGoods = parseFloat(vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore) || 0
  // const parsedTotalServices = parseFloat(vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore) || 0
  // const totalImportInLastFY = parseFloat(vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore) || 0 +
  // parseFloat(vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore) || 0

  // const totalImportInLastFY = parsedTotalGoods + parsedTotalServices
  // console.log('totalImportInLastFY int', typeof(totalImportInLastFY))
  // console.log('totalImportInLastFY', totalImportInLastFY)

  // const parsedEstimated_Import_of_Goods_attributable = parseFloat(vendorCode?.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore) || 0
  // const parsedEstimated_Import_of_Services_attributable_to_Applicant = parseFloat(vendorCode?.manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore) || 0

  // const totalEstimatedImportAttributableApplicant = parsedEstimated_Import_of_Goods_attributable + parsedEstimated_Import_of_Services_attributable_to_Applicant
  // console.log('totalEstimatedImportAttributableApplicant', totalEstimatedImportAttributableApplicant)

  // const parsedPPE_at_the_start_of_last_FY = parseFloat(vendorCode?.manualFormData?.PPE_at_the_start_of_last_FY) || 0
  // const parsedPPE_At_The_Close_of_last_FY = parseFloat(vendorCode?.manualFormData?.PPE_At_The_Close_of_last_FY) || 0

  // const avgParsedPPE = (parsedPPE_at_the_start_of_last_FY + parsedPPE_At_The_Close_of_last_FY) / 2
  // console.log('avgParsedPPE', avgParsedPPE)

  // console.log('vendorCode?.buttonStatusData11: ', vendorCode?.buttonStatusData)

  const disabledTextAreaStyle = {
    height: '37px',
    overflow: 'auto',
    width: '100%',
    padding: '0px 0px 0px 5px',
    fontFamily: 'Roboto',
    // fontSize: '0.7rem',
  }

  // console.log('calcRevenueToPPERatio: ', vendorCode?.calcRevenueToPPERatio)

  const renderDisableFlag = () => {
    let result;

    if (isGDCUser) {
      if (vendorCode?.buttonStatusData) {
        if (vendorCode.buttonStatusData.TMLBSL_Validation_Status === 'Validation_awaited' || vendorCode.buttonStatusData.TMLBSL_Validation_Status === 'Re-Validation awaited') {
          result = false;
        } else {
          result = true;
        }
      } else {
        result = true;
      }
    } else {
      result = true;
    }
    // console.log('result in if', result);
    return result
  // let flag
    // console.log('flag inside 1:', flag);
    // if (isGDCUser) {
    //   if (vendorCode?.buttonStatusData) {
    //     if (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation_awaited' || vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Re-Validation awaited') {
    //       flag = false
    //     } else {
    //       flag =  true
    //     }
    //   }
    // }
    // console.log('flag inside 2:', flag);
    // return flag
  }

  const renderOther = () => (
    <div className={styles.formGroup}>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '-14px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Vendor Code
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              value={vendorCode?.vendorCode}
              name='vendorCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
          {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Related Vendor Code(s)
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextFieldNoScrollNSymbol
              // <CustomTextareaAutosize
              variant='outlined'
              size='small'
              fullWidth
              multiline
              value={vendorCode?.relatedVendorCode}
              name='relatedVendorCode'
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
              // style={disabledTextAreaStyle}
            />
          </div>
        </Grid>


        {/* New outline */}
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Name of Supplier
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextFieldNoScrollNSymbol
              variant='outlined'
              size='small'
              fullWidth
              // multiline
              // onChange={onChangeHandler}
              name='nameOfSupplier'
              value={vendorCode?.supplierName}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid>
        
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              PAN
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              // multiline
              // onChange={onChangeHandler}
              name='PAN'
              value={vendorCode?.pan}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid>
      </Grid>
      {/* <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Name of Supplier<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              // onChange={onChangeHandler}
              name='model'
              value={vendorCode?.supplierName}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              GSTIN
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              // multiline
              onChange={onChangeHandler}
              name='GSTIN'
              value={vendorCode?.gstin}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid>

        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Related GSTIN
              {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            {/* <ValidatingTextFieldNoScrollNSymbol */}
            <CustomTextareaAutosize
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='RELATED_GSTIN'
              value={vendorCode?.relatedGstin}
              // value='27AAAAP0267H2ZN, 20AAMCP5213D1ZY, 27AAAAP0267H2ZN, 20AAMCP5213D1ZY'
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
              // style={{ height: '37px',overflow: 'auto', width: '100%', padding: '0px 0px 0px 5px' }}
              style={disabledTextAreaStyle}
            />
          </div>
        </Grid>
      </Grid>
      {/* <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Tier<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              // onChange={onChangeHandler}
              name='Tier'
              value={vendorCode?.tier}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        {/* <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              GSTIN<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='GSTIN'
              value={vendorCode?.gstin}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid> */}


        {/* SPOC Field */}
        {/* {
          !userRole.includes('Vendor') && (
            <> */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    SPOC
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <ValidatingTextFieldNoScrollNSymbol
                    variant='outlined'
                    size='small'
                    fullWidth
                    // multiline
                    // onChange={onChangeHandler}
                    name='nameOfSupplier'
                    value={vendorCode?.supplierName}
                    placeholder=''
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
              </Grid> */}

        {/* CTL Field */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    CTL
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <ValidatingTextFieldNoScrollNSymbol
                    variant='outlined'
                    size='small'
                    fullWidth
                    // multiline
                    // onChange={onChangeHandler}
                    name='nameOfSupplier'
                    value={vendorCode?.supplierName}
                    placeholder=''
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
              </Grid> */}

        {/* GDC Verifier */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    GDC Verifier
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <ValidatingTextFieldNoScrollNSymbol
                    variant='outlined'
                    size='small'
                    fullWidth
                    // multiline
                    // onChange={onChangeHandler}
                    name='nameOfSupplier'
                    value={vendorCode?.supplierName}
                    placeholder=''
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
              </Grid> */}

        {/* Purchase Approver Field */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    Purchase Approver
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <ValidatingTextFieldNoScrollNSymbol
                    variant='outlined'
                    size='small'
                    fullWidth
                    // multiline
                    // onChange={onChangeHandler}
                    name='nameOfSupplier'
                    value={vendorCode?.supplierName}
                    placeholder=''
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
              </Grid> */}

        {/* PMT Approver Field */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    PMT Approver
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <ValidatingTextFieldNoScrollNSymbol
                    variant='outlined'
                    size='small'
                    fullWidth
                    // multiline
                    // onChange={onChangeHandler}
                    name='nameOfSupplier'
                    value={vendorCode?.supplierName}
                    placeholder=''
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
              </Grid> */}
        {/* </>
          )
        } */}

        <Grid
          item
          xs={3}
          className={styles.alignCenter}
          style={{ justifyContent: 'space-between' }}
        >
          <div className={clsx(styles.lableDiv)}>
            <label>
              IEC Code<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          {/* <Switch /> */}

          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            style={{
              margin: '0px 37px 0px 0px',
              fontSize: '14px',
              letterSpacing: '0px',
              color: '#707070',
              opacity: 1
            }}
          >
            <label>No</label>
            <AntSwitch
              defaultChecked={false}
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={handleSwitchChange}
              checked={vendorCode?.iecChecked}
              disabled={editableByUser}
            />
            <label>Yes</label>
          </Stack>
        </Grid>
        {/* <Grid item xs={2} className={styles.alignCenter}> */}
        {/* <div className={clsx(styles.radioLabel)}>
            <label>
              Yes
            </label>
          </div>
          <Radio
            // checked={selectedValue === 'Yes'}
            // onChange={handleChange}
            onChange={e => {
              handleSelect(e, 'Yes')
            }}
            value="Yes"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
          <div className={clsx(styles.radioLabel)}>
            <label>
              No
            </label>
          </div>
          <Radio
            // checked={selectedValue === 'No'}
            // onChange={handleChange}
            onChange={e => {
              handleSelect(e, 'No')
            }}
            value="No"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          /> */}
        {/* </Grid>x */}
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              className={clsx(styles.inputColor)}
              variant='outlined'
              size='small'
              fullWidth
              // multiline
              onChange={onChangeHandler}
              name='IEC_Code'
              value={vendorCode.manualFormData?.IEC_Code}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input',
                style: { color: '#707070' }
              }}
              maxRows={1}
              // eslint-disable-next-line react/jsx-boolean-value
              // eslint-disable-next-line no-unneeded-ternary
              // isDisabled={vendorCode?.iecChecked === true ? false : true}
              // isDisabled={(vendorCode.tableArray[0]?.IEC_Code) ? true : false}
              // isDisabled={
              //   (vendorCode?.iecChecked && (userRole.includes('admin') || userRole.includes('Vendor')) ? false : true)
              // }
              // isDisabled={TML_PurchaseValidationCheckFlag || editableByUser}
              isDisabled={
                userRole.includes('TMLBSL') || userRole.includes('Purchase')
                  ? editableByUser
                  : !vendorCode?.iecChecked
              }
              // isDisabled={(editableByUser) && vendorCode?.iecChecked}
            />
          </div>
        </Grid>

        {/* <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
          columns={6}
        > */}

        {/* </Grid> */}

        {/* Commenting Tier as it is not needed Date: 03/01/2024 */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Tier
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              // onChange={onChangeHandler}
              name='Tier'
              value={vendorCode?.tier}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={true}
            />
          </div>
        </Grid> */}

        {/* New Field added "SA Name" Date: 03/01/2024 */}
        {/* Removing SA Name and Copies dropdown fields */}

        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Statutory Auditor Name(SA)
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              // multiline
              onChange={onChangeHandler}
              name='SA_Name'
              value={vendorCode.manualFormData?.SA_Name}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input',
                style: { color: '#707070' }
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
            />
          </div>
        </Grid>

        

        <Grid
          constainer
          style={{
            border: '1px #d5d7da solid',
            borderRadius: '7px',
            marginTop: '15px',
            padding: '8px',
            // backgroundColor: 'antiquewhite'
            backgroundColor: '#eaf5ff',
            width: '100%'
          }}
          spacing={2}
        >
          <Grid
            style={{
              margin: '0px 0px 5px 0px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              fontWeight: 'bold'
            }}
          >
            (BELOW DATA CONSIDERING ALL CUSTOMERS)
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              // marginTop: '15px',
              // border: '1px #d5d7da solid',
              // borderRadius: '7px',
              // margin: '10px 0px 0px 0px'
            }}
            columns={12}
          > 


            <Grid item xs={2} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  Revenue from Operations in last FY
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <div className={clsx(styles.validatationLabel)}>
                  (2 decimal places mandatory)
                </div>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <ValidatingTextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  onChange={onChangeHandler}
                  name='Revenue_from_Operations_in_last_FY_Rs_Crore'
                  // value={vendorCode.manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore ? vendorCode.manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore : vendorCode.tableArray[0]?.Revenue_from_Operations_in_last_FY_Rs_Crore}
                  value={
                    vendorCode.manualFormData
                      ?.Revenue_from_Operations_in_last_FY_Rs_Crore ? vendorCode.manualFormData
                      ?.Revenue_from_Operations_in_last_FY_Rs_Crore : ''
                  }
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input',
                    style: { color: '#707070' }
                  }}
                  isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
                />
              </div>
              <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              marginTop: '15px',
              // border: '1px #d5d7da solid',
              borderRadius: '7px',
              // margin: '10px 0px 0px 0px'
              backgroundColor: '#eaf5ff'
            }}
            columns={12}
          >
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  Total Import of Goods in last FY
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <div className={clsx(styles.validatationLabel)}>
                  (2 decimal places mandatory)
                </div>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <ValidatingTextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  // multiline
                  onChange={onChangeHandler}
                  name='Total_Import_of_Goods_in_last_FY_Rs_Crore'
                  // value={vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore ? vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore : vendorCode.tableArray[0]?.Total_Import_of_Goods_in_last_FY_Rs_Crore}
                  value={
                    vendorCode.manualFormData
                      ?.Total_Import_of_Goods_in_last_FY_Rs_Crore ? vendorCode.manualFormData
                      ?.Total_Import_of_Goods_in_last_FY_Rs_Crore : ''
                  }
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input',
                    style: { color: '#707070' }
                  }}
                  style={{ width: '100%' }}
                  isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
                />
              </div>
              <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  Total Import of Services in last FY
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <div className={clsx(styles.validatationLabel)}>
                  (2 decimal places mandatory)
                </div>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <ValidatingTextFieldNoScrollNSymbol
                  variant='outlined'
                  size='small'
                  fullWidth
                  // multiline
                  onChange={onChangeHandler}
                  name='Total_Import_of_Services_in_last_FY_Rs_Crore'
                  // value={vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore}
                  value={
                    vendorCode.manualFormData
                      ?.Total_Import_of_Services_in_last_FY_Rs_Crore ? vendorCode.manualFormData
                      ?.Total_Import_of_Services_in_last_FY_Rs_Crore : ''
                  }
                  // value={vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore ? vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore : vendorCode.tableArray[0]?.Total_Import_of_Services_in_last_FY_Rs_Crore}
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input',
                    // type: 'number',
                    style: { color: '#707070' }
                  }}
                  // type='number'
                  style={{ width: '100%' }}
                  isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
                />
              </div>
              <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  Total Import in last FY
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </label>
              </div>
            </Grid>
            <Grid item xs={2} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <ValidatingTextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  // multiline
                  onChange={onChangeHandler}
                  name='Total_Imports_in_last_FY_Rs_Crore'
                  // value={
                  //   vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore +
                  //   vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore
                  // }

                  // value={totalImportInLastFY !== 'NaN' ? totalImportInLastFY : null}
                  value={vendorCode?.totalImportInLastFY.toFixed(2)}
                  // value={ vendorCode?.totalImportInLastFY !== 0 ? vendorCode?.totalImportInLastFY.toFixed(2) : null }

                  // value={vendorCode?.manualFormData?.Total_Imports_in_last_FY_Rs_Crore}

                  // value={vendorCode.manualFormData?.Total_Imports_in_last_FY_Rs_Crore ? vendorCode.manualFormData?.Total_Imports_in_last_FY_Rs_Crore : vendorCode.tableArray[0]?.Total_Imports_in_last_FY_Rs_Crore}
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input'
                  }}
                  style={{ width: '100%' }}
                  // eslint-disable-next-line react/jsx-boolean-value
                  isDisabled={true}
                />
              </div>
              <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
            </Grid>
          </Grid>
        </Grid>


        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            margin: '20px 0px 0px 0px',
            border: '1px #d5d7da solid',
            borderRadius: '7px',
            // backgroundColor: 'aquamarine'
            backgroundColor: '#eaf5ff'
            // margin: '10px 0px 0px 0px'
          }}
          columns={12}
        >
                <Grid
          style={{
            margin: '0px 0px 5px 0px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            fontWeight: 'bold'
          }}
        >
          (BELOW DATA CONSIDERING TML(TMLCV, TMLPV & TPEML))
        </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                Estimated Import of Goods attributable to Applicant
                <span style={{ color: 'red' }}>*</span>
                <span className={clsx(styles.validatationLabel)}>
                  {' '}
                  (2 decimal places mandatory)
                </span>
              </label>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore'
                // value={
                //   vendorCode.manualFormData
                //     ?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore
                // }
                value={vendorCode?.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore ? vendorCode?.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore : ''}
                // value={vendorCode.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore ? vendorCode.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore : vendorCode.tableArray[0]?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input',
                  style: { color: '#707070' }
                }}
                style={{ width: '100%' }}
                isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>

          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                Estimated Import of Services attributable to Applicant
                <span style={{ color: 'red' }}>*</span>
                <span className={clsx(styles.validatationLabel)}>
                  {' '}
                  (2 decimal places mandatory)
                </span>
              </label>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv} style={{ color: '#707070' }}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore'
                value={
                  vendorCode.manualFormData
                    ?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore
                }
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input',
                  style: { color: '#707070' }
                }}
                style={{ width: '100%' }}
                isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>

          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                Total Estimated Imports attributable to Applicant
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='Estimated_Import_attributable_to_Applicant_Rs_Crore'
                value={vendorCode?.totalEstimatedImportAttributableApplicant.toFixed(2)}
                // value={vendorCode.manualFormData?.Estimated_Import_attributable_to_Applicant_Rs_Crore ? vendorCode.manualFormData?.Estimated_Import_attributable_to_Applicant_Rs_Crore : vendorCode.tableArray[0]?.Estimated_Import_attributable_to_Applicant_Rs_Crore}
                // value={vendorCode?.manualFormData?.Estimated_Import_attributable_to_Applicant_Rs_Crore}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                style={{ width: '100%' }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>
        </Grid>

        {/* </Grid> */}
        {/* <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              IEC Code<span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='IEC_Code'
              value={vendorCode.manualFormData?.IEC_Code}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // style={{ width: '100%' }}
            />
          </div>
        </Grid>
      </Grid> */}

        {/* <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      > */}
        {/* <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Revenue from Operations in last FY 
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='Revenue_from_Operations_in_last_FY_Rs_Crore'
              value={vendorCode.manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // style={{ width: '100%' }}
            />
          </div>
        </Grid> */}

        {/* </Grid> */}

        {/* <Gridx */}
        {/* <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      /> */}

        {/* </Grid> */}
        {/* <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      >
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Estimated Import of Goods attributable to Applicant
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore'
              value={vendorCode.manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              style={{ width: '100%' }}
            />
          </div>
        </Grid>
      </Grid> */}
        {/* <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
        columns={12}
      > */}

        {/* <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              Estimated Import of Services attributable to Applicant
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid>
        <Grid item xs={3} className={styles.alignCenter}>
          <div className={styles.formDiv} style={{ color: '#707070' }}>
            <ValidatingTextField
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore'
              value={vendorCode.manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input',
                style: { color: '#707070' }
              }}
              style={{ width: '100%' }}
              isDisabled={editableByUser}
            />
          </div>
          <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
        </Grid> */}

        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            margin: '20px 0px 0px 0px',
            border: '1px #d5d7da solid',
            borderRadius: '7px',
            backgroundColor: '#eaf5ff'
            // margin: '10px 0px 0px 0px'
          }}
          columns={12}
        >
          <Grid
            style={{
              margin: '0px 0px 5px 0px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              fontWeight: 'bold'
            }}
          >
            (BELOW DATA CONSIDERING ALL CUSTOMERS)
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                PPE at the start of last FY
                <span style={{ color: 'red' }}>*</span>
              </label>
              <div className={clsx(styles.validatationLabel)}>
                (2 decimal places mandatory)
              </div>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='PPE_at_the_start_of_last_FY'
                value={vendorCode.manualFormData?.PPE_at_the_start_of_last_FY}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input',
                  style: { color: '#707070' }
                }}
                style={{ width: '100%' }}
                isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>

          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                PPE at the close of last FY
                <span style={{ color: 'red' }}>*</span>
              </label>
              <div className={clsx(styles.validatationLabel)}>
                (2 decimal places mandatory)
              </div>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='PPE_At_The_Close_of_last_FY'
                value={vendorCode.manualFormData?.PPE_At_The_Close_of_last_FY}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input',
                  style: { color: '#707070' }
                }}
                style={{ width: '100%' }}
                isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>

          <Grid item xs={2} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>Average PPE for last FY</label>
            </div>
          </Grid>
          <Grid item xs={2} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // multiline
                onChange={onChangeHandler}
                name='Avg_PPE_for_last_FY'
                // value={vendorCode?.avgParsedPPE !== 0 ? vendorCode?.avgParsedPPE.toFixed(2) : ''}
                value={vendorCode?.avgParsedPPE.toFixed(2)}
                // value={vendorCode?.manualFormData?.Avg_PPE_for_last_FY}
                // value={vendorCode.manualFormData?.Avg_PPE_for_last_FY ? vendorCode.manualFormData?.Avg_PPE_for_last_FY : vendorCode.tableArray[0]?.Avg_PPE_for_last_FY}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                style={{ width: '100%' }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            <span className={styles.symbolCSS}>&#x20b9; Cr.</span>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            margin: '15px 0px 0px 0px',
            // border: '1px #d5d7da solid',
            // borderRadius: '7px',
            // margin: '10px 0px 0px 0px'
            // backgroundColor: '#eaf5ff'
          }}
          columns={12}
        >
          <Grid item xs={3} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)}>
              <label>
                Revenue to PPE Ratio
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              {/* <div className={clsx(styles.validatationLabel)}>
                (2 decimal places mandatory)
              </div> */}
            </div>
          </Grid>
          <Grid item xs={3} className={styles.alignCenter}>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                onChange={onChangeHandler}
                name='Revenue_to_PPE_ratio'
                value={vendorCode?.calcRevenueToPPERatio.toFixed(2)}
                // value={vendorCode.manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore ? vendorCode.manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore : vendorCode.tableArray[0]?.Revenue_from_Operations_in_last_FY_Rs_Crore}
                // value={vendorCode?.manualFormData?.Revenue_to_PPE_ratio}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input',
                  style: { color: '#707070' }
                }}
                // isDisabled={editableByUser || TML_PurchaseValidationCheckFlag}
                isDisabled={true}
              />
            </div>
            {/* <span className={styles.symbolCSS}>&#x20b9; Cr.</span> */}
          </Grid>
        </Grid>

        {!userRole.includes('Vendor') && (
          <>
            {/* SA Tenure Field */}
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  SA Tenure
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  {/* <div className={clsx(styles.validatationLabel)}>
                    (Only numeric value)
                  </div> */}
                </label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <ValidatingTextFieldNoScrollNSymbol
                  variant='outlined'
                  size='small'
                  fullWidth
                  // multiline
                  onChange={onChangeHandler}
                  name='SA_tenure'
                  value={vendorCode?.manualFormData?.SA_tenure}
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input'
                  }}
                  // eslint-disable-next-line react/jsx-boolean-value
                  // isDisabled={editableByUser}
                />
              </div>
            </Grid>

            {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    MPFD for major suppliers submitted
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <CustomSelect
                    isMandatory
                    className={clsx(styles.select, styles.sel2)}
                    isMulti={false}
                    isClearable
                    value={
                      vendorCode.manualFormData
                        ?.MPFD_for_major_suppliers_submitted
                        ? vendorCode.manualFormData
                          ?.MPFD_for_major_suppliers_submitted
                        : vendorCode.tableArray[0]
                          ?.MPFD_for_major_suppliers_submitted
                    }
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    onChange={e => {
                      handleSelect(e, 'Copies_of_AFS_of_suppliers_in_last_FY_submitted')
                    }}
                    name='Copies_of_AFS_of_suppliers_in_last_FY_submitted'
                    isDisabled={editableByUser}
                  />
                </div>
              </Grid> */}

            {/* <Grid
                container
                spacing={2}
                style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
                columns={12}
              > */}
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={clsx(styles.lableDiv)}>
                <label>
                  AFS Submitted Yes/NO
                  {/* Copies of AFS of major suppliers in last FY submitted */}
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </label>
              </div>
            </Grid>
            <Grid item xs={3} className={styles.alignCenter}>
              <div className={styles.formDiv}>
                <CustomSelect
                  isMandatory
                  className={clsx(styles.select, styles.sel2)}
                  isMulti={false}
                  isClearable
                  value={
                    vendorCode.manualFormData
                      ?.Copies_of_AFS_of_suppliers_in_last_FY_submitted
                      ? vendorCode.manualFormData
                          ?.Copies_of_AFS_of_suppliers_in_last_FY_submitted
                      : vendorCode.tableArray[0]
                          ?.Copies_of_AFS_of_suppliers_in_last_FY_submitted
                  }
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                  ]}
                  onChange={e => {
                    handleSelect(e,'Copies_of_AFS_of_suppliers_in_last_FY_submitted')
                  }}
                  name='Copies_of_AFS_of_suppliers_in_last_FY_submitted'
                  // isDisabled={editableByUser}
                />
              </div>
            </Grid>
            {/* <Grid item xs={3} className={styles.alignCenter}>
                <div className={clsx(styles.lableDiv)}>
                  <label>
                    PPA with Tier-I submitted to TA
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3} className={styles.alignCenter}>
                <div className={styles.formDiv}>
                  <CustomSelect
                    isMandatory
                    // markIfUnselected={highlightMandatoryFields}
                    // resetAllVisitedFields={resetAllVisitedFields}
                    className={clsx(styles.select, styles.sel2)}
                    isMulti={false}
                    isClearable
                    value={vendorCode.manualFormData?.PPA_with_Tier_I_submitted_to_TA ? vendorCode.manualFormData?.PPA_with_Tier_I_submitted_to_TA : vendorCode.tableArray[0]?.PPA_with_Tier_I_submitted_to_TA}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    onChange={e => {
                      handleSelect(e, 'PPA_with_Tier_I_submitted_to_TA')
                    }}
                    name='PPA_with_Tier_I_submitted_to_TA'
                    isDisabled={editableByUser}
                  />
                </div>
              </Grid> */}

            {/* {
                userRole.includes('admin') ||
                  (userRole.includes('Vendor') && ( */}
            {/* <>
                      <Grid item xs={3} className={styles.alignCenter}>
                        <div className={clsx(styles.lableDiv)}>
                          <label>
                            Date of PPA
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={3} className={styles.alignCenter}>
                        <div className={styles.formDiv}>
                          <CustomDatePicker
                            isMandatory
                            // markIfUnselected={true}
                            // markIfUnselected={highlightMandatoryFields}
                            autoOk
                            inputVariant='outlined'
                            value={vendorCode?.manualFormData?.Date_of_PPA}
                            onChange={e => {
                              handleDate(e, 'handoverDate')
                            }}
                            inputProps={{
                              className: clsx(styles.select, styles.dateInput)
                              // isDisabled: true
                            }}
                            emptyLabel='dd-mm-yyyy'
                            // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                            // minDate={!isEditable? null: getMinimumDateForPicker(index, milestones)}
                            format='DD-MM-yyyy'
                            data-testid='date-picker-div-1'
                            style={{ width: '100%' }}
                            maxDate={moment().toDate()}
                            // isDisabled={true}
                          />
                        </div>
                      </Grid>
                    </> */}
            {/* ))
              } */}

            {/* </Grid> */}
          </>
        )}
      </Grid>

      {/* {vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Re-Validation awaited' ||
      vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Re-Validation awaited' && */}
      {(isGDCUser || isPurchaseUser)
        &&
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: '15px',
            border: '1px #d5d7da solid',
            borderRadius: '7px',
            // margin: '10px 0px 0px 0px'
            backgroundColor: '#eaf5ff'
          }}
          columns={12}
        >
          {/* GDC can add there comment here */}
          <Grid item xs={6} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)} style={{ width: '23%', textAlign: 'center' }}>
              <label>
                {isVendorUser ? 'Approver 1' : 'TMLBSL Comment'}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
            </div>
            {/* </Grid> */}

            {/* <Grid item xs={2} className={styles.alignCenter}> */}
            <div className={styles.formDiv} style={{ width: '70%' }}>
              <CustomTextareaAutosize
                // variant='outlined'
                // size='small'
                // fullWidth
                // multiline
                onChange={onChangeHandler}
                name='TMLBSL_Validation_Email_comment'
                value={vendorCode.manualFormData?.TMLBSL_Validation_Email_comment}
                // value='vendor denosdocsmd kajnkjancjask alaskaksnkjasnckja akjsncjkascncjask alaskaksnkjasnckja akjsncjkascnas'
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                  // 'maxLength': 100
                }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={!isGDCUser}
                // isDisabled={true}
                style={{ height: '75px', width: '100%', overflow: 'auto' }}
              />
            </div>
          </Grid>

          {/* Purchase can add there comment here */}
          <Grid item xs={6} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)} style={{ width: '23%', textAlign: 'center' }}>
              <label>
                {/* {isVendorUser ? 'Approver 2' : 'Purchase Comment'} */}
                {isVendorUser ? 'Approver 2' : 'PMT Comment'}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
            </div>
            {/* </Grid>
        <Grid item xs={2} className={styles.alignCenter}> */}
            <div className={styles.formDiv} style={{ width: '70%' }}>
              <CustomTextareaAutosize
                variant='outlined'
                size='small'
                fullWidth
                multiline
                onChange={onChangeHandler}
                name='Purchase_Validation_Email_comment'
                value={vendorCode?.manualFormData?.Purchase_Validation_Email_comment}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={!isPurchaseUser}
                style={{ height: '75px', width: '100%', overflow: 'auto' }}
              />
            </div>
          </Grid>

          {/* PMT can add there comment here */}
          {/* <Grid item xs={4} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)} style={{ width: '40%' }}>
            <label>
              {isVendorUser ? 'Approver 3' : 'PMT Comment'}
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          <div className={styles.formDiv} style={{ width: '70%' }}>
            <CustomTextareaAutosize
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='PMT_Validation_Email_comment'
              value={vendorCode?.manualFormData?.PMT_Validation_Email_comment}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={!isPMTUser}
              style={{ height: '75px', width: '100%', overflow: 'auto' }}
            />
          </div>
        </Grid> */}
        </Grid>
      }

      {/* Replicating above condition for other user and enabling conditions */}
      {/* {(userRole?.includes('admin') || userRole?.includes('Vendor') && 
        (vendorCode?.buttonStatusData?.Supplier_Status === 'Not submitted') || Supplier_Status === ReValidation_awaited) */}
        {(userRole?.includes('admin') || userRole?.includes('Vendor') && Supplier_Status === ReSubmission_Awaited)
        &&
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: '15px',
            border: '1px #d5d7da solid',
            borderRadius: '7px',
            // margin: '10px 0px 0px 0px'
            backgroundColor: '#eaf5ff'
          }}
          columns={12}
        >
          {/* GDC can add there comment here */}
          <Grid item xs={6} className={styles.alignCenter}>
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '23%', textAlign: 'center' }}
            >
              <label>
                {userRole?.includes('Vendor') ? 'Approver 1' : 'TMLBSL Comment'}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
            </div>
            {/* </Grid> */}

            {/* <Grid item xs={2} className={styles.alignCenter}> */}
            <div className={styles.formDiv} style={{ width: '70%' }}>
              <CustomTextareaAutosize
                // variant='outlined'
                // size='small'
                // fullWidth
                // multiline
                onChange={onChangeHandler}
                name='TMLBSL_Validation_Email_comment'
                value={vendorCode.manualFormData?.TMLBSL_Validation_Email_comment}
                // value='vendor denosdocsmd kajnkjancjask alaskaksnkjasnckja akjsncjkascncjask alaskaksnkjasnckja akjsncjkascnas'
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={!isGDCUser}
                // isDisabled={true}
                style={{ height: '75px', width: '100%', overflow: 'auto' }}
              />
            </div>
          </Grid>


          {/* Purchase can add there comment here */}
          <Grid item xs={6} className={styles.alignCenter}>
            <div className={clsx(styles.lableDiv)} style={{ width: '23%', textAlign: 'center' }}>
              <label>
                {isVendorUser ? 'Approver 2' : 'PMT Comment'}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
            </div>
            {/* </Grid>
        <Grid item xs={2} className={styles.alignCenter}> */}
            <div className={styles.formDiv} style={{ width: '70%' }}>
              <CustomTextareaAutosize
                variant='outlined'
                size='small'
                fullWidth
                multiline
                onChange={onChangeHandler}
                name='Purchase_Validation_Email_comment'
                value={vendorCode?.manualFormData?.Purchase_Validation_Email_comment}
                placeholder=''
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={!isPurchaseUser}
                style={{ height: '75px', width: '100%', overflow: 'auto' }}
              />
            </div>
          </Grid>

          {/* Admin can add there comment here */}
          {isAdminUser &&
            <Grid item xs={6} className={styles.alignCenter}>
              <div
                className={clsx(styles.lableDiv)}
                style={{ width: '23%', textAlign: 'center' }}
              >
                <label>
                  {/* {userRole?.includes('Vendor') ? 'Approver 1' : 'TMLBSL Comment'} */}
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  Admin Comment
                </label>
              </div>
              {/* </Grid> */}

              {/* <Grid item xs={2} className={styles.alignCenter}> */}
              <div className={styles.formDiv} style={{ width: '70%' }}>
                <CustomTextareaAutosize
                  // variant='outlined'
                  // size='small'
                  // fullWidth
                  // multiline
                  onChange={onChangeHandler}
                  name='Admin_Validation_comment'
                  value={vendorCode.manualFormData?.Admin_Validation_comment ? vendorCode.manualFormData?.Admin_Validation_comment : ''}
                  // value='vendor denosdocsmd kajnkjancjask alaskaksnkjasnckja akjsncjkascncjask alaskaksnkjasnckja akjsncjkascnas'
                  placeholder=''
                  inputProps={{
                    'data-testid': 'remark-input'
                  }}
                  // eslint-disable-next-line react/jsx-boolean-value
                  // isDisabled={}
                  // isDisabled={true}
                  style={{ height: '75px', width: '100%', overflow: 'auto' }}
                />
              </div>
            </Grid>
          }

          {/* PMT can add there comment here */}
          {/* <Grid item xs={4} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)} style={{ width: '40%' }}>
            <label>
              {isVendorUser ? 'Approver 3' : 'PMT Comment'}
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
          <div className={styles.formDiv} style={{ width: '70%' }}>
            <CustomTextareaAutosize
              variant='outlined'
              size='small'
              fullWidth
              multiline
              onChange={onChangeHandler}
              name='PMT_Validation_Email_comment'
              value={vendorCode?.manualFormData?.PMT_Validation_Email_comment}
              placeholder=''
              inputProps={{
                'data-testid': 'remark-input'
              }}
              // eslint-disable-next-line react/jsx-boolean-value
              isDisabled={!isPMTUser}
              style={{ height: '75px', width: '100%', overflow: 'auto' }}
            />
          </div>
        </Grid> */}
        </Grid>
      }

      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px'
        }}
        columns={12}
      >
        {/* <Grid item xs={3} className={styles.alignCenter}>
          <div className={clsx(styles.lableDiv)}>
            <label>
              File Upload
              <span style={{ color: 'red' }}>*</span>
            </label>
          </div>
        </Grid> */}
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          {userRole.includes('admin') && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button
                name='validateL1'
                className={clsx(
                  AFSstyles.actionButton,
                  AFSstyles.primaryActionButton
                )}
                style={{
                  backgroundColor: setButtonColor(
                    // MIS_TMLBSL_data_for_PAN_for_both
                    MIS_TMLBSL_data_for_PAN_for_both_admin
                  )
                }}
                variant='primary'
                // eslint-disable-next-line react/jsx-boolean-value
                disabled={Supplier_Status === Submitted ? false : true}
                onClick={() => {
                  if (
                    vendorCode?.buttonStatusData?.TMLBSL_Validation_Status ===
                    'Validation Completed'
                  ) {
                    showPopup({
                      type: MESSAGE_TYPE.INFORMATION,
                      contextText: MESSAGE_TYPE.INFORMATION,
                      // info: 'AFS has already been validated by TMLBSL User!'
                      info: `AFS(L1) has already been validated!`
                    })
                  } else {
                    handleOpenL1()
                    // goNextForm(MIS_TMLBSL_data_for_PAN_for_both)
                    goNextForm(MIS_TMLBSL_data_for_PAN_for_both_admin)
                  }
                }}
              >
                Validate (L1)
              </Button>
              <Button
                name='validateL2'
                className={clsx(
                  AFSstyles.actionButton,
                  AFSstyles.primaryActionButton
                )}
                style={{
                  // backgroundColor: setButtonColor(MIS_Purchase_data_for_PAN)
                  backgroundColor: setButtonColor(MIS_Purchase_data_for_PAN_Admin)
                }}
                variant='primary'
                // eslint-disable-next-line react/jsx-boolean-value
                disabled={(Supplier_Status === Submitted && TMLBSLValidationStatus === Validation_Completed) ? false : true}
                onClick={() => {
                  if (
                    vendorCode?.buttonStatusData?.Purchase_Validation_status ===
                    'Validation Completed'
                  ) {
                    showPopup({
                      type: MESSAGE_TYPE.INFORMATION,
                      contextText: MESSAGE_TYPE.INFORMATION,
                      info: 'AFS(L2) has already been validated!'
                    })
                  } else {
                    handleOpenL1()
                    // goNextForm(MIS_Purchase_data_for_PAN)
                    goNextForm(MIS_Purchase_data_for_PAN_Admin)
                  }
                }}
              >
                Validate (L2)
              </Button>
            </div>
          )}
        </Grid>
        {/* </Grid> */}
        <Grid
          item
          xs={4}
        // className={styles.alignCenter}
        >
          {/* <div className={clsx(styles.lableDiv)}> */}
          {(!isPurchaseUser) && (
            <>
              {/* Hello
          </>} */}
              {!userRole?.includes('Purchase') && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                    color: 'brown'
                  }}
                >
                  <div>File type: PDF only</div>
                  <div>Max size: 150MB</div>
                </div>
              )}

              <div>
                {!isPurchaseUser &&
                  (
                    <div
                      {...getRootProps()}
                      style={{
                        border: '1.6px dashed grey',
                        padding: '0px 12px',
                        borderRadius: '5px',
                        borderColor: '#d2d2d2',
                        color: '#7f7f7f',
                        cursor: 'pointer'
                      }}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className={styles.dropZonePara}>
                          Drop the file here ...
                        </p>
                      ) : (
                        <div
                          style={{
                            display: 'grid',
                            justifyItems: 'center',
                            padding: '8px 6px'
                          }}
                        >
                          <p className={styles.dropZonePara}>
                            Drag and drop file here, or click to select file
                          </p>
                          <CloudDownloadOutlinedIcon />
                        </div>
                      )}
                    </div>
                  )}
                {(fileInfo.name || fileInfo.size) && (
                  <div
                    className={clsx(styles.lableDivDropZone)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: fileSuccessFlag ? '#59a385' : '#707070',
                      alignItems: 'center'
                    }}
                  >
                    <div>{fileInfo.name}</div>
                    <div>{calculateSize(fileInfo.size)}</div>
                    <DeleteOutlinedIcon
                      style={{
                        color: 'red',
                        fontSize: '22px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setFileInfo({
                          name: '',
                          size: ''
                        })
                        setAcceptedFiles1(null)
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={3.5}
          className={clsx(styles.bottomButtons)}
          // style={{ display: 'inlineFlex', justifyContent: 'right', marginLeft: '30px', alignItems: 'center' }}
        >
        {/* {
          (Supplier_Status === Submitted ||
            Supplier_Status === ReSubmission_Awaited) && ( */}
            <Button
              name='downloadAfsCertificate'
              className={clsx(AFSstyles.actionButton, AFSstyles.primaryActionButton)}
              variant='primary'
              // eslint-disable-next-line react/jsx-boolean-value
              disabled={(Supplier_Status === Submitted || Supplier_Status === ReSubmission_Awaited) ? false : true}
              // onClick={() => {
              //   if (!Bc?.value) {
              //     alert('Please select vendor!')
              //   } else {
              //     showTable()
              //   }
              // }}
              onClick={downloadAfsCertificate}
            >
              {/* Save as Draft */}
              Download AFS Document
            </Button>
          {/* )
        } */}

          {/* } */}

          {/* TMLBSL Send Back */}
          {/* {(isGDCUser && Supplier_Status !== 'Not Submitted' || Supplier_Status === 'Re-Submission Awaited') && ( */}
          {(isGDCUser && Supplier_Status === Submitted) && (
            <Button
              className={clsx(
                AFSstyles.actionButton,
                AFSstyles.primaryActionButton
              )}
              variant='primary'
              onClick={() => {

                if (TMLBSLValidationStatus === Validation_Completed) {
                  showPopup({
                    type: MESSAGE_TYPE.INFORMATION,
                    contextText: MESSAGE_TYPE.INFORMATION,
                    info: 'Cannot send back as validation is completed!'
                  })
                } else if ((Supplier_Status === Submitted && TMLBSLValidationStatus === Validation_awaited) || (Supplier_Status === Submitted &&TMLBSLValidationStatus === ReValidation_awaited)) {
                  sendBackRevalidateAPI()
                  setUpdateFlag((previousState) => !previousState)
                } else if (Supplier_Status === ReSubmission_Awaited) {
                  showPopup({
                    type: MESSAGE_TYPE.INFORMATION,
                    contextText: MESSAGE_TYPE.INFORMATION,
                    info: `Cannot send back as it is already sent back!`
                  })
                }

                // if ((Supplier_Status === Submitted)) {
                //   sendBackRevalidateAPI()
                //   setUpdateFlag((previousState) => !previousState)
                // }
                // else if (Supplier_Status === ReSubmission_Awaited && TMLBSLValidationStatus === ReValidation_awaited) {
                //   showPopup({
                //     type: MESSAGE_TYPE.INFORMATION,
                //     contextText: MESSAGE_TYPE.INFORMATION,
                //     info: 'Cannot send back as it is already sent back!'
                //   })
                // } 
                // else if (TMLBSLValidationStatus === Validation_Completed) {
                //   showPopup({
                //     type: MESSAGE_TYPE.INFORMATION,
                //     contextText: MESSAGE_TYPE.INFORMATION,
                //     info: 'Cannot send back once validated!'
                //   })
                // } 
                // else {
                //   setUpdateFlag((previousState) => !previousState)
                //   showPopup({
                //     type: MESSAGE_TYPE.INFORMATION,
                //     contextText: MESSAGE_TYPE.INFORMATION,
                //     info: `You can not send back before vendor's submission!`
                //   })
                // }
                // if (Supplier_Status === 'Submitted' && TMLBSLValidationStatus === Validation_Completed) {
                //   sendBackRevalidateAPI()
                //   setUpdateFlag((previousState) => !previousState)
                // } else if (Supplier_Status === 'Submitted' && TMLBSLValidationStatus === Validation_Completed) {
                //   setUpdateFlag((previousState) => !previousState)
                //   showPopup({
                //     type: MESSAGE_TYPE.INFORMATION,
                //     contextText: MESSAGE_TYPE.INFORMATION,
                //     info: 'You can not send back the AFS Document as it is already sent back!'
                //   })
                // }
                // else {
                //   setUpdateFlag((previousState) => !previousState)
                //   showPopup({
                //     type: MESSAGE_TYPE.INFORMATION,
                //     contextText: MESSAGE_TYPE.INFORMATION,
                //     info: 'You can not send back the AFS Document as it is already sent back!'
                //   })
                // }
              }}
              style={{ backgroundColor: '#FFA222' }}
              disabled={(TMLBSLValidationStatus === Validation_Completed && PurchaseValidationStatus === Validation_Completed && Supplier_Status === 'Submitted') ? true : false}
            >
              Send Back
            </Button>
          )}  

          {/* Purchase Send Back */}
          {(isPurchaseUser && (TMLBSLValidationStatus === Validation_Completed)) && (
            <Button
              className={clsx(
                AFSstyles.actionButton,
                AFSstyles.primaryActionButton
              )}
              variant='primary'
              onClick={sendBackRevalidateAPI}
              style={{ backgroundColor: '#FFA222' }}
              disabled={((TMLBSLValidationStatus === Validation_Completed && PurchaseValidationStatus === Validation_Completed) && Supplier_Status === 'Submitted') ? true : false}
            >
              Send Back
            </Button>
          )}
          {(isAdminUser || isVendorUser) &&
            <Button
              className={clsx(
                AFSstyles.actionButton,
                AFSstyles.primaryActionButton
              )}
              variant='primary'
              onClick={vendorCode.handleOpenManualFormConfirmation}
              // disabled={
              //   // userRole.includes('') || userRole.includes('')
              //   // eslint-disable-next-line no-nested-ternary
              //   ((vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Validation Completed' ||
              //     vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation Completed') && isVendorUser)
              //     ? true : false
              disabled={
                // false
                isButtonDisabled
                // renderDisableFlagVendor
                // vendorSubmitFlag
                // renderDisableFlag
                // (vendorCode?.buttonStatusData && (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation_awaited' || vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Re-Validation awaited') ? false : true)
    //             if (isGDCUser) {
    //   if (vendorCode?.buttonStatusData) {
    //     if (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation_awaited' || vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Re-Validation awaited') {
    //       flag = false
    //     } else {
    //       flag =  true
    //     }
    //   }
    // }
              }
            >
              Submit
            </Button>
          }

          {(isGDCUser && Supplier_Status !== 'Not Submitted') &&
            <Button
              className={clsx(
                AFSstyles.actionButton,
                AFSstyles.primaryActionButton
              )}
              variant='primary'
              onClick={() => {
                if (Supplier_Status === 'Re-Submission Awaited') {
                  handlepopup('Cannot validate till vendor re-submits the form!')
                } else if (TMLBSLValidationStatus === Validation_Completed) {
                  handlepopup('Cannot re-validate once validated!')
                } else {
                  vendorCode.handleOpenManualFormConfirmation()
                }
              }
              }
              disabled={(TMLBSLValidationStatus === Validation_Completed && PurchaseValidationStatus === Validation_Completed && Supplier_Status === 'Submitted') ? true : false}
            // disabled={
            //   // userRole.includes('') || userRole.includes('')
            //   // eslint-disable-next-line no-nested-ternary
            //   (vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Validation Completed' ||
            //     vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation Completed')
            //     ? true : false
            //     // ? userRole.includes('TMLBSL') || userRole.includes('Purchase') ? false : true : true
            //   // ? true : false
            // }
            >
              {/* {userRole.includes('Purchase') || userRole.includes('TMLBSL')
              ? 'Validate'
              : 'Submit'} */}
              Validate
            </Button>
          }

          {(isPurchaseUser && (vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation Completed' && Supplier_Status === 'Submitted')) &&
            <Button
              className={clsx(
                AFSstyles.actionButton,
                AFSstyles.primaryActionButton
              )}
              variant='primary'
              onClick={vendorCode.handleOpenManualFormConfirmation}
              disabled={(TMLBSLValidationStatus === Validation_Completed && PurchaseValidationStatus === Validation_Completed && Supplier_Status === 'Submitted') ? true : false}
            // disabled={
            //   // userRole.includes('') || userRole.includes('')
            //   // eslint-disable-next-line no-nested-ternary
            //   (vendorCode?.buttonStatusData?.Purchase_Validation_status === 'Validation Completed' ||
            //     vendorCode?.buttonStatusData?.TMLBSL_Validation_Status === 'Validation Completed')
            //     ? true : false
            //     // ? userRole.includes('TMLBSL') || userRole.includes('Purchase') ? false : true : true
            //   // ? true : false
            // }
            >
              {/* {userRole.includes('Purchase') || userRole.includes('TMLBSL')
              ? 'Validate'
              : 'Submit'} */}
              Validate
            </Button>
          }
        </Grid>
      </Grid>
    </div>
  )
  return (
    // <div>
    <div
      className={clsx(
        styles.scrollContainer,
        styles.scrollBar,
        styles.formContainer
      )}
    >
      <CustomFormGroup body={renderOther()} />
      <Dialog
        open={openL1Dialog}
        className={styles.popContainer}
        classes={{
          paper: styles.popupBox
        }}
        data-testid='reset-popup'
      >
        <>
          <DialogTitle>
            <span className={styles.title}>
              <WarningIcon style={{ height: '18px', width: '18px' }} />
              <span style={{ marginLeft: '8px' }} className={styles.txt}>
                Confirm Validation
              </span>
            </span>
          </DialogTitle>
          <DialogContent className={styles.content}>
            <div>
              {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
              <p>
                On confirmation, AFS data will be validated on behalf of{' '}
                {vendorCode?.URLandUserType?.userType}.
              </p>
              <p>Are you sure ?</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(styles.actionButton, styles.transparentButton)}
              onClick={handleCloseL1}
            >
              Cancel
            </Button>
            <Button
              className={clsx(styles.actionButton, styles.primaryActionButton)}
              variant='primary'
              onClick={e => {
                e.preventDefault()
                // handleCloseFormModal()
                // goNextForm()
                confirmL1Popup()
                // if (vendorCode?.URLandUserType?.apiURL === MIS_TMLBSL_data_for_PAN_for_both_admin) {
                //   vendorCode?.manualDataUpload()
                // }
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
    // </div>
  )
}

export default AFSForm

/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'
import AWS from 'aws-sdk'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './AFSMasterData.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'

import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

// import StepBar1 from '../../components/StepBar1/StepBar'
// import SupplierImportDetailsManualForm from './SupplierImportDetailsManualForm'
// import {
//   CustomFormGroup,
//   CustomSelect,
//   ValidatingTextField
// } from '../../components/FormComponents'

const AFSMasterData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  // console.log(baseUrl);
  const auth = useSelector(state => state.auth) // useSelector
  // console.log(auth)

  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])

  const [financialYearArray, setFinancialYearArray] = useState([])

  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)

  // Financial dropdown
  const [financialYear, setFinancialYear] = useState()
  //   { value: '2023-24', label: '2023-24' },
  // ])

  const [manualFormData, setManualFormData] = useState({
    //   Vendor_Code: Bc?.value,
    //   NAME1: tableArray[0]?.NAME1,
    //   PAN: tableArray[0]?.PAN,
    //   Tier: tableArray[0]?.Tier,
    //   GSTIN: tableArray[0]?.GSTIN,
    IEC_Code: '',
    SA_Name: '',
    Revenue_from_Operations_in_last_FY_Rs_Crore: '',
    Total_Import_of_Goods_in_last_FY_Rs_Crore: '',
    Total_Import_of_Services_in_last_FY_Rs_Crore: '',
    Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore: '',
    Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore: '',
    PPA_with_Tier_I_submitted_to_TA: '',
    Copies_of_AFS_of_suppliers_in_last_FY_submitted: '',
    PPE_at_the_start_of_last_FY: '',
    PPE_At_The_Close_of_last_FY: ''
  })

  const [vendCode, setvendCode] = useState()
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  //

  const [isOpenManualForm, setIsOpenManualForm] = useState(false)
  const handleOpenManualForm = () => setIsOpenManualForm(true)
  const handleCloseManualForm = () => setIsOpenManualForm(false)
  const [isManualFormConfirmationBox, setIsManualFormConfirmationBox] =
    useState(false)

  const [relatedVendorCode, setRelatedVendorCode] = useState('')
  const [relatedGstin, setRelatedGstin] = useState('')

  const handleOpenManualFormConfirmation = () =>
    setIsManualFormConfirmationBox(true)
  const handleCloseManualFormConfirmation = () =>
    setIsManualFormConfirmationBox(false)

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    let { userRole } = auth
    getData()
    if (userRole?.includes('Vendor')) {
      setLoading(false)
      getTableData1()
      if(Bc?.Value){
        getTableData()
      }
    }
    // getDataAll();
    // getdate()
    console.log('Bc?.value', Bc?.value);
    //  Uploadcertification();

    /* API for getting all AFS master data */
    ShowAllAFSData()

    /* Loads vendor code and FY to be shown in dropdowns */
    // LoadAFSFYandSupplierData()
    
  }, [flag])

  // const ShowAFSDataFYandSupplierwise = () => {
  //   const apiFormatedData = {
  //     Financial_year: financialYear?.value,
  //     Vendor_Code: Bc?.value
  //   }
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData)
  //   let headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')
  //   // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
  //   // fetch(`${baseUrl}ShowAFSDataFYandSupplierwise/`, {
  //   fetch(`${baseUrl}AFSMasterData/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body: apiFormatedDataJson
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       console.log('responsejson master: ', responseJson);
        
  //       if (responseJson.status == 'ok' || 200 || 201) {
  //         const tableData = responseJson
  //         console.log('tableData', tableData)
  //         // setTableArray(tableData)
  //         setLoading(false)
  //         const excelData = tableData.map(
  //           (
  //             {
  //               Financial_year,
  //               Vendor_Code,
  //               NAME1,
  //               PAN,
  //               SA_Name,
  //               GSTIN,
  //               IEC_Code,
  //               Revenue_from_Operations_in_last_FY_Rs_Crore,
  //               Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //               Total_Import_of_Services_in_last_FY_Rs_Crore,
  //               Total_Imports_in_last_FY_Rs_Crore,
  //               Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //               PPE_at_the_start_of_last_FY,
  //               PPE_At_The_Close_of_last_FY,
  //               Avg_PPE_for_last_FY,
  //               Revenue_to_PPE_ratio,
  //               Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //               BuyerName,
  //               TMLBSL_Validator_Name,
  //               Purchase_PMT_Validator_Name,
  //               SPOC,
  //               CTL,
  //               Test11,
  //             },
  //             id
  //           ) => {
  //             let obj = {
  //               id,
  //               Financial_year,
  //               Vendor_Code,
  //               NAME1,
  //               PAN,
  //               SA_Name,
  //               GSTIN,
  //               IEC_Code,
  //               Revenue_from_Operations_in_last_FY_Rs_Crore,
  //               Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //               Total_Import_of_Services_in_last_FY_Rs_Crore,
  //               Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ?
  //                 Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) :
  //                 null,
  //               Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ?
  //                 Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) :
  //                 null,
  //                 PPE_at_the_start_of_last_FY,
  //                 PPE_At_The_Close_of_last_FY,
  //                 Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ?
  //                 Number(Avg_PPE_for_last_FY).toFixed(2) :
  //                 null,
  //               Revenue_to_PPE_ratio:
  //                 Revenue_to_PPE_ratio != null ?
  //                   Number(Revenue_to_PPE_ratio).toFixed(2) :
  //                   null,
  //               // Revenue_to_PPE_ratio,
  //               Copies_of_AFS_of_suppliers_in_last_FY_submitted:
  //                 Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
  //               BuyerName,
  //               TMLBSL_Validator_Name,
  //               Purchase_PMT_Validator_Name,
  //               SPOC,
  //               CTL,
  //               SA_tenure: Test11,
  //             }
  //             return obj
  //           }
  //         )
  //         setTableArray(excelData)
  //       } 
  //       else if (responseJson.status > 300) {
  //         show(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Data not found'
  //         })
  //       }else {
  //         show(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Data not found'
  //         })
  //       }

  //       //  exportExcel(excelData);
  //     })
  //     .catch(error => { 
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: error
  //       })
  //      })
  // }

  const ShowAFSDataFYandSupplierwise = () => {
    const apiFormatedData = {
      Financial_year: financialYear?.value,
      // Vendor_Code: Bc?.value
    }
  
    axios.post(`${baseUrl}AFSMasterData/`, apiFormatedData, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const responseJson = response.data;
      console.log('responsejson master: ', responseJson);
  
      if (responseJson.status === 'ok' || response.status === 200 || response.status === 201) {
        const tableData = responseJson;
        console.log('tableData', tableData);
        setLoading(false);
        const excelData = tableData.map(
          (
            {
              Financial_year,
              Vendor_Code,
              NAME1,
              PAN,
              SA_Name,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted,
              BuyerName,
              TMLBSL_Validator_Name,
              Purchase_PMT_Validator_Name,
              SPOC,
              CTL,
              Test11,
            },
            id
          ) => {
            let obj = {
              id,
              Financial_year,
              Vendor_Code,
              NAME1,
              PAN,
              SA_Name,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ?
                Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) :
                null,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ?
                Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) :
                null,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ?
                Number(Avg_PPE_for_last_FY).toFixed(2) :
                null,
              Revenue_to_PPE_ratio:
                Revenue_to_PPE_ratio != null ?
                  Number(Revenue_to_PPE_ratio).toFixed(2) :
                  null,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted:
                Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
              BuyerName,
              TMLBSL_Validator_Name,
              Purchase_PMT_Validator_Name,
              SPOC,
              CTL,
              SA_tenure: Test11,
            };
            return obj;
          }
        );
        setTableArray(excelData);
      } else {
        show(false);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Data not found'
        });
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        show(false);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Data not found!'
        });
      } else {
        show(false);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'An error occurred'
        });
      }
    });
  };

  // const ShowAllAFSData = () => {
  //   // http://127.0.0.1:8000/file/ShowAllAFSData/
  //   const headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')
  //   // fetch(`${baseUrl}ShowAllAFSData/`, {
  //   fetch(`${baseUrl}AFSMasterData/`, {
  //     method: 'GET',
  //     headers: headerss
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       console.log('responseJson: ', responseJson)
  //       if (responseJson.status == 'ok' || 200 || 201) {
  //         console.log('response inside', responseJson)
  //         const tableData = responseJson
  //         const excelData = tableData.map(
  //           (
  //             {
  //               Vendor_Code,
  //               NAME1,
  //               PAN,
  //               GSTIN,
  //               IEC_Code,
  //               SA_Name,
  //               Revenue_from_Operations_in_last_FY_Rs_Crore,
  //               Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //               Total_Import_of_Services_in_last_FY_Rs_Crore,
  //               Total_Imports_in_last_FY_Rs_Crore,
  //               Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //               PPE_at_the_start_of_last_FY,
  //               PPE_At_The_Close_of_last_FY,
  //               Avg_PPE_for_last_FY,
  //               Revenue_to_PPE_ratio,
  //               Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //               // PPA_with_Tier_I_submitted_to_TA,
  //               SPOC,
  //               CTL,
  //               Test11,
  //               Financial_year,
  //               TMLBSL_Validator_Name,
  //               Purchase_PMT_Validator_Name,
  //               BuyerName
  //             },
  //             id
  //           ) => {
  //             let obj = {
  //               id,
  //               Vendor_Code,
  //               // Plant_code,
  //               NAME1,
  //               // Tier,
  //               PAN,
  //               GSTIN,
  //               IEC_Code,
  //               SA_Name,
  //               Revenue_from_Operations_in_last_FY_Rs_Crore,
  //               Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //               Total_Import_of_Services_in_last_FY_Rs_Crore,
  //               Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ?
  //                 Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) :
  //                 null,
  //               Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //               Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ?
  //                 Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) :
  //                 null,
  //               Revenue_to_PPE_ratio:
  //                 Revenue_to_PPE_ratio != null ?
  //                   Number(Revenue_to_PPE_ratio).toFixed(2) :
  //                   null,
  //               // Estimated_Import_attributable_to_Applicant_Rs_Crore.toFixed(2),
  //               PPE_at_the_start_of_last_FY,
  //               PPE_At_The_Close_of_last_FY,
  //               Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ?
  //                 Number(Avg_PPE_for_last_FY).toFixed(2) :
  //                 null,
  //               // Revenue_to_PPE_ratio,
  //               Copies_of_AFS_of_suppliers_in_last_FY_submitted:
  //                 Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
  //               // PPA_with_Tier_I_submitted_to_TA: PPA_with_Tier_I_submitted_to_TA ? 'Yes' : 'No',
  //               SPOC,
  //               CTL,
  //               SA_tenure: Test11,
  //               Financial_year,
  //               TMLBSL_Validator_Name,
  //               Purchase_PMT_Validator_Name,
  //               BuyerName
  //             }
  //             return obj
  //           }
  //         )
  //         setTableArray(excelData)
  //         // setTableArray(responseJson)
  //         show(true)
  //         setLoading(false)
  //       }
  //       else if (responseJson.status > 300) {
  //         show(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Data not found'
  //         })
  //       }else {
  //         show(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Data not found'
  //         })
  //       }
  //     })
  //     .catch(error => { })
  // }

  const ShowAllAFSData = () => {
    axios.get(`${baseUrl}AFSMasterData/`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const responseJson = response.data;
      console.log('responseJson: ', responseJson);
      if (responseJson.status === 'ok' || response.status === 200 || response.status === 201) {
        console.log('response inside', responseJson);
        const tableData = responseJson;
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              NAME1,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted,
              SPOC,
              CTL,
              Test11,
              Financial_year,
              TMLBSL_Validator_Name,
              Purchase_PMT_Validator_Name,
              BuyerName
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              NAME1,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ?
                Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) :
                null,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ?
                Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) :
                null,
              Revenue_to_PPE_ratio:
                Revenue_to_PPE_ratio != null ?
                  Number(Revenue_to_PPE_ratio).toFixed(2) :
                  null,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ?
                Number(Avg_PPE_for_last_FY).toFixed(2) :
                null,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted:
                Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
              SPOC,
              CTL,
              SA_tenure: Test11,
              Financial_year,
              TMLBSL_Validator_Name,
              Purchase_PMT_Validator_Name,
              BuyerName
            };
            return obj;
          }
        );
        setTableArray(excelData);
        show(true);
        setLoading(false);
      } else {
        // show(false);
        // showPopup({
        //   type: MESSAGE_TYPE.FAILURE,
        //   contextText: MESSAGE_TYPE.FAILURE,
        //   info: 'Data not found'
        // });
      }
    })
    .catch(error => {
      // if (error.response && error.response.status === 404) {
      //   show(false);
      //   showPopup({
      //     type: MESSAGE_TYPE.FAILURE,
      //     contextText: MESSAGE_TYPE.FAILURE,
      //     info: 'Data not found!'
      //   });
      // } else {
      //   show(false);
      //   showPopup({
      //     type: MESSAGE_TYPE.FAILURE,
      //     contextText: MESSAGE_TYPE.FAILURE,
      //     info: 'An error occurred'
      //   });
      // }
    });
  };

  const useStyles = makeStyles(theme => ({
    customDialog: {
      width: '50%',
      maxWidth: 'none'
    }
  }))

  const showTable = () => {
    ShowAFSDataFYandSupplierwise()
    // show(true)
  }

  const handleSelect = (selectedOption, name) => {
    if (name == 'VC') {
      setBC(selectedOption)
    } else {
      setPlant(selectedOption)
    }
    if (name == 'FY') {
      setFinancialYear(selectedOption)
    }
  }

  const getTableData = () => {
    const apiFormatedData = {
      VC: Bc.value
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
    fetch(`${baseUrl}Supplier_data/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        const tableData = responseJson.data
        console.log('tableData', tableData)
        setTableArray(tableData)
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              NAME1,
              // Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted,
              // PPA_with_Tier_I_submitted_to_TA

              // Claimed_for_PLI,
              // Start_Date,
              // Explanation,
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              // Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted:
                Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
              // PPA_with_Tier_I_submitted_to_TA: PPA_with_Tier_I_submitted_to_TA
              //   ? 'Yes'
              //   : 'No'
              // Claimed_for_PLI,
              // Start_Date,
              //  Explanation,
            }
            return obj
          }
        )
        setTableArray(excelData)

        setLoading(false)
        //  exportExcel(excelData);
      })
      .catch(error => {})
  }

  const getTableDataAll = () => {
    const apiFormatedData = {
      VC: Bc.value
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
    fetch(`${baseUrl}Supplier_data/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        const tableData = responseJson.data

        setTableArray(tableData)
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted,
              PPA_with_Tier_I_submitted_to_TA
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted:
                Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
              PPA_with_Tier_I_submitted_to_TA: PPA_with_Tier_I_submitted_to_TA
                ? 'Yes'
                : 'No'
            }
            return obj
          }
        )
        setTableArray(excelData)

        setLoading(false)
        //  exportExcel(excelData);
      })
      .catch(error => {})
  }
  const getTableData1 = value => {
    const apiFormatedData = {
      VC: value
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
    fetch(`${baseUrl}Supplier_data/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        const tableData = responseJson.data

        setTableArray(tableData)
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted,
              PPA_with_Tier_I_submitted_to_TA
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              PAN,
              GSTIN,
              IEC_Code,
              SA_Name,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY,
              Avg_PPE_for_last_FY,
              Revenue_to_PPE_ratio,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted:
                Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
              PPA_with_Tier_I_submitted_to_TA: PPA_with_Tier_I_submitted_to_TA
                ? 'Yes'
                : 'No'
            }
            return obj
          }
        )

        // exportExcel(excelData)
      })
      .catch(error => {})
  }

  const getData = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
    fetch(`${baseUrl}Supplier_data/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code
        }))
        setBcArray(ListArray)
        // console.log('ListArray in response', ListArray);
        let { userRole, userID } = auth
        // console.log('userRole in response', userRole);
        if (
          userRole.includes('Vendor-CV') ||
          userRole.includes('Vendor-PV') ||
          userRole.includes('Vendor')
        ) {
          // console.log('inside userRole if');
          const singleVendor = ListArray.filter(
            element => element.value == userID
          )
          if (singleVendor.length >= 1) {
            // console.log('singleVendor', singleVendor);
            setBC(singleVendor[0])
            setDisable(true)
            show(true) // table show
            getTableData1(singleVendor[0].value)
          }
        } else {
          setDisable(false)
        }
      })
      .catch(error => {})
  }
  // const getDataAll = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Gat_all_Supplier_list/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const ListArray = responseJson.map(({ Vendor_Code }) => ({
  //         value: Vendor_Code,
  //         label: Vendor_Code,
  //       }));
  //       setBcArray(ListArray);

  //       let { userRole, userID } = auth;

  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV") || userRole.includes("Vendor")) {
  //         const singleVendor = ListArray.filter(
  //           (element) => element.value == userID
  //         );
  //         if (singleVendor.length >= 1) {
  //           setBC(singleVendor[0]);
  //           setDisable(true);
  //           show(true); // table show
  //           getTableData1(singleVendor[0].value);
  //         }
  //       } else {
  //         setDisable(false);
  //       }
  //     })
  //     .catch((error) => { });
  // };
  // const getDataAll = () => {
  //   let headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')
  //   fetch(`${baseUrl}Supplier_import_details_CV_ALl_Data_Tier_I_II_III/`, {
  //     method: 'GET',
  //     headers: headerss
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       // const ListArray = responseJson.map(({ Tier}) => ({
  //       //   value:  Tier,
  //       //   label:  Tier,
  //       // }));
  //       setTableArray(responseJson)
  //       // console.log(ListArray )
  //     })
  //     .catch(error => {})
  // }
  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}PanToVendorUpdate/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

    const exportExcel = async excelData => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    // const newExcelData = excelData.map(
    //   (
    //     {
    //       Financial_year,
    //       Vendor_Code,
    //       NAME1,
    //       PAN,
    //       SA_Name,
    //       GSTIN,
    //       IEC_Code,
    //       Revenue_from_Operations_in_last_FY_Rs_Crore,
    //       Total_Import_of_Goods_in_last_FY_Rs_Crore,
    //       Total_Import_of_Services_in_last_FY_Rs_Crore,
    //       Total_Imports_in_last_FY_Rs_Crore,
    //       Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
    //       Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
    //       Estimated_Import_attributable_to_Applicant_Rs_Crore,
    //       PPE_at_the_start_of_last_FY,
    //       PPE_At_The_Close_of_last_FY,
    //       Avg_PPE_for_last_FY,
    //       Revenue_to_PPE_ratio,
    //       Copies_of_AFS_of_suppliers_in_last_FY_submitted,
    //       BuyerName,
    //       TMLBSL_Validator_Name,
    //       Purchase_PMT_Validator_Name,
    //       SPOC,
    //       CTL
    //       // Test11
    //     },
    //     id
    //   ) => {
    //     let obj = {
    //       id,
    //       Financial_year,
    //       Vendor_Code,
    //       Vendor_Name: NAME1,
    //       PAN,
    //       SA_Name,
    //       GSTIN,
    //       IEC_Code,
    //       // eslint-disable-next-line no-unneeded-ternary
    //       Revenue_from_Operations_in_last_FY_Rs_Crore: Revenue_from_Operations_in_last_FY_Rs_Crore != null ? Number(Revenue_from_Operations_in_last_FY_Rs_Crore).toFixed(2) : null,
    //       // eslint-disable-next-line no-unneeded-ternary
    //       Total_Import_of_Goods_in_last_FY_Rs_Crore: Total_Import_of_Goods_in_last_FY_Rs_Crore != null ? Number(Total_Import_of_Goods_in_last_FY_Rs_Crore).toFixed(2) : null,
    //       Total_Import_of_Services_in_last_FY_Rs_Crore,
    //       Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ? Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) : null,
    //       Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
    //       Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
    //       Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ? Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) : null,
    //       PPE_at_the_start_of_last_FY,
    //       PPE_At_The_Close_of_last_FY,
    //       Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ? Number(Avg_PPE_for_last_FY).toFixed(2) : null,
    //       Revenue_to_PPE_ratio,
    //       // eslint-disable-next-line no-unneeded-ternary
    //       Copies_of_AFS_of_suppliers_in_last_FY_submitted,
    //       // : Copies_of_AFS_of_suppliers_in_last_FY_submitted == 'Yes' ? true : false,
    //       BuyerName,
    //       TMLBSL_Validator_Name,
    //       Purchase_PMT_Validator_Name,
    //       SPOC,
    //       CTL
    //       // Test11,
    //     }
    //     return obj
    //   }
    // )
    /* 15-10-2024 updated columns */
    const newExcelData = excelData.map(
      (
        {
          Financial_year,
          Vendor_Code,
          NAME1,
          PAN,
          SA_Name,
          GSTIN,
          IEC_Code,
          Revenue_from_Operations_in_last_FY_Rs_Crore,
          Total_Import_of_Goods_in_last_FY_Rs_Crore,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore,
          PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY,
          Revenue_to_PPE_ratio,
          Copies_of_AFS_of_suppliers_in_last_FY_submitted,
          MPFD_for_major_suppliers_submitted,
          PPA_with_Tier_I_submitted_to_TA,
          Date_of_PPA,
          // Email_id,
          Supplier_Status,
          Purchase_Validation_status,
          TMLBSL_Validation_Status,
          TMLBSL_Validator_Name,
          Certificate_Supplier_Status,
          Overall_Status,

          BuyerName,
          Purchase_PMT_Validator_Name,
          PMT_Validation_Status,
          SPOC,
          CTL,
          Test11,
          Afs_Document_mandatory,
          Updated_by_Username
        },
        id
      ) => {
        let obj = {
          id,
          Financial_year,
          Vendor_Code,
          Vendor_Name: NAME1,
          PAN,
          SA_Name,
          GSTIN,
          IEC_Code,
          // eslint-disable-next-line no-unneeded-ternary
          Revenue_from_Operations_in_last_FY_Rs_Crore: Revenue_from_Operations_in_last_FY_Rs_Crore != null ? Number(Revenue_from_Operations_in_last_FY_Rs_Crore).toFixed(2) : null,
          // eslint-disable-next-line no-unneeded-ternary
          Total_Import_of_Goods_in_last_FY_Rs_Crore: Total_Import_of_Goods_in_last_FY_Rs_Crore != null ? Number(Total_Import_of_Goods_in_last_FY_Rs_Crore).toFixed(2) : null,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore: Total_Imports_in_last_FY_Rs_Crore != null ? Number(Total_Imports_in_last_FY_Rs_Crore).toFixed(2) : null,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore: Estimated_Import_attributable_to_Applicant_Rs_Crore != null ? Number(Estimated_Import_attributable_to_Applicant_Rs_Crore).toFixed(2) : null,
          PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY: Avg_PPE_for_last_FY != null ? Number(Avg_PPE_for_last_FY).toFixed(2) : null,
          Revenue_to_PPE_ratio,
          // eslint-disable-next-line no-unneeded-ternary
          Copies_of_AFS_of_suppliers_in_last_FY_submitted,
          // : Copies_of_AFS_of_suppliers_in_last_FY_submitted == 'Yes' ? true : false,
          MPFD_for_major_suppliers_submitted,
          PPA_with_Tier_I_submitted_to_TA,
          Date_of_PPA,
          // Email_id,
          Supplier_Status,
          Certificate_Supplier_Status,
          TMLBSL_Validation_Status,
          TMLBSL_Validator_Name,
          PMT_Validation_Status,
          Purchase_PMT_Validator_Name,
          // Purchase_Validation_status,
          // 'Purchase_Validator_Name': Purchase_PMT_Validator_Name,
          Overall_Status,

          BuyerName,
          SPOC,
          CTL,
          'SA_tenure': Test11,
          Afs_Document_mandatory,
          Updated_by_Username
        }
        return obj
      }
    )
    //

    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `AFSMasterDataPV${fileExt}`)
  }

  const exportExcel2 = async EXdata => {
    //  let excelData=EXdata.map((element,id)=>(Object.assign(element,{id})))

    const excelData = EXdata.map(
      (
        {
          Vendor_Code,
          // Plant_code,
          NAME1,
          Tier,
          PAN,
          GSTIN,
          IEC_Code,
          SA_Name,
          Revenue_from_Operations_in_last_FY_Rs_Crore,
          Total_Import_of_Goods_in_last_FY_Rs_Crore,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore,
          PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY,
          Revenue_to_PPE_ratio,
          Copies_of_AFS_of_suppliers_in_last_FY_submitted,
          PPA_with_Tier_I_submitted_to_TA
        },
        id
      ) => {
        let obj = {
          id,
          Vendor_Code,
          // Plant_code,
          NAME1,
          Tier,
          PAN,
          GSTIN,
          IEC_Code,
          SA_Name,
          Revenue_from_Operations_in_last_FY_Rs_Crore,
          Total_Import_of_Goods_in_last_FY_Rs_Crore,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore,
          PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY,
          Revenue_to_PPE_ratio,
          Copies_of_AFS_of_suppliers_in_last_FY_submitted,
          // :Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
          PPA_with_Tier_I_submitted_to_TA
          // :PPA_with_Tier_I_submitted_to_TA ? 'Yes' : 'No'
        }
        return obj
      }
    )

    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 120,
      title: 'Financial Year',
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Code',
      field: 'Vendor_Code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 120,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 180,
      title: 'Name of Supplier',
      field: 'NAME1',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 120,
    //   title: 'Tier',
    //   field: 'Tier',
    //   enableSearch: true,
    //   enableFilter: true,
    //   filterOptions: true,
    //   sortData: true,
    //   selectedFilterOptions: true
    // },
    {
      width: 120,
      title: 'PAN',
      field: 'PAN',
      enableSearch: true,
      enableFilter: true,
      filterOptions: true,
      sortData: true,
      selectedFilterOptions: true
    },

    {
      width: 150,
      title: 'SA Name',
      field: 'SA_Name',
      enableSearch: true,
      enableFilter: true,
      filterOptions: true,
      sortData: true,
      selectedFilterOptions: true
    },

    {
      width: 150,
      title: 'GSTIN',
      field: 'GSTIN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'IEC Code',
      field: 'IEC_Code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Revenue from Operations in last FY',
      field: 'Revenue_from_Operations_in_last_FY_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Imports of Good on Last FY',
      field: 'Total_Import_of_Goods_in_last_FY_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Imports of Services* on Last FY',
      field: 'Total_Import_of_Services_in_last_FY_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Imports in Last FY',
      field: 'Total_Imports_in_last_FY_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Estimated Imports of Goods attributal to Applicant',
      field: 'Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Estimated Imports of Services attributal to Applicant',
      field: 'Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Estimated Imports attributal to Applicant',
      field: 'Estimated_Import_attributable_to_Applicant_Rs_Crore',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'PPE At The start of last FY',
      field: 'PPE_at_the_start_of_last_FY',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'PPE At The Close of last FY',
      field: 'PPE_At_The_Close_of_last_FY',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Avg PPE for last FY',
      field: 'Avg_PPE_for_last_FY',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Revenue to PPE ratio',
      field: 'Revenue_to_PPE_ratio',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Copies of AFS of suppliers in last FY submitted',
      field: 'Copies_of_AFS_of_suppliers_in_last_FY_submitted',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'PPA with Tier I submitted to TA',
      field: 'PPA_with_Tier_I_submitted_to_TA',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'SA Tenure',
      field: 'Test11',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'MPFD For Major Suppliers Submitted',
      field: 'MPFD_for_major_suppliers_submitted',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Buyer Name',
      field: 'BuyerName',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 120,
      title: 'TMLBSL Validation Status',
      field: 'TMLBSL_Validation_Status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'TMLBSL Validator Name',
      field: 'TMLBSL_Validator_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'PMT Validator Status',
      field: 'PMT_Validation_Status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'PMT Validator Name',
      field: 'Purchase_PMT_Validator_Name',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 120,
    //   title: 'Purchase Validation Status',
    //   field: 'Purchase_Validation_status',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 120,
    //   title: 'Purchase Validator Name',
    //   field: 'Purchase_PMT_Validator_Name',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 120,
      title: 'Date of PPA',
      field: 'Date_of_PPA',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 110,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 110,
      title: 'CTL',
      field: 'CTL',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const goNextForm = () => {
    var url
    var urlMethod

    let { userRole, userID } = auth

    if (userRole.includes('GDC')) {
      url = 'MIS_Supplier_data_for_Same_PAN'
      urlMethod = 'PUT'
    } else if (userRole.includes('Vendor') || userRole.includes('Vendor-CV')) {
      url = 'MIS_Supplier_data_for_Same_PAN'
      urlMethod = 'POST'
    } else {
      url = 'MIS_Supplier_data_for_Same_PAN'
      urlMethod = 'POST'
    }
    const apiFormatedData = {
      VC: Bc.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}${url}/`, {
      method: urlMethod,
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is Succesfully Saved'
        })
        history.push('/')
      })
      .catch(error => {})
  }

  const manualDataUpload = prop => {
    console.log('manualFormData', manualFormData)
    // console.log('prop in manualDataUpload', prop)
    // const constData = {
    //   Vendor_Code: Bc?.value,
    //   NAME1: tableArray[0]?.NAME1,
    //   PAN: tableArray[0]?.PAN,
    //   Tier: tableArray[0]?.Tier,
    //   GSTIN: tableArray[0]?.GSTIN
    // }
    const body = {
      Vendor_Code: Bc?.value,
      NAME1: tableArray[0]?.NAME1,
      PAN: tableArray[0]?.PAN,
      Tier: tableArray[0]?.Tier,
      GSTIN: tableArray[0]?.GSTIN,
      // eslint-disable-next-line no-unneeded-ternary
      Copies_of_AFS_of_suppliers_in_last_FY_submitted: manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted ?.value == 'Yes' ? true: false,
      // eslint-disable-next-line no-unneeded-ternary
      PPA_with_Tier_I_submitted_to_TA: manualFormData?.PPA_with_Tier_I_submitted_to_TA?.value == 'Yes' ? true : false,
      IEC_Code: manualFormData?.IEC_Code,
      Revenue_from_Operations_in_last_FY_Rs_Crore:
        manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore,
      Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore:
        manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
      Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore:
        manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
      PPE_At_The_Close_of_last_FY: manualFormData?.PPE_At_The_Close_of_last_FY,
      PPE_at_the_start_of_last_FY: manualFormData?.PPE_at_the_start_of_last_FY,
      Total_Import_of_Goods_in_last_FY_Rs_Crore:
        manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore,
      Total_Import_of_Services_in_last_FY_Rs_Crore:
        manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore
    }
    // console.log('data', data)
    // const body = { ...constData, ...manualFormData }
    // console.log('body', body)
    const headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}PanManualDataForAllVendors1/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(body)
    })
      // .then((response) => response.json())
      .then(async responseJson => {
        // setTableArray(responseJson);
        console.log('manualDataUpload: ', await responseJson.json())
        // setFormSubmitTrigger(false)
        handleCloseManualForm()
      })
      .catch(error => {
        console.log('ManualDataUpload: ', error)
      })
  }
  // console.log('formSubmitTrigger before function', formSubmitTrigger)
  // if (formSubmitTrigger) {
  //   manualDataUpload()
  // }
  // const classes = useStyles()

  // const renderOther = () => (
  //   <div className={styles.formGroup}>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '-14px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Vendor Code<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             value={Bc?.value}
  //             name='Vendor_Code'
  //             inputProps={{
  //               'data-testid': 'remark-input',
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Name of Supplier<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='model'
  //             value={tableArray[0]?.NAME1}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PAN<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='PAN'
  //             value={tableArray[0]?.PAN}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Tier<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='Tier'
  //             value={tableArray[0]?.Tier}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             GSTIN<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='GSTIN'
  //             value={tableArray[0]?.GSTIN}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             IEC Code<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='IEC_Code'
  //             value={myState.IEC_Code}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Revenue from Operations in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Revenue_from_Operations_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Total Import of Goods in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Total_Import_of_Goods_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Total Import of Services in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Total_Import_of_Services_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Estimated Import of Goods attributable to Applicant (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Estimated Import of Services attributable to Applicant (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     {/* <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             MPFD for major suppliers submitted
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             // markIfUnselected={highlightMandatoryFields}
  //             // resetAllVisitedFields={resetAllVisitedFields}
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //             //   value={myState.plantCode}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               handleSelect(e, 'plantCode')
  //             }}
  //             name='plantCode'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid> */}
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPA with Tier-I submitted to TA
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             // markIfUnselected={highlightMandatoryFields}
  //             // resetAllVisitedFields={resetAllVisitedFields}
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //             value={myState?.PPA_with_Tier_I_submitted_to_TA}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               manualFormHandleSelect(e, 'PPA_with_Tier_I_submitted_to_TA')
  //             }}
  //             name='PPA_with_Tier_I_submitted_to_TA'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     {/* <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Date of PPA
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             type='date'
  //             // onChange={handleDateChange}
  //             name='variant'
  //             // value={myState.variant}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid> */}
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPE at the start of last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='PPE_at_the_start_of_last_FY'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPE at the close of last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='PPE_At_The_Close_of_last_FY'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //           <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Copies of AFS of major suppliers in last FY submitted
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //               value={myState.Copies_of_AFS_of_suppliers_in_last_FY_submitted}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               manualFormHandleSelect(e, 'Copies_of_AFS_of_suppliers_in_last_FY_submitted')
  //             }}
  //             name='Copies_of_AFS_of_suppliers_in_last_FY_submitted'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //   </div>
  // )
  // console.log('isOpenManualForm: ', isOpenManualForm)
  // function ManualForm () {
  //   // const classes = useStyles()
  //   return (
  //     // <ThemeProvider theme={theme}>
  //     <Dialog
  //       open={isOpenManualForm}
  //       // className={styles.manualFormPopupContainer}
  //       // classes={{
  //       //   paper: styles.manualFormPopupBox,
  //       // }}
  //       classes={{ paper: classes.customDialog }}
  //       fullWidth
  //     >
  //       <>
  //         <DialogTitle>
  //           <span>
  //             <span
  //             // className={styles.txt}
  //             >
  //               Supplier Import Details
  //             </span>
  //           </span>
  //         </DialogTitle>
  //         <DialogContent
  //         // className={styles.content}
  //         >
  //           <SupplierImportDetailsManualForm
  //             vendorCode={Bc?.value}
  //             supplierName={tableArray[0]?.NAME1}
  //             pan={tableArray[0]?.PAN}
  //             tier={tableArray[0]?.Tier}
  //             gstin={tableArray[0]?.GSTIN}
  //             formSubmitTrigger={formSubmitTrigger}
  //             manualDataUpload={manualDataUpload}
  //             setManualFormData={setManualFormData}
  //             manualFormData={manualFormData}
  //             // myState={myState}
  //           />
  //           {/* <CustomFormGroup body={renderOther()} /> */}
  //         </DialogContent>
  //         <DialogActions>
  //           <Button
  //             className={clsx(styles.actionButton, styles.transparentButton)}
  //             onClick={handleCloseManualForm}
  //           >
  //             Cancel
  //           </Button>
  //           <Button
  //             className={clsx(styles.actionButton, styles.primaryActionButton)}
  //             variant='primary'
  //             onClick={e => {
  //               e.preventDefault()
  //               setFormSubmitTrigger(true)
  //               // ManualDataUpload()
  //               // handleClose();
  //               // goNext();
  //             }}
  //           >
  //             Submit
  //           </Button>
  //         </DialogActions>
  //       </>
  //     </Dialog>
  //     // </ThemeProvider>
  //   )
  // }

  // const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>
      <Grid
        container
        spacing={1}
        style={{
          display: 'flex',
          justifyContent: 'left',
          // marginTop: '5px',
          marginBottom: '1px'
        }}
        columns={12}
      >
        {/* <Grid item md={4} lg={4}>
          <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
            <label className={styles.label}>Financial Year</label>
          </div>

          <div className={styles.formDiv}>
            <CustomSelect
              name='bc'
              options={bcArray}
              isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              // isClearable
              onChange={e => {
                handleSelect(e, 'VC')
              }}
            />
          </div>
        </Grid> */}
        <Grid item md={11} lg={11}>
          <div className={styles.rowDiv} style={{ alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <CustomSelect
              name='FY'
              // options={financialYearArray}
              options={[
                { value: '2023-24', label: '2023-24' },
                { value: '2022-23', label: '2022-23' },
              ]}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={financialYear}
              isMulti={false}
              // isClearable
              onChange={e => {
                handleSelect(e, 'FY')
              }}
              selectedOption='2023'
            />
            {/* <div className={clsx(styles.lableDiv)} style={{ width: '13%' }}>
              <label className={styles.label}>Supplier</label>
            </div> */}
            <div className={styles.formDiv}>
              {/* <CustomSelect
                name='VC'
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                // isClearable
                onChange={e => {
                  handleSelect(e, 'VC')
                }}
              /> */}
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '1px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => {
                    if (!financialYear?.value) {
                      showPopup({
                      type: MESSAGE_TYPE.INFORMATION,
                      contextText: MESSAGE_TYPE.INFORMATION,
                      info: 'Please select Financial Year',
                    })
                    } else {
                      showTable()
                    }
                  }}
                >
                  Show Data
                </Button>
                {loader == false && (
                  <AuthChecker operation='download'>
                    {isAuthorized => (
                      <Button
                        className={clsx(
                          styles.actionButton,
                          styles.primaryActionButton
                        )}
                        variant='primary'
                        //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                        onClick={() => {
                          console.log('tableArray in if: ', tableArray);
                          if (tableArray.length >= 1) {
                            exportExcel(tableArray)
                          }
                        }}
                        disabled={!isAuthorized}
                        data-testid='confirm-action'
                        style={{ marginLeft: '5px' }}
                      >
                        download
                      </Button>
                    )}
                  </AuthChecker>
                )}
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginLeft: '5px' }}
                  variant='primary'
                  onClick={ShowAllAFSData}
                >
                  Show All
                </Button>
                {/* {
                  loader == false &&

                  <AuthChecker operation="alldata">

                  {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                   //   onClick={showTableAll}
                      onClick={getDataAll}
                      disabled={!isAuthorized}
                      data-testid="confirm-action"
                      style={{ marginLeft: "5px" ,    width: "max-content"}}

                    >
                      ALL Data
                    </Button>
                  )}

                </AuthChecker>


                } */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <div className={styles.formGroup}>
        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>Vendor Code</label>
            <CustomSelect
              name="bc"
              options={bcArray}
              isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={(e) => {
                handleSelect(e, "VC");
              }}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant="primary"
                onClick={showTable}
              >
                Download Data
              </Button>
            </label>

          </div>
        </div>


      </div> */}
    </div>
  )

  // console.log('tableArray', tableArray);
  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
            />
            {/* <div className={styles.topBar} style={{}}>
              <div className={styles.divspan}>
                Collect DVA from Vendors Start Date:
                <span>{datesData.startDate}</span>
              </div>
              <div className={styles.divspan}>
                Collect DVA from Vendors End Date:
                <span>{datesData.endDate}</span>
              </div>
            </div> */}

            <div className={styles.topDiv}>
              <div className={styles.processHeader}>
                <div style={{ width: '100%' }}>
                  <CustomTab
                    // title='Supplier Annual Financial Statement Details'
                    title='AFS Master Data'
                    withOutCount
                    isSelected
                  />
                </div>

                {/* <StepBar1 enable={5} /> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup
              // header="Supplier Details --- > (1) Details of Tier-I are mandatory.  (2) '*' Import of services including royalty, as disclosed in form 15CA & 15CB of Income Tax Act/ Rules. "
              body={renderHTML()}
            />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              // primaryAction={{
              //   name: 'Validate Data',
              //   authOperation: 'submit',
              //   shouldEnable: () => true,
              //   actionFn: handleOpenFormModal
              // }}
              // secondaryActions={[
              // {
              //   name: 'Fill AFS Form',
              //   authOperation: 'manualForm',
              //   shouldEnable: () => true,
              //   actionFn: selected => {
              //     handleOpenManualForm()
              //   },
              //   customClass: ''
              // },
              // {
              //   name: 'Upload Data',
              //   authOperation: 'upload',
              //   shouldEnable: selected => true,
              //   actionFn: selected => {
              //     handleOpen()
              //   },
              //   customClass: ''
              // },
              // {
              //   name: 'Attach AFS',
              //   authOperation: 'uploadPdfDocument',
              //   shouldEnable: () => true,
              //   actionFn: selected => {
              //     Uploadcertification()
              //   },
              //   customClass: ''
              // }
              // {
              //   name: 'Download AFS Certificate',
              //   authOperation: 'downloadAfsCertificate',
              //   shouldEnable: () => true,
              //   actionFn: selected => {
              //     downloadAfsCertificate()
              //   },
              //   customClass: ''
              // }
              // ]}
              setAsnCount={setCount}
              isDataLoading={loader}
              filterDownloadBtn
              filterDownload={exportExcel2}
              actionButtons
              onRowSelect={e => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />

      <Dialog
        open={isManualFormConfirmationBox}
        className={styles.popContainer}
        classes={{
          paper: styles.popupBox
        }}
        data-testid='reset-popup'
      >
        <>
          <DialogTitle>
            <span className={styles.title}>
              <WarningIcon style={{ height: '18px', width: '18px' }} />
              <span style={{ marginLeft: '8px' }} className={styles.txt}>
                Confirm Submit
              </span>
            </span>
          </DialogTitle>
          <DialogContent className={styles.content}>
            <div>
              {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
              <p>On confirmation, AFS form data will be submitted.</p>
              <p>Are you sure ?</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(styles.actionButton, styles.transparentButton)}
              onClick={handleCloseFormModal}
            >
              Cancel
            </Button>
            <Button
              className={clsx(styles.actionButton, styles.primaryActionButton)}
              variant='primary'
              onClick={e => {
                e.preventDefault()
                manualDataUpload()
                handleCloseManualFormConfirmation()
                // handleCloseFormModal()
                // goNextForm()
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </>
      </Dialog>

      {/* <ManualForm 
        isOpenManualForm={isOpenManualForm}
        handleCloseManualForm={handleCloseManualForm}
        manualFormPost={ManualDataUpload}
      /> */}

      {/* <Dialog
        open={isOpenManualForm}
        // className={styles.manualFormPopupContainer}
        // classes={{
        //   paper: styles.manualFormPopupBox,
        // }}
        classes={{ paper: classes.customDialog }}
        fullWidth
      >
        <>
          <DialogTitle>
            <span>
              <span
              // className={styles.txt}
              >
                Annual Financial Statement Details
              </span>
            </span>
          </DialogTitle>
          <DialogContent
          // className={styles.content}
          >
            <SupplierImportDetailsManualForm
              vendorCode={Bc?.value}
              supplierName={tableArray[0]?.NAME1}
              pan={tableArray[0]?.PAN}
              tier={tableArray[0]?.Tier}
              gstin={tableArray[0]?.GSTIN}
              // formSubmitTrigger={formSubmitTrigger}
              // manualDataUpload={manualDataUpload}
              setManualFormData={setManualFormData}
              manualFormData={manualFormData}
              // myState={myState}
            />
            <CustomFormGroup body={renderOther()} />
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(styles.actionButton, styles.transparentButton)}
              onClick={handleCloseManualForm}
            >
              Cancel
            </Button>
            <Button
              className={clsx(styles.actionButton, styles.primaryActionButton)}
              variant='primary'
              onClick={e => {
                e.preventDefault()
                handleOpenManualFormConfirmation()
                // setFormSubmitTrigger(true)
                // ManualDataUpload()
                // manualDataUpload()
                // handleClose();
                // goNext();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      </Dialog> */}
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

// ManualForm.propTypes = {
//   isPopupOpen: PropTypes.bool.isRequired,
//   handleCloseManualForm: PropTypes.func.isRequired,
//   // goNext: PropTypes.func.isRequired,
// };

export default withAllowedOperationsProvider(
  AFSMasterData,
  RESOURCE_TYPE.PROJECT
)

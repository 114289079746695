/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { ACCESSRIGHT } from '../../constants'

import { getAllowedOperations } from '../../utils/authUtils';
import { useAuthorizationContext } from '../../providers/AuthorizationHandler/AuthorizationHandler';

const AllowedOperationsContext = createContext(); 

const withAllowedOperationsProvider = (
  WrappedComponent,
  ...resourceTypes
) => props => {
  const { authPolicies } = useAuthorizationContext();
  const [allowedOperations, setAllowedOperations] = useState([]);
  const auth = useSelector(state => state.auth);


  const location = useLocation()

  location.pathname

  const history = useHistory()
  const pathUrl = history.location.pathname
  const userPage = () => {
    if (auth.userRole.length >= 1) {
      const role = auth.userRole[0];
      if (role === "admin") {
        setAllowedOperations(["delete", "upload", "download", "downloadCertificate", "downloadAnnexure", "uploadCertificate", "uploadAnnexure", "submit", "search","submit-2","alldata", "uploadPdfDocument", "downloadAfsCertificate", "manualForm", "sendWelcomeEmail", "sendSummaryEmail"]);

      } else {

        const validePath = ACCESSRIGHT.filter(menu => menu.rolePLI === role)[0].pageDetails.filter(menu => menu.url === pathUrl);
        setAllowedOperations(validePath[0].resourceAccess)
      }

    }

  }
  useEffect(() => {
    userPage();
  }, [auth.userRole]);
  return (
    <AllowedOperationsContext.Provider value={{ allowedOperations }}>
      <WrappedComponent {...props} />
    </AllowedOperationsContext.Provider>
  );
};

withAllowedOperationsProvider.propTypes = {
  authPolicies: PropTypes.array
};

export default withAllowedOperationsProvider;

export const useAllowedOperationsContext = () =>
  useContext(AllowedOperationsContext);

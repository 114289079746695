/* eslint-disable object-shorthand */
/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable vars-on-top */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-named-as-default-member */
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { getUserProfile, buildErrorMessage } from '../../apis/calls';
import { usePopupManager } from '../PopupManager/PopupManager';
import { API_RESOURCE_URLS, USER_OPERATIONS, USER_STATUS } from '../../constants';
import { API } from '../../apis/api';
import { logoutAndClearData, getISTTime } from '../../utils/utils';
import { setLogin } from '../../redux/features/auth/actions';



const initializeScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onError = e => reject(e);
    document.body.appendChild(script);
  });

export const AuthorizationContext = createContext(null);

const AuthorizationHandler = props => {
  const { showInternalError } = usePopupManager();
  const SSO_SESSION_CHECK_INTERVAL_MS = 60000;
  const [user, setUser] = useState({ isLoaded: false });
  const [keycloak, setKeycloak] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authPolicies, setAuthPolicies] = useState([]);
  const [authResources, setAuthResources] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const dispatch = useDispatch()


  const checkSSOSessionStatePeriodically = () => {
    keycloak
      .loadUserInfo()
      .then(() => setTimeout(
          checkSSOSessionStatePeriodically,
          SSO_SESSION_CHECK_INTERVAL_MS,
        ))
      .catch(error => {
        const {
          // eslint-disable-next-line no-unused-vars
          sub: userID,
          exp,
          iat,
          auth_time: authTime,
        } = keycloak.tokenParsed;
        console.error(
          `failed user check, error:${ 
            buildErrorMessage(error) 
            }; exp:${getISTTime(exp)} iat:${getISTTime(iat)} ` +
            `auth:${getISTTime(authTime)}`,
        );
        refreshTokenAndContinueUserSessionCheckElseLogout();
      });
  };

  const refreshTokenAndContinueUserSessionCheckElseLogout = () =>
    refreshToken()
      .then(() => checkSSOSessionStatePeriodically())
      .catch(error => {
        console.error({ error });
        console.error(
          `Failed to refresh the token, or the session has expired.Err:${ 
            buildErrorMessage(error) 
            } Logging user out...`,
        );
        console.error(buildErrorMessage(error));
        logoutUser();
      });

  const loadAuthPolicy = async () => {
    try {
      const response = await API.get(API_RESOURCE_URLS.AUTH_BULK_RESOURCE, {
        params: {
          resourceGroup: 'esakha',
          operation: Object.values(USER_OPERATIONS), 
        },
      });
      setAuthPolicies(response.data);
    } catch (error) {
      console.error(buildErrorMessage(error));
    }
  };

  // eslint-disable-next-line consistent-return
  const loadAuthResources = async () => {
    try {
      const response = await API.get(API_RESOURCE_URLS.AUTH_ALLOWED_RESOURCE, {
        params: {
          resourceGroup: 'application-access',
          operation: 'access-application',
        },
      });
      setAuthResources(response.data);
    } catch (error) {
      console.error(buildErrorMessage(error));
    }
  };

  // eslint-disable-next-line consistent-return
  const generateUser1 = async token => {
    try {
      const { name, email, sub: userID, common } = jwtDecode(token);
      const { functions: loggedInUserStatus } = common;
      let supplierId;
      if (loggedInUserStatus === USER_STATUS.SUPPLIER)
        supplierId = common.supplier_id;
      const userProfile = await getUserProfile();
      return { name, email, userID, loggedInUserStatus, supplierId, ...userProfile, isLoaded: true };
    } catch (err) {
      console.error(buildErrorMessage(err));
      showInternalError();
    }
  };

  const generateUser = async token => {
    // console.log(jwtDecode(token))
 
     try {
       // eslint-disable-next-line camelcase
       console.log(jwtDecode(token),"token")
       // console.log(JSON.stringify(jwtDecode(token)))
   //  const pli_functions=['admin']
       const { name, email, vendorcode,pli_functions,preferred_username} = jwtDecode(token);
       //  const { functions: loggedInUserStatus } = common;
      const loggedInUserStatus = { functions: "vendor" }
       // const supplierId=vendorcode;
     //  const userID = vendorcode;
       const userID = preferred_username;
       const preferredUsername = preferred_username
       const userProfile = await getUserProfile();
 
       console.log(userProfile,"userProfile")
 
       return {pli_functions, name, email, userID, preferredUsername, loggedInUserStatus, ...userProfile, isLoaded: true, vendorcode };
     } catch (err) {
       console.error(buildErrorMessage(err));
       showInternalError();
     }
   };
   const checkText = (text, check) => {  
    const role = text.split(check)  
    return role; 
  }
  const loadAuthData = async token => {
    // eslint-disable-next-line no-shadow
    const user = await generateUser(token);
    setUser(user);
    setAuthToken(token);

     var userID; 
    console.log("userID",user.userID)

    const arrayofCheck = ["k01", "m01", "g01"]
    for (let i = 0; i <= arrayofCheck.length; i++) {
      const text = checkText(user.userID, arrayofCheck[i])
      if (text.length === 2) {
        let [id] = text;
        userID = id;
        break; 
      } else {
        let [id] = text;
        userID = id;

      } 
    }
 
    dispatch(setLogin(
      {
        user: null,
        isAuthenticated: true,
        keycloak: null,
        authPolicies: [],
        authToken: token,
       userRole: user.pli_functions,
     //   userRole: ["Purchase-PV"],
        userID: userID.toUpperCase(),
        // userID: userID,
        companyCode: "0100",
        email:user.email,
        msg: "login success",
        userName: user?.name,
        preferredUsername: user?.preferredUsername,
        vendorcode: user?.vendorcode
      }
    ));
  };

  const logoutUser = () => {
    logoutAndClearData(keycloak);
    setIsAuthenticated(false);
    setAuthToken(null);
    setUser(null);
  };

  const refreshToken = () =>
    keycloak
      .updateToken(-1) // -1 for force refresh
      .then(refreshed => {
        console.info(`token refreshed:${refreshed}`);
        setAuthToken(keycloak.token);
      });

  const setRefreshBeforeTimeout = () => {
    const expirtytimeInUnixSeconds = keycloak.tokenParsed.exp;
    const { sub: userID, exp, iat, auth_time: authTime } = keycloak.tokenParsed;
    const currentTimeInUnixSeconds = moment().unix();
    const sixtySeconds = 60;
    const remainingTimeToRefreshInMS =
      (expirtytimeInUnixSeconds - currentTimeInUnixSeconds - sixtySeconds) *
      1000;
    console.info(
      `setting token refresh - userID: ${userID} ` +
        `exp:${getISTTime(exp)} iat:${getISTTime(iat)} ` +
        `auth:${getISTTime(authTime)}`,
    );
    return setTimeout(() => {
      console.info(`userID: ${userID}: calling refresh before token expiry`);
      refreshToken().catch(err =>
        console.error(`failed to refresh token ${  buildErrorMessage(err)}`),
      );
    }, remainingTimeToRefreshInMS);
  };

  useEffect(() => {
    initializeScript(`${window.epAppData.AUTH_ENDPOINT}/js/keycloak.js`)
      .then(() => setKeycloak(window.Keycloak('/keycloak.json')))
      .catch(() => console.error('failed to load keycloak script'));
  }, []);

  useEffect(() => {
    if (!authToken) return;
    const timeout = setRefreshBeforeTimeout();
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    if (!keycloak) return;
    keycloak
      .init({
        onLoad: 'login-required',
        promiseType: 'native',
        pkceMethod: 'S256',
        checkLoginIframe: false,
      })
      .then(authenticated => {
        window.keycloak = keycloak;
        setIsAuthenticated(authenticated);
        if (authenticated) {
          loadAuthData(keycloak.token);
          checkSSOSessionStatePeriodically();
        }
      })
      .catch(error =>
        console.error('keycloack init error:', buildErrorMessage(error)),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak]);

  const authData = {
    user,
    isAuthenticated,
    logoutUser,
    authPolicies,
    authResources,
    authToken,
  };

  return <AuthorizationContext.Provider value={authData} {...props} />;
};

export const useAuthorizationContext = () => useContext(AuthorizationContext);

export default AuthorizationHandler;

/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
// import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
// import Table from '../../components/Table/Table'
import Table from '../../components/Table1/Table1'
import styles from './SupplierUnderPLIDeclarationPMT.module.css'
import { CustomFormGroup, CustomSelect, ValidatingTextField } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  MESSAGE_TYPE,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
// import { API } from '../../apis/api'
// import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

// import StepBar1 from '../../components/StepBar1/StepBar'

const VendarData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID, userName } = auth
  console.log('auth in Supplier: ', userName)
  // const [dynamicColumns, setDynamicColumns] = useState([]);
  // const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([])
  // const [Fy,setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  // const [quarter,setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  // const [datesData, setDates] = useState({
  //   endDate: "",
  //   startDate: "",
  // });
  const [projectDescription, setProjectDescription] = useState('')

  const [pliSubmissionStatus, setPliSubmissionStatus] = useState({})
  console.log('pliSubmissionStatus: ', pliSubmissionStatus)  

  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState('')
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  /* Send Back Popup */
  const [isPopupOpenFormModalSendBack, setPopupOpenFormModalSendBack] = useState(false)
  const handleOpenFormModalSendBack = () => {
    setPopupOpenFormModalSendBack(true)
  }
  const handleCloseFormModalSendBack = () => setPopupOpenFormModalSendBack(false)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    getRecentProjectCode()
    getAllProjectCode()
  }, [])
  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject);
    }
  }, [latestProject]);

  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(selectedProjectCode.value === latestProject.value);
    }
  }, [selectedProjectCode, latestProject])

  useEffect(() => {
    if (selectedProjectCode) {
      getProjectDescription()
    }
  }, [selectedProjectCode])

  // Fetch project description based on project code
  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const newDate = new Date();
  const formattedDate = newDate.toLocaleDateString('en-GB')

  // const GetSupplierCodeAPI = async latestProject1 => {
  //   console.log(
  //     'Supplier res PRO: ',
  //     selectedProjectCode?.value || latestProject1?.value
  //   )
  //   const headerss = {
  //     'Content-Type': 'application/json'
  //   }
  //   const apiFormattedDataJson = JSON.stringify({
  //     // Project_code: selectedProjectCode?.value || latestProject1?.value
  //     Project_code: (latestProject1?.value == null || 
  //       latestProject1?.value == '' || 
  //       latestProject1?.value == undefined) ? selectedProjectCode?.value : latestProject1?.value
  //   })
  //   // try {
  //   const { data } = await axios.post(
  //     `${baseUrl}GetSupplierCode/`,
  //     apiFormattedDataJson,
  //     { headers: headerss }
  //   )
  //   console.log('supplier res: ', data)

  //   const dropdownOptions = data?.Supplier_codes.filter(code => code) // Filter out empty strings and null values
  //     .map(code => ({ value: code, label: code }))
  //   console.log('Supplier res option: ', dropdownOptions)
  //   setBcArray(dropdownOptions)

  //   if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
  //     const singleVendor = dropdownOptions.find(item => item.value === userID)
  //     if (singleVendor) {
  //       setBC(singleVendor)
  //       setDisable(true) // Disable UI elements
  //     }
  //   } else {
  //     setDisable(false) // Enable UI elements
  //   }
  // }
  const GetSupplierCodeAPI = async latestProject1 => {
    console.log(
      'Supplier res PRO: ',
      selectedProjectCode?.value || latestProject1?.value
    )
  
    const headerss = {
      'Content-Type': 'application/json'
    }
  
    const apiFormattedDataJson = JSON.stringify({
      Project_code: (latestProject1?.value == null || 
        latestProject1?.value === '' || 
        latestProject1?.value === undefined) 
        ? selectedProjectCode?.value 
        : latestProject1?.value
    })
  
    try {
      const { data } = await axios.post(
        `${baseUrl}GetSupplierCode/`,
        apiFormattedDataJson,
        { headers: headerss }
      )
      console.log('supplier res: ', data)
  
      const dropdownOptions = data?.Supplier_codes.filter(code => code) // Filter out empty strings and null values
        .map(code => ({ value: code, label: code }))
      console.log('Supplier res option: ', dropdownOptions)
      setBcArray(dropdownOptions)
  
      if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
        const singleVendor = dropdownOptions.find(item => item.value === userID)
        if (singleVendor) {
          setBC(singleVendor)
          setDisable(true) // Disable UI elements
        }
      } else {
        setDisable(false) // Enable UI elements
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
            console.error("Error 400: Bad Request - Check the API request format or data.");
            break;
          case 404:
            console.error("Error 404: Not Found - The requested resource was not found.");
            break;
          case 500:
            console.error("Error 500: Internal Server Error - The server encountered an error.");
            break;
          default:
            console.error("An error occurred:", error.response.status, error.response.data);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server:", error.request);
      } else {
        // Something else triggered an error
        console.error("An error occurred:", error.message);
      }
    }
  }  

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      const projectDesc = uniqueProjects.map(item => {
        console.log('item: ', item)
        return item?.Project_code === latestProject
      })
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      const latestProjectCodeDesc =
        data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc)
      setRecentProjectCodeDescription(latestProjectCodeDesc)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setProjectCode(recentProj)
      setSelectedProjectCode(recentProj)
      GetSupplierCodeAPI(recentProj)
    } catch (error) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  const handleDropdownChange = (selectedOption, name) => {
    console.log('selectedOption: ', selectedOption)
    if (name === 'Project_code') {
      setSelectedProjectCode(selectedOption)
    }
  }

  const showTable = () => {
    console.log('Project in show: ', projectCode)
    if (projectCode === null || projectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else if (Bc === null || Bc?.value === '' || Bc === undefined) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Supplier Code is Mandatory'
      })
    } else {
      getTableData()
      getSupplierunderPLIstatusForbutton()
      show(true)
    }
  }


  const getSupplierunderPLIstatusForbutton = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    try {
      const response = await axios.post(
        `${baseUrl}SupplierunderPLIstatusForbutton/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log('Response Status: ', response)
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data
        console.log('Response Status: ', tableData[0])
        setPliSubmissionStatus(tableData[0])
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error 404: No records found')
      } else {
        console.error('Error fetching table data:', error)
      }
    }
  }


  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value)
    if (name == 'VC') {
      show(false)

      setBC(selectedOption)
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption)
    }
  }

  const getTableData = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    try {
      const response = await axios.post(
        // `${baseUrl}VendorUnderPLIIncentiveData/`,
        `${baseUrl}VendorUnderPLIIncentiveDataNew/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data?.data
        setTableArray(tableData)
        setLoading(false)
      } else {
        console.error('Error fetching table data:', response.statusText)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${response.statusText}`
        })
      }
    } catch (error) {
      console.error('Error fetching table data:', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error: ${error.message}`
      })
    }
  }

  // const getAllData = () => {
  //   let headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')
  //   fetch(`${baseUrl}AllSupplierDeclarationData/`, {
  //     method: 'GET',
  //     headers: headerss
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       if (responseJson === null || responseJson.length === 0) {
  //         // setTableArray(responseJson)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'No data available'
  //         })
  //       } else {
  //         setTableArray(responseJson)
  //         show(true)
  //         setLoading(false)
  //       }
  //     })
  //     .catch(error => {
  //       console.log('error', error)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Error while fetching data.'
  //       })
  //     })
  // }

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataadditionPV/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })
        .catch(error => {})
    }
  }

  /* Excel upload for TMLBSL */
  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()
    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      try {
        const response = await axios.post(
          // `${baseUrl}VendorUnderPLIDataforPMT/`,
          `${baseUrl}VendorUnderPLIDataforPMT1/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        console.log('Upload res: ', response)

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully!'
          })
          getTableData()
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Document upload failed!`
          })
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response)
          let errorMessage = 'Document upload failed!'

          if (error.response.status === 400) {
            errorMessage = error?.response?.data?.status
            console.log('404: ', error?.response?.data?.status);
            
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Document upload failed!`
            })
          } else if (error.response.status === 404) {
            errorMessage =
              'Not Found: The server can not find the requested resource.'
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Document upload failed!`
            })
          } else if (error.response.status === 500) {
            errorMessage = `Internal Server Error: The server has encountered a situation.`
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Document upload failed!`
            })
          }
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: errorMessage
          })
        } else if (error.request) {
          console.error('Error request:', error.request)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No response received from server.'
          })
        } else {
          console.error('Error message:', error.message)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Request error: ${error.message}`
          })
        }
      }
    }
  }


  const exportExcel = async excelData => {
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          // Supplier_pli,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          // PLI_claim_effective_date,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          // Financial_year,
          // Quarter
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          // Purchase_Remark,
          // Purchase_approve_or_reject,
          PMT_approve_or_reject,
          PMT_Remark,
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          // Supplier_pli,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          // PLI_claim_effective_date,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          // Financial_year,
          // Quarter
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          // Purchase_Remark,
          // Purchase_approve_or_reject,
          PMT_approve_or_reject,
          PMT_Remark,
        }
        return obj
      }
    )

    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      // field: "Supplier_Code",
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      // field: "Part_number",
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      // field: "Part_Description",
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "TML Part No. for eligible AAT Component",
    //   // field: "TML Part No. for eligible AAT Component",
    //   field: "Selling_Price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      // field : "Availing_PLI_Incentive",
      // field: 'Supplier_pli',
      field: 'Availing_PLI_Incentive_Yes_No',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   // title: "Date post which PLI incentives will be claimed",
    //   // field: "Date post which PLI incentives will be claimed",
    //   title: 'PLI Claim Effective Date',
    //   field: 'PLI_claim_effective_date',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (DD)',
      field: 'PLI_claim_effective_date_dd',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (MM)',
      field: 'PLI_claim_effective_date_mm',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (YYYY)',
      field: 'PLI_claim_effective_date_yyyy',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TA Certified DVA %',
      // field: "TA certified DVA % ",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 180,
      title: 'TMLBSL Approve/Reject',
      field: 'TMLBSL_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Remark',
      field: 'TMLBSL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 180,
    //   title: 'Purchase Remark',
    //   field: 'Purchase_Remark',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 180,
    //   title: 'Purchase Approve/Reject',
    //   field: 'Purchase_approve_or_reject',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 180,
      title: 'PMT Approve/Reject',
      field: 'PMT_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PMT Remark',
      field: 'PMT_Remark',
      enableSearch: true,
      enableFilter: true
    },


    // {
    //   width: 200,
    //   title: 'Financial Year',
    //   // field: "Financial Year",
    //   field: 'Financial_year',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'Quarter',
    //   field: 'Quarter',
    //   enableSearch: true,
    //   enableFilter: true
    // }
    // {
    //   width: 140,
    //   title: "MPFD",
    //   field: "MPFD",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]
  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'TML Part No. for eligible AAT Component',
    //   // field: "TML Part No. for eligible AAT Component",
    //   field: 'Selling_Price',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      // field: 'Supplier_pli',
      field: 'Availing_PLI_Incentive_Yes_No',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'Date post which PLI incentives will be claimed',
    //   // field: "Date post which PLI incentives will be claimed",
    //   field: 'PLI_claim_effective_date',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (DD)',
      field: 'PLI_claim_effective_date_dd',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (MM)',
      field: 'PLI_claim_effective_date_mm',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date (YYYY)',
      field: 'PLI_claim_effective_date_yyyy',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TA certified DVA %',
      // field: "TA certified DVA % ",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Approve/Reject',
      field: 'TMLBSL_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'TMLBSL Remark',
      field: 'TMLBSL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 180,
    //   title: 'Purchase Remark',
    //   field: 'Purchase_Remark',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 180,
    //   title: 'Purchase Approve/Reject',
    //   field: 'Purchase_approve_or_reject',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    {
      width: 180,
      title: 'PMT Approve/Reject',
      field: 'PMT_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PMT Remark',
      field: 'PMT_Remark',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: 'Financial Year',
    //   // field: "Financial Year",
    //   field: 'Financial_year',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'Quarter',
    //   field: 'Quarter',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'MPFD',
    //   field: 'MPFD',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'CA_SA',
    //   field: 'CA_SA',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'UDIN',
    //   field: 'UDIN',
    //   enableSearch: true,
    //   enableFilter: true
    // },
    // {
    //   width: 140,
    //   title: 'DVA_certifying_agency',
    //   field: 'DVA_certifying_agency',
    //   enableSearch: true,
    //   enableFilter: true
    // }
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]

  const goNextForm = async () => {
    let url = 'SupplierUnderPLIPMTSubmissionstatus'
    let urlMethod = 'POST'

    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      PMT_underPLI_submitted_Date: formattedDate,
      PMT_underPLI_submitted_Name: userName
    }

    try {
      const response = await axios({
        method: urlMethod,
        url: `${baseUrl}${url}/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: apiFormatedData,
      })
      if (response.status === 200 || response.status === 201) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is saved Successfully!',
        });
        history.push('/');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      let errorMessage = 'An error occurred';
    
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request';
            break;
          case 401:
            errorMessage = 'Unauthorized';
            break;
          case 403:
            errorMessage = 'Forbidden';
            break;
          case 404:
            errorMessage = 'Not Found';
            break;
          case 500:
            errorMessage = 'Internal Server Error';
            break;
          default:
            errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server';
      } else {
        // Handle other errors
        errorMessage = `Error: ${error.message}`;
      }
    
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: errorMessage,
      });
    }  
    autoSendBackAPI()  
  }

  const autoSendBackAPI = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    try {
      await axios({
        method: 'POST',
        url: `${baseUrl}AutosendUnderPLISubmissionstatus/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: apiFormatedData,
      })
    } catch (error) {
      let errorMessage = 'An error occurred';
    
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request';
            break;
          case 401:
            errorMessage = 'Unauthorized';
            break;
          case 403:
            errorMessage = 'Forbidden';
            break;
          case 404:
            errorMessage = 'Not Found';
            break;
          case 500:
            errorMessage = 'Internal Server Error';
            break;
          default:
            errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server';
      } else {
        // Handle other errors
        errorMessage = `Error: ${error.message}`;
      }
      console.log('Error in autoSendBack', errorMessage)      
    } 
  }

  const SendBackPMTStatusForSupplierUnderPLIAPI = async () => {
    let url = 'SendBackPMTStatusForSupplierUnderPLI'
    let urlMethod = 'POST'

    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      PMT_underPLI_submitted_Date: formattedDate,
      PMT_underPLI_submitted_Name: userName
    }

    try {
      const response = await axios({
        method: urlMethod,
        url: `${baseUrl}${url}/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: apiFormatedData,
      })
      if (response.status === 200 || response.status === 201) {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Data is sent back re-submission!',
        });
        history.push('/');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      let errorMessage = 'An error occurred';
    
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request';
            break;
          case 401:
            errorMessage = 'Unauthorized';
            break;
          case 403:
            errorMessage = 'Forbidden';
            break;
          case 404:
            errorMessage = 'Not Found';
            break;
          case 500:
            errorMessage = 'Internal Server Error';
            break;
          default:
            errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server';
      } else {
        // Handle other errors
        errorMessage = `Error: ${error.message}`;
      }
    
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: errorMessage,
      });
    }    
  }

  const renderHTML = () => (
    <div className={styles.formGroup}>
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          md={3}
          lg={3}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: '51%', display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project_code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setProjectCode(selectedOption)
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  GetSupplierCodeAPI(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='bc'
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={e => {
                  // handleSelect(e, "VC");
                  setBC(e)
                }}
              />
            </div>
          </div>
        </Grid>
        {/* Project Desc. View */}
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          // sm={12}
          style={{
            // marginTop: "5px", 
            // marginBottom: "5px", 
            display: 'flex',
            justifyContent: 'center', alignItems: 'center'
          }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: "30%" }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            {/* <div className={styles.formDiv}>
              <Grid item xs={3} className={styles.alignCenter}> */}
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // value={vendorCode?.vendorCode}
                // value={recentProjectCodeDescription && recentProjectCodeDescription}
                value={projectDescription && projectDescription}
                name='projDesc'
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                // onChange={(selectedOption) => handleDropdownChange(selectedOption)}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
            {/* </Grid> */}
            {/* </div> */}
          </div>
        </Grid>
        <Grid
          item
          md={2}
          lg={2}
          sm={2}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              // marginTop: '1px',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
            
            {
              flag && (
                <AuthChecker operation='search'>
                  {isAuthorized => (
                    <Button
                      className={clsx(styles.actionButton, styles.primaryActionButton)}
                      variant='primary'
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                      onClick={() => exportExcel(tableArray)}
                      disabled={!isAuthorized}
                      data-testid='confirm-action'
                      style={{ marginLeft: '5px' }}
                    >
                      Download
                    </Button>
                  )}
                </AuthChecker>
              )
            }
            
          </div>
        </Grid>
      </Grid>
    </div>
  )

  /* Generate PDF API function */
  const downloadFile = () => {
    const headerss = new Headers({
      'Content-Type': 'application/json'
    })
    // const formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // formData.append("Supplier_Code",Bc?.value)
    // console.log(Bc?.value)
    const body = JSON.stringify({
      Supplier_code: Bc?.value,
      Project_code: projectCode?.value
    })
    // fetch(`${baseUrl}SupplierDeclaration_PDF/`,{
    fetch(`${baseUrl}SupplierDeclaration_PDFGen/`, {
      method: 'POST',
      headers: headerss,
      body
    }).then(response => {
      const success = response.ok

      response
        .blob()
        .then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob)
          // Setting various property values

          let alink = document.createElement('a')
          alink.href = fileURL
          alink.download = 'Certificate.pdf'
          alink.click()
        })
        .then(() => {
          if (!success) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'File is Sucessfully Downloaded'
            })
          }
        })
        .catch(error => {
          // catch any unexpected errors
          console.log(error)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Document download failed.'
          })
        })
    })
  }

  const Uploadcertification = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Supplier_code', Bc?.value)
      formData.append('Project_code', projectCode?.value)

      try {
        const response = await axios.post(
          `${baseUrl}SupplierDeclarationPdfUploadToS3Bucket/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Certificate Uploaded Successfully!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: response.data?.url || `Error: ${response.statusText}`
          })
        }
      } catch (error) {
        let errorMessage = 'An error occurred'

        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request'
              break
            case 401:
              errorMessage = 'Unauthorized'
              break
            case 404:
              errorMessage = 'Not Found'
              break
            case 500:
              errorMessage = 'Internal Server Error'
              break
            default:
              errorMessage = `Error: ${error.response.statusText}`
          }
        } else if (error.request) {
          errorMessage = 'No response received from the server'
        } else {
          errorMessage = `Error: ${error.message}`
        }

        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Upload Failed. Reason: ${errorMessage}`
        })
      }
    }
  }

  /**
   * Downloads the Supplier Under PLI Annexure PDF.
   *
   * @param {string} Bc?.value - The supplier code.
   * @param {string} projectCode?.value - The project code.
   */

  const downloadSupplierUnderPLICertificatePDF = () => {
    const formData = new FormData()
    formData.append('Supplier_code', Bc?.value)
    formData.append('Project_code', projectCode?.value)
  
    // axios.post(`${baseUrl}DownloadSupplierUnderPLICertificatePDF/`, formData)
    axios.post(`${baseUrl}DownloadSupplierUnderPLICertificatePDFNew/`, formData)
      .then(response => {
        const res = response.data
        console.log('response certificate status: ', response);
        
        if (response?.status === 200 || response?.status === 201) {
        if (response?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Document is not present or is not uploaded by vendor.'
          })
        }
        else {
          const aws_url = res?.url
          const link = document.createElement('a')
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Certificate downloaded successfully!'
          })
        }
      }
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response
          if (status === 400) {
            console.log('Bad Request: ', error.response.data)
          } else if (status === 404) {
            console.log('Not Found: ', error.response.data)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Document is not present or may not be uploaded by vendor.'
            })
          } else if (status === 500) {
            console.log('Internal Server Error: ', error.response.data)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Internal server error'
            })
          } else {
            console.log('Error: ', error.response.data)
          }
        } else {
          console.log('Error while calling DownloadSupplierAfsFile API: ', error.message)
        }
      })
  }

  /**
   * Downloads the Supplier Under PLI Annexure PDF.
   *
   * @param {string} Bc?.value - The supplier code.
   * @param {string} projectCode?.value - The project code.
   */

  const downloadSupplierUnderPLIAnnexurePDF = () => {
    const formData = new FormData()
    formData.append('Supplier_code', Bc?.value)
    formData.append('Project_code', projectCode?.value)
  
    axios.post(`${baseUrl}DownloadSupplierUnderPLIAnnexurePDF/`, formData)
      .then(response => {
        const res = response.data
        if (res?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Document is not present or is not uploaded by vendor.'
          })
        } else {
          const aws_url = res?.url
          const link = document.createElement('a')
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document downloaded successfully!'
          })
        }
      })
      .catch(error => {
        if (error.response) {
          const { status } = error.response
          if (status === 400) {
            console.log('Bad Request: ', error.response.data)
          } else if (status === 404) {
            console.log('Not Found: ', error.response.data)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Document is not present or may not be uploaded by vendor.'
            })
          } else if (status === 500) {
            console.log('Internal Server Error: ', error.response.data)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Internal server error'
            })
          } else {
            console.log('Error: ', error.response.data)
          }
        } else {
          console.log('Error while calling DownloadSupplierAfsFile API: ', error.message)
        }
      })
  }

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: () => submitButtonFlag(),
      actionFn: selected => {
        uploadDocument()
      },
      customClass: ''
    },
    // {
    //   name: 'Generate Certificate',
    //   authOperation: 'submit',
    //   shouldEnable: selected => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: selected => {
    //     downloadFile()
    //   },
    //   customClass: ''
    // },
    // {
    //   name: 'Upload Certificate',
    //   authOperation: 'uploadCertificate',
    //   // shouldEnable: selected => true,
    //   shouldEnable: () => submitButtonFlag(),
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
    //   actionFn: selected => {
    //     Uploadcertification()
    //   },
    //   customClass: ''
    // },
    // {
    //   name: 'Other Documents',
    //   authOperation: 'uploadAnnexure',
    //   // shouldEnable: selected => true,
    //   shouldEnable: () => submitButtonFlag(),
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: selected => {
    //     // Uploadannexure()
    //   },
    //   customClass: ''
    // },
    {
      name: 'Download Certificate',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        downloadSupplierUnderPLICertificatePDF()
      },
      customClass: ''
    },
    {
      name: 'Download Documents',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        downloadSupplierUnderPLIAnnexurePDF()
      },
      customClass: ''
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate' ||
          item.name === 'Upload'
      )
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter(
        item =>
          item.name !== 'Download Documents' &&
          item.name !== 'Download Certificate'
      )
    }
    return secondaryActions
  }

  // Re-Submission_awaited
  /* 
    true = enable
    false = disable
  */
  function submitButtonFlag() {
    const PMT_underPLI_submitted_status = pliSubmissionStatus?.PMT_underPLI_submitted_status
    const Vendor_underPLI_submitted_status = pliSubmissionStatus?.Vendor_underPLI_submitted_status
    // console.log('Flag: TMLBSLSubmitStatus: ', TMLBSLSubmitStatus)
    // const PurchaseSubmitStatus = pliSubmissionStatus?.Purchase_underPLI_submitted_status
    // console.log('Flag: PurchaseSubmitStatus: ', PurchaseSubmitStatus)

    let buttonFlag
    // if (TMLBSLSubmitStatus === 'Re-Submission_awaited' && PurchaseSubmitStatus === 'Re-Submission_awaited') {
    if ((Vendor_underPLI_submitted_status === 'Submitted') && (PMT_underPLI_submitted_status !== 'Submitted')) {
      buttonFlag = true
    }
    else {
      buttonFlag = false
    }
    return buttonFlag
  }

  const enableFlag = submitButtonFlag()
  console.log('Flag: submitFlag: ', enableFlag)

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div style={{ width: '100%' }}>
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='Supplier PLI Declaration'
                      withOutCount
                      isSelected
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>

        {/* {checkDisable && (  */}
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              defaultColumns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              rowHeight={38}
              rows={tableArray}
              primaryAction={
                [
                  {
                    name: 'Submit Data',
                    authOperation: 'submit',
                    shouldEnable: () => submitButtonFlag(),
                    actionFn: handleOpenFormModal
                  },
                  {
                    name: 'Send Back',
                    authOperation: 'submit',
                    shouldEnable: () => true,
                    actionFn: handleOpenFormModalSendBack
                  },
                ]
              }
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />
      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <SendBackConfirmModal
        isPopupOpenFormModalSendBack={isPopupOpenFormModalSendBack}
        handleCloseFormModalSendBack={handleCloseFormModalSendBack}
        SendBackPMTStatusForSupplierUnderPLIAPI={SendBackPMTStatusForSupplierUnderPLIAPI}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>On confirmation, data will be submitted</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation, Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SendBackConfirmModal ({ isPopupOpenFormModalSendBack, handleCloseFormModalSendBack, SendBackPMTStatusForSupplierUnderPLIAPI }) {
  return (
    <Dialog
      open={isPopupOpenFormModalSendBack}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>On confirmation, Data will be sent back for re-submission to vendor.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModalSendBack}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModalSendBack()
              SendBackPMTStatusForSupplierUnderPLIAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}
SendBackConfirmModal.propTypes = {
  isPopupOpenFormModalSendBack: PropTypes.bool.isRequired,
  handleCloseFormModalSendBack: PropTypes.func.isRequired,
  SendBackPMTStatusForSupplierUnderPLIAPI: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT)



/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table1 from "../../components/Table/Table";
import Table from "../../components/Table1/Table1";

import styles from "./SupplierDataPurchase.module.css";
import { CustomFormGroup, CustomSelect, ValidatingTextField } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from '../../components/StepBar1/StepBar';
import { GridLoadingSpinner } from "../../components";
// import { error } from "jquery";

const VendarData = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName, vendorcode } = auth
  console.log('userRole: ', userRole, userID, userName);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState("")
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [dvaSubmissionStatus, setDVASubmissionStatus] = useState({})

  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  })
  const [projectDescription, setProjectDescription] = useState('')

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  // 
  const plantArray1 = [
    { value: '7501', name: 'Sanand-1' },
    { value: '1100', name: 'Pune' },
    { value: 'E201', name: 'Sanand-2' },
  ];
  
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [projectDescription1, setProjectDescription1] = useState('');
  
  const handlePlantChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption); 
    setSelectedPlant(selectedOption); 
    
    const plantName = plantArray1.find(
      (plantItem) => plantItem.value === selectedOption?.value
    )?.name;
  
    setProjectDescription1(plantName || ''); 
  };
  

  const ROW_HEIGHT = 38;
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {setIsSubmissionPopupOpen(true)}
  const handleCloseSubmissionPopup = () => {setIsSubmissionPopupOpen(false)}

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  const vendorUserID = isVendorUser && userID.toUpperCase()

  useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
  }, [])

  useEffect(() => {
    if (selectedProjectCode) {
      // getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode]);
  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
    // else if (name === 'projDesc') {
    //   console.log('inside projDesc');
      
    //   setSelectedProjectCode(selectedOption)
    // }
  }
 
  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    // Plant_code: plant?.value,
    // Po_Plant: plant?.value,
    Po_Plant: selectedPlant?.value ,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
  }

  useEffect(() => {

  }, [bcArray])
  console.log('Supplier BC: ', Bc);
  

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
      getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map((item) => { 
        console.log('item: ', item); 
        return item?.Project_code === latestProject})
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc);
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription = 
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  }

  // const submitSupplierDVACollection = () => {

  //   const apiFormatedDataJson = JSON.stringify(apiBody);
  //   // const body = {
  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}SubmitStatusUpdate/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       // const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       // const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       // setDates((prevData)=>({...prevData,endDate,startDate}));
  //       console.log("responseJson", responseJson)
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "Supplier DVA Submitted Successfully!",
  //       })
  //     })
  //     .catch((error) => { console.log('error:', error) });
  // };

  // const getdate = () => {

  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Milestone_calendarView/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       setDates((prevData)=>({...prevData,endDate,startDate}));

  //     })
  //     .catch((error) => {});
  // };





  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

  const showTable = () => {
    // const { userRole } = auth
    if (
      userRole[0] === 'admin' ||
      userRole[0] === 'TMLBSL' ||
      userRole[0] === 'GDC' ||
      userRole[0] === 'Purchase' ||
      isPMTUser
    ) {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Project Code is Mandatory'
        })
      } else {
        getSupplierDVAstatusForbutton()
        getTableData()
        show(true)
      }
    } else if (userRole[0] === 'Vendor') {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Project Code is Mandatory'
        })
      } else if (Bc === null || Bc.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Supplier Code is Mandatory'
        })
      }
      // else if (plant === null || plant.value === '')
        else if (selectedPlant === null || selectedPlant.value === '')
         {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Plant Code is Mandatory'
        })
      } else {
        getProjectDescription()
        getSupplierDVAstatusForbutton()
        getTableData()
        show(true)
      }
    } else {
      getSupplierDVAstatusForbutton()
      getTableData()
      show(true)
    }
  }


  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const getSupplierDVAstatusForbutton = async () => {
    const body = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      // Po_Plant: plant?.value
      Po_Plant: selectedPlant?.value ,
    }
    const purchaseBody = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      // Po_Plant: plant?.value,
      Po_Plant: selectedPlant?.value ,
      Buyer_ID: userID
    }
    try {
      const response = await axios.post(`${baseUrl}SupplierDVAstatusForbutton/`, isPurchaseUser ? purchaseBody : body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log('Response data:', response.data)
      if (response?.status === 200 || response?.status === 201) {
        setDVASubmissionStatus(response?.data[0])
      }
    } catch (error) {
      let errorMessage = 'An error occurred'

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request - Invalid parameters'
            break
          case 404:
            errorMessage = 'Not Found - The requested resource was not found'
            break
          case 500:
            errorMessage = 'Internal Server Error - Please try again later'
            break
          default:
            errorMessage = `Unexpected Error: ${error.response.statusText}`
        }
      } else if (error.request) {
        errorMessage = 'No response received from the server'
      } else {
        errorMessage = `Request Error: ${error.message}`
      }
    }
  }

  function submitButtonFlag () {
    const PMT_submitted_status = dvaSubmissionStatus?.PMT_submitted_status
    const Vendor_submitted_status = dvaSubmissionStatus?.Vendor_submitted_status
    const TMLBSL_submitted_status = dvaSubmissionStatus?.TMLBSL_submitted_status
    const Purchase_submitted_status =
      dvaSubmissionStatus?.Purchase_submitted_status

    let buttonFlag
    if (isPMTUser) {
      if (
        (PMT_submitted_status === 'Re-Validation_awaited' ||
          PMT_submitted_status === 'Not submitted') &&
        Vendor_submitted_status === 'Submitted'
      ) {
        buttonFlag = true
      } else {
        buttonFlag = false
      }
    }

    if (isVendorUser) {
      if (
        Vendor_submitted_status === 'Re-Submission_awaited' ||
        Vendor_submitted_status === 'Not submitted'
      ) {
        buttonFlag = true
      } else {
        buttonFlag = false
      }
    }

    if (isGDCUser) {
      if (
        (TMLBSL_submitted_status === 'Re-Validation_awaited' ||
          TMLBSL_submitted_status === 'Not submitted') &&
        Vendor_submitted_status === 'Submitted'
      ) {
        buttonFlag = true
      } else {
        buttonFlag = false
      }
    }

    if (isPurchaseUser) {
      if (
        (Purchase_submitted_status === 'Re-Validation_awaited' ||
          Purchase_submitted_status === 'Not submitted') &&
        Vendor_submitted_status === 'Submitted'
      ) {
        buttonFlag = true
      } else {
        buttonFlag = false
      }
    }

    return buttonFlag
  }


  console.log('submitButtonFlag: ', submitButtonFlag())  

  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value);
    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };

  // const getTableData = () => {
  //   const apiBodyPurchase = {
  //     Supplier_Code: Bc?.value,
  //     Project_code: projectCode?.value,
  //     Buyer_ID: userName,
  //     Plant_code: plant?.value
  //   }
  //   var apiFormatedDataJson = JSON.stringify(isPurchaseUser ? apiBodyPurchase : apiBody)
  //   let headerss = new Headers()
  //   headerss.append("Content-Type", "application/json")
  //   // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
  //   // const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'

  //   const url = isVendorUser ? 'ShowVendorData' : isPurchaseUser ? 'PurchaseByerDVAData' : 'SupplierDvaCollectionAdminGDCshow'

  //   fetch(`${baseUrl}${url}/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('table data response', responseJson)
  //       const tableData = responseJson.data;
  //       setTableArray(tableData)
  //       // const excelData = tableData.map(
  //       //   (
  //       //     {
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,

  //       //     },
  //       //     id
  //       //   ) => {
  //       //     let obj = {
  //       //       id,
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,
  //       //     };
  //       //     return obj;
  //       //   }
  //       // );

  //       // setTableArray(excelData)
  //       // setTableArray(responseJson)
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }
  

  const getTableData = async () => {
    const apiBodyPurchase = {
      Supplier_code: Bc?.value,
      Project_code: projectCode?.value,
      Buyer_ID: userID,
      // Plant_code: plant?.value
      // Po_Plant: plant?.value
      Po_Plant: selectedPlant?.value
    };
    
    const apiFormatedDataJson = JSON.stringify(isPurchaseUser ? apiBodyPurchase : apiBody);
  
    // Determine the URL based on user type
    const url = isVendorUser ? 'ShowVendorData' 
                : isPurchaseUser ? 'PurchaseByerDVAData' 
                : 'SupplierDvaCollectionAdminGDCshow';
    setLoader(true)
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormatedDataJson, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      console.log('table data response', response.data);
      
      const tableData = response.data.data;

      if (tableData.length === 0) {
        // Show popup if no records are found
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'No record found for the selected Combination.',
        });
      } else {
        setTableArray(tableData); // Update table with data if present
      }

      setTableArray(tableData);
      setLoader(false)
      
      // Optionally process the table data for Excel
      // const excelData = tableData.map(({
      //   Vendor_Code, Plant_code, Part_number, Part_Description, 
      //   Selling_Price, Value_of_Direct_Import_by_us,
      //   Broad_Description_of_parts_imported_by_us,
      //   Value_of_parts_imported_by_our_suppliers,
      //   Broad_Description_of_parts_imported_by_our_suppliers,
      //   Local_Content, DVA,
      // }, id) => ({
      //   id, Vendor_Code, Plant_code, Part_number, Part_Description, 
      //   Selling_Price, Value_of_Direct_Import_by_us,
      //   Broad_Description_of_parts_imported_by_us,
      //   Value_of_parts_imported_by_our_suppliers,
      //   Broad_Description_of_parts_imported_by_our_suppliers,
      //   Local_Content, DVA,
      // }));
  
      // setTableArray(excelData);
      
      setLoading(false);
    } catch (error) {
      setLoader(false)
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error('Bad Request: Check the sent data.', error.response.data);
        } else if (status === 404) {
          console.error('Not Found: The requested resource could not be found.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: error?.response?.data?.status,
          })
        } else if (status === 500) {
          console.error('Internal Server Error: An error occurred on the server.', error.response.data);
        } else {
          console.error('Error:', error.response.data);
        }
      } else {
        console.error('Network Error or the request was made but no response was received:', error.message);
      }
      setLoading(false);  // Ensure loading state is turned off even when an error occurs
    }
  }

  
  // const getTableData = async () => {
  //   const apiBodyPurchase = {
  //     Supplier_code: Bc?.value,
  //     Project_code: projectCode?.value,
  //     Buyer_ID: userID,
  //     Po_Plant: selectedPlant?.value ,
  //   };
  
  //   console.log('API Payload:', apiBodyPurchase); 
  
  //   const url = isVendorUser ? 'ShowVendorData'
  //               : isPurchaseUser ? 'PurchaseByerDVAData'
  //               : 'SupplierDvaCollectionAdminGDCshow';
  
  //   setLoader(true);
  //   try {
  //     const response = await axios.post(`${baseUrl}${url}/`, JSON.stringify(apiBodyPurchase), {
  //       headers: { 'Content-Type': 'application/json' },
  //     });
  
  //     console.log('Response:', response.data);
  //     setTableArray(response.data.data); // Set table data
  //   } catch (error) {
  //     console.error('API Error:', error);
  //     showPopup({
  //       type: MESSAGE_TYPE.INFORMATION,
  //       contextText: MESSAGE_TYPE.INFORMATION,
  //       info: error?.response?.data?.status || 'Something went wrong',
  //     });
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  

  // const getTableData1 = (value) => {
  //   const apiFormatedData = {
  //     VC: value,
  //   };
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson.data === null || responseJson.status === 500) {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Data not found",

  //         })
  //       } else {
  //         const tableData = responseJson.data;
  //         setTableArray(tableData);
  //       }

  //       // const excelData = tableData.map(
  //       //   (
  //       //     {
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,

  //       //     },
  //       //     id
  //       //   ) => {
  //       //     let obj = {
  //       //       id,
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,
  //       //     };
  //       //     return obj;
  //       //   }
  //       // );

  //       // exportExcel(excelData);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setDisable(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: `${error.message}`,
  //       });
  //     });
  // };

  // const getAllData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}AllSupplierDvaCollectionData/`, {
  //     method: "GET",
  //     headers: headerss,

  //   }).then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson === null || responseJson.length === 0) {
  //         // setTableArray(responseJson)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "No data available",

  //         })

  //       }
  //       else {
  //         setTableArray(responseJson)
  //         show(true)
  //         setLoading(false)
  //       }

  //     }).catch((error) => {
  //       console.log('error', error)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Error while fetching data.',
  //       })


  //     })

  // }
  console.log(auth.userRole)

  console.log('latestProject: ', latestProject?.value);
  const getData = (recentProj) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: "POST",
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson: ', responseJson);
      // Extract unique values for different fields
        const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_code))].filter(Boolean);
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniquePlants = [...new Set(responseJson.map(item => item.Po_Plant))].filter(Boolean);
        const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))].filter(Boolean);
        const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))].filter(Boolean);

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          console.log('MApping vendor: ', uniqueSuppliers.map(value => ({ value, label: value })))
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })));
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
        setFyArray(uniqueYears.map(value => ({ value, label: value })));

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log('User Role:', userRole, 'User ID:', userID, 'vendorID: ', vendorUserID);

        // Check user role to conditionally set BC state
        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
          console.log('bcArray: ', bcArray);
          
          const singleVendor = bcArray.find(item => item.value === userID);
          const singleVendor1 = temp.find(item => item.value === userID);
          console.log('singleVendor: ', singleVendor, singleVendor1);
          
          if (singleVendor1) {
            setBC(singleVendor1);
            setDisable(true); // Disable UI elements
          }
        } else {
          setDisable(false); // Enable UI elements
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      });
  };

  // const getData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}SupplierDvaCollection/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const unique_Supplier = []
  //       const Supplier = responseJson.filter(({ Supplier_Code }) => {
  //         if (!unique_Supplier.includes(Supplier_Code)) {
  //           unique_Supplier.push(Supplier_Code); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Supplier_Code }) => ({
  //         value: Supplier_Code,
  //         label: Supplier_Code,
  //       }));
  //       setBcArray(Supplier)
  //       console.log(bcArray)

  //       const projectCode_unique = []
  //       const project_Code_Array = responseJson.filter(({Project_code})=>{
  //         if (!projectCode_unique.includes(Project_code)) {
  //           projectCode_unique.push(Project_code); // Add to unique plants array
  //           return true; // Include this item in the filtered result
  //         }
  //         return false; 
  //       }).map(({Project_code})=>({ value: Project_code,label: Project_code}) )
  //       setProjectCodeArray(project_Code_Array)


  //       // const plant_Array = responseJson.filter(({ Plant_code }) => ({
  //       //   value: Plant_code,
  //       //   label: Plant_code,
  //       // }));
  //       const uniquePlants = []
  //       const plant_Array = responseJson.filter(({ Plant_code }) => {
  //         if (!uniquePlants.includes(Plant_code)) {
  //           uniquePlants.push(Plant_code); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Plant_code }) => ({
  //         value: Plant_code,
  //         label: Plant_code,
  //       }));


  //       setPlantArray(plant_Array);

  //       const unique_quarter = []
  //       const quarter_array = responseJson.filter(({ Quarter }) => {
  //         if (!unique_quarter.includes(Quarter)) {
  //           unique_quarter.push(Quarter); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Quarter }) => ({
  //         value: Quarter,
  //         label: Quarter,
  //       }));
  //       setQuarterArray(quarter_array)

  //       const unique_Fy = []
  //       const F_year = responseJson.filter(({ Financial_year }) => {
  //         if (!unique_Fy.includes(Financial_year)) {
  //           unique_Fy.push(Financial_year); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Financial_year }) => ({
  //         value: Financial_year,
  //         label: Financial_year,
  //       }));
  //       setFyArray(F_year)

  //       let { userRole, userID } = auth;
  //       console.log('single userRole', userRole, 'userID', userID);
  //       // const item1 = bcArray.find((item)=> item.value === userID)
  //       // // setBC(vendorList[0])
  //       // if(item1){
  //       //   setBC(item1)
  //       // }

  //       // setBC(userID)
  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         // const singleVendor = bcArray.filter(
  //         //   (element) => element.value == userID
  //         // );
  //         const singleVendor = bcArray.find((item)=> item.value === userID)
  //         console.log('single vendor list', singleVendor);
  //         console.log('single ListArray', bcArray);
  //         // if (singleVendor.length >= 1) {
  //         if (singleVendor) {  
  //           setBC(singleVendor);
  //           console.log('single Vendor', singleVendor[0]);
  //           setDisable(true);
  //           // show(true); // table show
  //           // getTableData1(singleVendor[0].value);
  //         }
  //       } else {
  //         // setTableArray(responseJson)
  //         setDisable(false);
  //       }
  //       console.log(projectCodeArray)
  //     })
  //     .catch((error) => {});
  // };

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  const exportExcel = async (excelData) => {
    // console.log('excelData: ', excelData);
    // const modifiedExcelData = excelData.map((row, index) => {
    //   const modifiedRow = { 'id': index, ...row };
    //   return modifiedRow;
    // });

    // let newExcelData

    // const vendorExcel = (row) =>  ({})
    // const otherThanVendorExcel = (row) => ({
    //   id: row.id,
    //   Supplier_Code: row.Supplier_Code,
    //   Project_code: row.Project_code,
    //   Plant_code: row.Plant_code,
    //   Quarter: row.Quarter,
    //   Financial_year: row.Financial_year,
    // })
    // console.log('other Vendor: ', otherThanVendorExcel);
    // if (isVendorUser) {
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          // Plant_code,
          Po_Plant,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,
          // IEC_of_Tier_I_Supplier,
          IEC_Code,
          Filename,
          Generate_certificate
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          // Plant_code,
          Po_Plant,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,
          // IEC_of_Tier_I_Supplier,
          IEC_Code,
          Filename,
          Generate_certificate,
        }
        return obj
      }
    )
    
    const excelDataExceptVendor = excelData?.map(
      (
        {
          Project_code,
          // Plant_code,
          Po_Plant,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          // Invoice_selling_price,

          /* Editing as said by Pritish on 03-10-2024 */
          BOM_price,

          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,
          // IEC_of_Tier_I_Supplier,
          IEC_Code,
          Filename,

          Ca_Sa,
          UDIN,
          DVA_certifying_egency,
          Supplier_invoice_no,
          // Supplier_net_value,
          // Supplier_discounted_value,
          Value_matched_Notmatched,
          
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          // Invoice_no_GDC,
          // Price_considered,
          GR_Date,
          GR_No,
          PO_Date,
          PO_No,
          Net_price,
          Gross_price,
          Supplementary_price,
          Total_net_price,
          Total_gross_price,
          System_Invoice_No_R,
          System_Invoice_No_S,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          // Plant_code,
          Po_Plant,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          // Invoice_selling_price, // Added this missing
          /* Editing as said by Pritish on 03-10-2024 */
          BOM_price,
          
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,
          // IEC_of_Tier_I_Supplier,
          IEC_Code,
          Filename,

          Ca_Sa,
          UDIN,
          DVA_certifying_egency,
          Supplier_invoice_no,
          // Supplier_net_value,
          // Supplier_discounted_value,
          Value_matched_Notmatched,
          
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          // Invoice_no_GDC,
          // Price_considered,
          GR_Date,
          GR_No,
          PO_Date,
          PO_No,
          Net_price,
          Gross_price,
          Supplementary_price,
          Total_net_price,
          Total_gross_price,
          System_Invoice_No_R,
          System_Invoice_No_S,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark
        }
        return obj
      }
    )

    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    // const ws = XLSX.utils.json_to_sheet(modifiedExcelData);
    const ws = XLSX.utils.json_to_sheet(isVendorUser ? newExcelData : excelDataExceptVendor)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileTypel })
    // FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
    // FileSaver.saveAs(data, `${projectCode?.value}_${Bc?.value}_${plant?.value}${fileExt}`);
    FileSaver.saveAs(data, `${projectCode?.value}_${Bc?.value}_${selectedPlant?.value}${fileExt}`);
    
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Plant code",
      // field: "Plant_code",
      field: "Po_Plant",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Code",
      field: "Supplier_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Financial Year",
      field: "Financial_year",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Quarter",
      field: "Quarter",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 210,
      title: "Part Description",
      field: "Part_description",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 200,
    //   title: "System Selling Price",
    //   field: "Selling_Price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: "Selling Price INR (excluding GST)",
      field: "Supplier_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Vendor Remark",
      field: "Vendor_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of Direct Import by us(in INR)*",
      field: "Value_of_Direct_Import_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by us",
      field: "Broad_Description_of_parts_imported_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of parts imported by our suppliers (in INR)* ",
      field: "Value_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by our suppliers",
      field: "Broad_Description_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Local Content",
      field: "Local_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 90,
      title: "DVA",
      field: "DVAPercentage",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    {
      width: 140,
      title: "Foreign exchange rate",
      field: "Foreign_Exchange_Rate",
      enableSearch: true,
      enableFilter: true,
    }, {
      width: 140,
      title: "Currency Name",
      field: "Currency_Name",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Currency Reference Date",
      field: "Reference_Date",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 140,
      title: "IEC Code",
      // field: "IEC_of_Tier_I_Supplier",
      field: "IEC_Code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Generate Certificate",
      field: "Generate_certificate",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "File Name",
      field: "Filename",
      enableSearch: true,
      enableFilter: true,
    },

    /* Approve/Reject Column Section */
    {
      width: 140,
      title: "TMLBSL Approve/Reject",
      field: "TMLBSL_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "TMLBSL Approve/Reject Remark",
      field: "TMLBSL_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject",
      field: "Purchase_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject Remark",
      field: "Purchase_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PMT Approve/Reject",
      field: "PMT_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PMT Approve/Reject Remark",
      field: "PMT_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
  ]
  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Plant code",
      field: "Plant_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Code",
      field: "Supplier_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Financial Year",
      field: "Financial_year",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Quarter",
      field: "Quarter",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 210,
      title: "Part Description",
      field: "Part_description",
      enableSearch: true,
      enableFilter: true,
    },
    // userRole?.includes('TMLBSL') &&
    {
      width: 200,
      // title: "BOM Selling Price",
      // title: "BOM Selling Price", // Editing as said by Pritish on 25-09-2024
      /* Editing as said by Pritish on 03-10-2024 */
      title: "BOM Price",
      // field: "Invoice_selling_price",
      field: "BOM_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Selling Price INR (excluding GST)",
      field: "Supplier_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Vendor Remark",
      field: "Vendor_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of Direct Import by us(in INR)*",
      field: "Value_of_Direct_Import_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by us",
      field: "Broad_Description_of_parts_imported_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of parts imported by our suppliers (in INR)* ",
      field: "Value_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by our suppliers",
      field: "Broad_Description_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Local Content",
      field: "Local_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 90,
      title: "DVA",
      field: "DVAPercentage",
      enableSearch: true,
      enableFilter: true,
    },
    
    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    
    {
      width: 140,
      title: "Foreign exchange rate",
      field: "Foreign_Exchange_Rate",
      enableSearch: true,
      enableFilter: true,
    }, {
      width: 140,
      title: "Currency Name",
      field: "Currency_Name",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Currency Reference Date",
      field: "Reference_Date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "IEC Code",
      // field: "IEC_of_Tier_I_Supplier",
      field: "IEC_Code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "File name",
      field: "Filename",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 140,
    //   title: "Reference CA_SA",
    //   field: "Ca_Sa",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 140,
      title: "CA_SA",
      field: "Ca_Sa",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "UDIN",
      field: "UDIN",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "DVA certifying agency",
      field: "DVA_certifying_egency",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Invoice No. (Supplier)",
      field: "Supplier_invoice_no",
      enableSearch: true,
      enableFilter: true,
    },

    /* Removed as said by Pritish on 25-09-2024 */
    // {
    //   width: 140,
    //   title: "Net Value",
    //   field: "Supplier_net_value",
    //   enableSearch: true,
    //   enableFilter: true,
    // }, 

    /* To be added as said by Pritish on 25-09-2024 */
    // {
    //   width: 140,
    //   title: "Contract Price",
    //   field: "Supplier_net_value",
    //   enableSearch: true,
    //   enableFilter: true,
    // }, 


    // {
    //   width: 140,
    //   title: "Discounted Value",
    //   field: "Supplier_discounted_value",
    //   enableSearch: true,
    //   enableFilter: true,

    // },
    {
      width: 140,
      title: "Value Matched/Not Matched",
      field: "Value_matched_Notmatched",
      enableSearch: true,
      enableFilter: true,

    },
    // {
    //   width: 140,
    //   title: "Remarks",
    //   field: "Remarks",
    //   enableSearch: true,
    //   enableFilter: true,

    // },
    {
      width: 140,
      title: "Invoices Submitted",
      field: "Invoice_submitted",
      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 140,
      title: "Invoice Considered(System/Supplier)",
      field: "Invoice_considered_System_Supplier",
      enableSearch: true,
      enableFilter: true,

    },

    /* Removed as said by Pritish on 25-09-2024 */

    // {
    //   width: 140,
    //   title: "Invoice No(GDC)",
    //   field: "Invoice_no_GDC",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title: "Price Considered",
    //   field: "Price_considered",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    /* For except Vendor */
    
    {
      width: 140,
      title: "GR Date",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "GR No",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PO Date",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PO No",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },


    /* Newly added 25-09-2024 */
    {
      width: 140,
      title: "Net Price",
      field: "Net_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Gross Price",
      field: "Gross_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Supplementary Price",
      field: "Supplementary_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Total Net Price",
      field: "Total_net_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Total Gross Price",
      field: "Total_gross_price",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 140,
      title: "System Invoice No (R)",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "System Invoice No (S)",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },

    /* Approve/Reject Column Section */
    {
      width: 140,
      title: "TMLBSL Approve/Reject",
      field: "TMLBSL_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "TMLBSL Approve/Reject Remark",
      field: "TMLBSL_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject",
      field: "Purchase_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject Remark",
      field: "Purchase_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PMT Approve/Reject",
      field: "PMT_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PMT Approve/Reject Remark",
      field: "PMT_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "CA_SA",
    //   field : "CA_SA",
    //   enableSearch : true,
    //   enableFilter : true,

    // },
    // {
    //   width: 140,
    //   title : "UDIN",
    //   field : "UDIN",
    //   enableSearch : true,
    //   enableFilter : true,

    // },
    // {
    //   width: 140,
    //   title : "DVA_certifying_agency",
    //   field : "DVA_certifying_agency",
    //   enableSearch : true,
    //   enableFilter : true,

    // },

  ]

  // const validationAPIFunction = () => {
  //   let url
  //   let { userRole } = auth;
  //   if (userRole.includes("Purchase")) {
  //     url = 'PurchaseUpdateValidationStatus'
  //     // url = "SupplierDataaddition"
  //     // urlMethod = "POST"
  //   } else if (userRole?.includes('TMLBSL')) {
  //     url = 'TMLBSLValidateMasterData'
  //   }
  //   const apiFormatedDataJson = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  //   fetch(`${baseUrl}${url}/`,
  //     {
  //       method: 'POST',
  //       headers: headerss,
  //       body: apiFormatedDataJson
  //     }).then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson?.ok) {
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: 'Data validation successful!'
  //         })
  //       } else {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Data validation failed!'
  //         })
  //       }
  //       history.push('/')
  //     })
  //     .catch((error) => {
  //     })
  // }
  // const goNextForm = () => {
  //   let url
  //   let { userRole, userID } = auth;
  //   if (userRole.includes("Purchase")) {
  //     url = 'SendBackOfPurchaseStatusAPIView_'
  //     // url = "SupplierDataaddition"
  //     // urlMethod = "POST"
  //   } else if (userRole?.includes('TMLBSL')) {
  //     url = 'SendBackOfTMLBSLStatus'
  //   }
  //   const apiFormatedDataJson = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  //   fetch(`${baseUrl}${url}/`,
  //     {
  //       method: 'POST',
  //       headers: headerss,
  //       body: apiFormatedDataJson
  //     }).then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson: ', responseJson);
  //       if (responseJson?.ok) {
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: 'Data sent back for Re-Submission'
  //         })
  //       } 
  //       else {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: 'Could not sent data for Re-Submission!'
  //         })
  //       }
  //       history.push('/')
  //     })
  //     .catch((error) => {
  //     })
  // }

  const validationAPIFunction = async () => {

    const apiBodyPurchase = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value,
      // Po_Plant: plant?.value,
      Po_Plant: selectedPlant?.value ,
      Buyer_ID: userID,
      Purchase_submitted_Name: userName,
      Purchase_submitted_Date: formattedCurrentDate
    }
    const apiBodyTMLBSL = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value, 
      // Plant_code: plant?.value,
      // Po_Plant: plant?.value,
      Po_Plant: selectedPlant?.value ,
      TMLBSL_submitted_Name: userName,
      TMLBSL_submitted_Date: formattedCurrentDate
    }
    const apiBodyPMT = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value, 
      // Plant_code: plant?.value,
      // Po_Plant: plant?.value,
      Po_Plant: selectedPlant?.value ,
      PMT_submitted_Name: userName,
      PMT_submitted_Date: formattedCurrentDate
    }

    let url
  
    if (isPurchaseUser) {
      url = 'PurchaseSubmissionstatusForSupplierDVA'
    } else if (isGDCUser) {
      url = 'TMLBSLSubmissionstatusForSupplierDVA'
    } else {
      url = 'PMTSubmissionstatusForSupplierDVA'
    }
  
    const apiFormattedDataJson = JSON.stringify(isGDCUser ? apiBodyTMLBSL : isPurchaseUser ? apiBodyPurchase : apiBodyPMT)
    const headerss = {
      'Content-Type': 'application/json'
    }
  
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss })
      console.log('response from axios: ', response)
      if (response.data?.ok || response?.status == '200') {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data submitted successfully!'
        })
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Data submission failed!'
        })
      }
  
      history.push('/')
    } catch (error) {
      console.error('API error: ', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred during data validation!'
      })
    }
    autoSendBackAPI()
  }
  

  const goNextForm = async () => {
    let url = 'PMTSendBackStatusForSupplierDva'

    const sendBackBodyPMT = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      // Po_Plant: plant?.value
      Po_Plant: selectedPlant?.value ,
    }

    const apiFormattedDataJson = JSON.stringify(sendBackBodyPMT)
    const headerss = {
      'Content-Type': 'application/json'
    }
    setLoader(true)
    try {
      const response = await axios.post(
        `${baseUrl}${url}/`,
        apiFormattedDataJson,
        { headers: headerss }
      )

      if (
        response.data?.ok ||
        response?.data?.status === 'Records updated successfully' ||
        response?.status == '200'
      ) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data sent back for Re-Submission'
        })
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Could not send data for Re-Submission!'
        })
      }

      history.push('/')
    } catch (error) {
      console.error('API error: ', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while sending data for Re-Submission!'
      })
      setLoader(false)
    }
  }

  
  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/')
  const submitSupplierDVACollection = async () => {
    const newAPIBody = {
      ...apiBody,
      Vendor_submitted_Date: formattedCurrentDate
    }
    const apiFormattedDataJson = JSON.stringify(newAPIBody);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    try {
      // const response = await axios.post(`${baseUrl}SubmitStatusUpdate/`, apiFormattedDataJson, { headers: headerss });
      const response = await axios.post(`${baseUrl}SupplierSubmissionstatus/`, apiFormattedDataJson, { headers: headerss });
      
      // Handle the response
      console.log("responseJson", response.data);
  
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Supplier DVA Submitted Successfully!",
      })
      history.push('/')
    } catch (error) {
      console.error('API error:', error);
      
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while submitting Supplier DVA!'
      });
    }
    autoSendBackAPI()
  }

  const autoSendBackAPI = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      // Plant_code: plant?.value
      // Po_Plant: plant?.value
      Po_Plant: selectedPlant?.value ,
    }
    try {
      await axios({
        method: 'POST',
        url: `${baseUrl}AutosendbackSupplierDVAubmissionstatus/`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: apiFormatedData,
      })
    } catch (error) {
      let errorMessage = 'An error occurred';
    
      if (error.response) {
        // Handle specific error codes
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request';
            break;
          case 401:
            errorMessage = 'Unauthorized';
            break;
          case 403:
            errorMessage = 'Forbidden';
            break;
          case 404:
            errorMessage = 'Not Found';
            break;
          case 500:
            errorMessage = 'Internal Server Error';
            break;
          default:
            errorMessage = `Error: ${error.response.statusText}`;
        }
      } else if (error.request) {
        // Handle cases where no response was received
        errorMessage = 'No response received from the server';
      } else {
        // Handle other errors
        errorMessage = `Error: ${error.message}`;
      }
      console.log('Error in autoSendBack', errorMessage)      
    } 
  }
  

  const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>
      {
        loading && (
          <div className={styles.gridLoader}>
            <GridLoadingSpinner />
          </div>
        )
      }

      <Grid
        container
        spacing={2}

        style={{ display: 'flex', justifyContent: "space-between" }}
        columns={12}
      >
        <Grid
          item
          sm={12}
          md={3}
          lg={3}
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
        <div
          style={{
            marginLeft: '5px',
            paddingRight: '',
            // marginTop: '1px',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '54%' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isMandatory={
                  auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
                }
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setProjectCode(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  getData(selectedOption)
                }}
              />
            </div>
            </div>
          </div>
        </Grid>

        <Grid 
          item 
          md={3} 
          lg={3} 
          // sm={12} 
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "30%" }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv} >
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                isMandatory={auth.userRole[0] === 'Vendor'}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setBC(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
              <label className={styles.label}>Plant</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Plant'
                options={plantArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={plant}
                isMulti={false}
                isClearable
                isMandatory={auth.userRole[0] === 'Vendor'}
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setPlant(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid> */}

        
   <Grid item md={3} lg={3}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
            <label className={styles.label}>Plant</label>
          </div>
          <div className={styles.formDiv}>
          <CustomSelect
              name="Plant"
              // options={plantArray1}
              options={plantArray1.map((plant1) => ({
                label: plant1.value, // Display the plant name
                value: plant1.value, // Use the plant code for value
              }))}
              className={clsx(styles.select, styles.sel1)}
              value={selectedPlant}
              isMulti={false}
              isClearable
              isMandatory={auth.userRole[0] === 'Vendor'}
              onChange={handlePlantChange}
            />
           
          </div>
        </div>
      </Grid>

      <Grid item md={3} lg={3}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
            <label className={styles.label}></label>
          </div>
          <div className={styles.formDiv}>
          <ValidatingTextField
  variant="outlined"
  size="small"
  fullWidth
  value={projectDescription1} // Bound to the updated state
  name="projDesc"
  inputProps={{
    'data-testid': 'remark-input',
  }}
  isDisabled // Boolean attribute corrected
/>
          </div>
        </div>
      </Grid>


        </Grid>

        {/* <Grid item sm={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
          {/* <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv} >
              <CustomSelect
                name="Fy"
                options={fyArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Fy}
                isMulti={false}
                // isMandatory = {auth.userRole === ''}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setFY(selectedOption)
                }}
              />
            </div>
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
          {/* <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
              <label className={styles.label}>Quarter</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="Quarter"
                options={quarterArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={quarter}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setQuarter(selectedOption)
                }}
              />
            </div>
          </div> */}
        {/* </Grid> */}

        {/* <Grid item md={2} lg={2} sm={12}>
         */}


      {/* Project Desc. View */}
        <Grid
          container
          spacing={1}

          style={{ display: 'flex', justifyContent: "space-between" }}
          columns={12}
        >
          <Grid
            item
            xs={5}
            md={5}
            lg={5}
            style={{
              marginTop: '5px',
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center'
            }}
          >
              <div className={styles.rowDiv}>
                <div className={clsx(styles.lableDiv)} style={{ width: '26%' }}>
                  <label className={styles.label}>Project Desc.</label>
                </div>
                {/* <div className={styles.formDiv}>
                    <Grid item xs={3} className={styles.alignCenter}> */}
                <div className={styles.formDiv}>
                  <ValidatingTextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    // value={vendorCode?.vendorCode}
                    // value={recentProjectCodeDescription && recentProjectCodeDescription}
                    value={projectDescription && projectDescription}
                    name='projDesc'
                    inputProps={{
                      'data-testid': 'remark-input'
                    }}
                    onChange={selectedOption => handleDropdownChange(selectedOption)}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isDisabled={true}
                  />
                </div>
                {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
                {/* </Grid> */}
                {/* </div> */}
              </div>
          </Grid>
          <Grid
            item
            md={3}
            lg={3}
            sm={3}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
          >
            <div
              style={{
                marginLeft: '5px',
                paddingRight: '',
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'right'
              }}
            >
              <AuthChecker operation='search'>
                {isAuthorized => (
                  <Button
                    className={clsx(styles.actionButton, styles.primaryActionButton)}
                    style={{ marginRight: '12px !important' }}
                    variant='primary'
                    //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                    onClick={showTable}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                  >
                    Show Data
                  </Button>
                )}
              </AuthChecker>
              {
                flag && (
                  <AuthChecker operation='search'>
                    {isAuthorized => (
                      <Button
                        className={clsx(styles.actionButton, styles.primaryActionButton)}
                        variant='primary'
                        //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                        onClick={() => exportExcel(tableArray)}
                        disabled={!isAuthorized}
                        data-testid='confirm-action'
                        style={{ marginLeft: '5px' }}
                      >
                        Download
                      </Button>
                    )}
                  </AuthChecker>
                )
              }

            </div>
          </Grid>
        </Grid>

    
    </div>
  )

  // const downloadFile = () => {
  //   const headerss = new Headers({
  //     'Content-Type': 'application/json'
  //   })
  //   // const formData = new FormData();
  //   // formData.append("file", e.target.files[0]);
  //   // formData.append("Supplier_Code",Bc?.value)
  //   console.log(Bc?.value)
  //   const body = JSON.stringify({
  //     Project_code: projectCode?.value,
  //     Supplier_code: Bc?.value,
  //     Plant_code: plant?.value,
  //   })
  //   // fetch(`${baseUrl}SupplierDvaCollection_PDF/`, {
  //   fetch(`${baseUrl}SupplierDva_PDF/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body

  //   }).then((response) => {

  //     const success = response.ok;

  //     response
  //       .blob()
  //       .then((blob) => {
  //         // Creating new object of PDF file
  //         console.log('blob: ', blob);
          
  //         const fileURL = window.URL.createObjectURL(blob);
  //         // Setting various property values

  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = "Certificate.pdf";
  //         alink.click();

  //       }).then(() => {
  //         if (success) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "File is downloaded successfully",
  //           });
  //         }

  //       })
  //       .catch((error) => {
  //         // catch any unexpected errors
  //         console.log(error);
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Document download failed.",
  //         });
  //       });
  //   });
  // };

  // const downloadFile = async () => {
  //   try {
  //     const body = {
  //       Project_code: projectCode?.value,
  //       Supplier_code: Bc?.value,
  //       Plant_code: plant?.value
  //     }

  //     const response = await axios({
  //       method: 'POST',
  //       url: `${baseUrl}SupplierDva_PDF/`,
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       data: body,
  //       responseType: 'blob' // Important to handle the response as a Blob
  //     })

  //     const blob = response.data
  //     console.log('blob: ', blob)

  //     // Creating a URL for the Blob object
  //     const fileURL = window.URL.createObjectURL(blob)

  //     // Creating a link element for downloading the file
  //     const link = document.createElement('a')
  //     link.href = fileURL
  //     // link.download = 'Certificate.pdf' // Default filename for download
  //     link.download = `${projectCode?.value}_${Bc?.value}_${plant?.value}.pdf` // Default filename for download
  //     link.click()

  //     // Optionally, revoke the object URL after download to free up memory
  //     window.URL.revokeObjectURL(fileURL)

  //     // Show success message
  //     showPopup({
  //       type: MESSAGE_TYPE.SUCCESS,
  //       contextText: MESSAGE_TYPE.SUCCESS,
  //       info: 'File is downloaded successfully'
  //     })
  //   } catch (error) {
  //     // Handle any errors
  //     console.error('Error:', error)
  //     let errorMessage = 'Document download failed.'

  //     if (error.response) {
  //       switch (error.response.status) {
  //         case 400:
  //           errorMessage = 'Bad Request'
  //           break
  //         case 401:
  //           errorMessage = 'Unauthorized'
  //           break
  //         case 403:
  //           errorMessage = 'Forbidden'
  //           break
  //         case 404:
  //           errorMessage = 'Not Found'
  //           break
  //         case 500:
  //           errorMessage = 'Internal Server Error'
  //           break
  //         default:
  //           errorMessage = `Error: ${error.response.statusText}`
  //       }
  //     } else if (error.request) {
  //       errorMessage = 'No response received from the server'
  //     } else {
  //       errorMessage = `Error: ${error.message}`
  //     }

  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       info: errorMessage
  //     })
  //   }
  // }

  const downloadFile = async () => {
    try {
      const body = {
        Project_code: projectCode?.value,
        Supplier_code: Bc?.value,
        // Plant_code: plant?.value
        // Po_Plant: plant?.value
        Po_Plant: selectedPlant?.value ,
      }
  
      const response = await axios({
        method: 'POST',
        // url: `${baseUrl}SupplierDva_PDF/`,
        /* Docx implementation instead of PDF approach */
        url: `${baseUrl}SupplierDva_DOCX/`,
        // headers: {
        //   'Content-Type': 'application/json',
          // 'Content-Type': 'application/octet-stream',
          // 'Filename': 'C24_P008_P83520_3100_file19.pdf'
          // 'Access-Control-Expose-Headers', 'x-xsrf-token'
          // 'access-control-expose-headers'
        // },
        data: body,
        // responseType: 'blob' // Important to handle the response as a Blob
      })
      
      // axios.post(`${baseUrl}SupplierDva_PDF/`, body).then((res) => {
      //   console.log('res .post: ', res.headers)
      // })

      console.log('response file: ', response)
      const blob = response.data?.file_url
      console.log('blob: ', blob)
  
      // Extract filename from the Content-Disposition header
      // let filename = 'downloaded_file.pdf' // Default filename
      let filename = response.headers['x-filename']
      console.log('Response header: ', response.headers['x-filename'])
      
      // const contentDisposition = response.headers['Content-Disposition']
      // console.log('Content: ', response.headers['Content-Disposition'])
      
  
      // if (contentDisposition && contentDisposition.includes('filename=')) {
      //   const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
      //   if (filenameMatch != null && filenameMatch[1]) {
      //     filename = filenameMatch[1].replace(/['"]/g, '') // Remove any surrounding quotes
      //   }
      // }
  
      // Creating a URL for the Blob object
      // const fileURL = window.URL.createObjectURL(blob)
  
      // Creating a link element for downloading the file
      const link = document.createElement('a')
      link.href =  blob // fileURL
      link.download = filename // Set filename extracted from header
      // link.click()
      document.body.appendChild(link)
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(link)
  
      // Optionally, revoke the object URL after download to free up memory
      // window.URL.revokeObjectURL(fileURL)
  
      // Show success message
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: 'File is downloaded successfully'
      })

      getTableData()
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
      let errorMessage = 'Document download failed.'
  
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request'
            break
          case 401:
            errorMessage = 'Unauthorized'
            break
          case 403:
            errorMessage = 'Forbidden'
            break
          case 404:
            // errorMessage = `Found no column values with Generate_certificate equals to 'Yes'.`
            errorMessage = `No data found for given combination.`
            break
          case 500:
            errorMessage = 'Internal Server Error'
            break
          default:
            errorMessage = `Error: ${error.response.statusText}`
        }
      } else if (error.request) {
        errorMessage = 'No response received from the server'
      } else {
        errorMessage = `Error: ${error.message}`
      }
  
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: errorMessage
      })
    }
  }
  
  

  const getUrl = (code) => {
    var msg = code;

    return <div>
      <p>Certificate Uploaded Successfully</p>
      <a href={msg} target="_blank" rel="noreferrer">
        {msg}
      </a>
    </div>
  }

  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();


  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);

  //     fetch(`${baseUrl}SupplierDVAdataUpload/`, {
  //       method: "PUT",
  //       body: formData,
  //   })
  //   .then((response) => {
  //       console.log(response);
  //       // return response.json(); // Parse JSON response
  //       if(response.ok){
  //         return response.json()
  //       }
  //       if(response.status === 400){
  //         return response.json().then(data => {
  //           console.log(data)
  //           throw new Error(data.status || 'Local Content does not match the calculated value');
  //       });

  //       }
  //       throw new Error('Network Error')

  //   })
  //   .then((myResponse) => {
  //       console.log('MyResponse', myResponse);
  //       getTableData()    
  //       showPopup({
  //                 type: MESSAGE_TYPE.SUCCESS,
  //                 contextText: MESSAGE_TYPE.SUCCESS,
  //                 info: "Document Uploaded Successfully"
  //             });


  //   })
  //   .catch((error) => {
  //       showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: `Error : ${error.message}`,
  //       });
  //   });
  //   };

  // }
  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();


  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDVAdataUpload' : 'SupplierDvaCollectionAdminGDC'
  //     const url = auth.userRole[0] === 'Vendor' ? 'UploadSupplierData' : 'SupplierDvaCollectionAdminGDC'
  //     fetch(`${baseUrl}${url}/`, {
  //       // fetch(`${baseUrl}SupplierDVAdataUpload/`, {
  //       // method: "PUT",
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => {
  //         console.log(response);
  //         // return response.json(); // Parse JSON response
  //         if (response.ok) {
  //           return response.json()
  //         }
  //         if (response.status === 400) {
  //           return response.json().then(data => {
  //             console.log(data)
  //             const error = data.status.match(/="(.*?)"/)[1];
  //             throw new Error(error || 'Local Content does not match the calculated value');
  //           });

  //         } if (response.status === 500) {
  //           return response.json().then(data => {
  //             console.log(data)
  //             throw new Error(data.status);
  //           });

  //         }
  //         return response.json().then(data => {
  //           console.log(data)
  //           throw new Error(data.statusText);
  //         });

  //       })
  //       .then((myResponse) => {
  //         console.log('MyResponse', myResponse);
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: "Document Uploaded Successfully"
  //         });

  //         getTableData()
  //       })
  //       .catch((error) => {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: `Error : ${error.message}`,
  //         });
  //       });
  //   };

  // }


  const uploadDocument = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.setAttribute('multiple', '')
    input.click()

      input.onchange = async e => {
        const formData = new FormData()
        const { files } = e.target
        // formData.append('file', e.target.files[0])
        Array.from(files).forEach((file) => {
          formData.append('file', file);
        });

      // const url =
      //   auth.userRole[0] === 'Vendor'
      //     ? 'UploadSupplierData'
      //     : 'SupplierDvaCollectionAdminGDC'

      const url = isVendorUser ? 'UploadSupplierData' : isPMTUser ? 'UploadPMTdataforSupplierDVA' : isGDCUser ? 'UploadTMLBSLdata' : 'UploadPurchasedata' 

      try {
        const response = await axios.post(`${baseUrl}${url}/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        console.log('Upload Response:', response)

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          })
          getTableData()
        }
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response
          console.log('Error Response:', data)

          let errorMessage = 'An error occurred during the document upload.'

          if (status === 400) {
            errorMessage = 
              // data?.status
              // data?.message
              
              // changing status to message Date: 12/11/2024
              // data?.status
              data?.message || data?.status

              /* Date: 12/11/2024 */
              // data?.status.match(/="(.*?)"/)?.[1] ||
              // 'Local Content does not match the calculated value'
          } else if (status === 500) {
            errorMessage = data?.status || 'Server encountered an issue.'
          } else {
            errorMessage = `Unexpected error: ${
              data?.statusText || error.message
            }`
          }

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            // info: `Error: ${errorMessage}`
            info: errorMessage
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message || 'Document upload failed'}`
          })
        }
      }
    }
  }

  


  const Uploadcertification = () => {
    var input = document.createElement("input")
    input.type = "file"
    input.id = "file"
    input.setAttribute("accept", ".pdf")
    input.multiple = true
    input.click()


    input.onchange = (e) => {
      const formData = new FormData()
      const { files } = e.target
      // formData.append("file", e.target.files[0])
      for (let i = 0; i < files.length; i += 1) {
        formData.append("files", files[i])
      }
      formData.append("Project_code", projectCode?.value);
      formData.append("Supplier_code", Bc?.value);
      // formData.append("Plant_code", plant?.value);
      // formData.append("Po_Plant", plant?.value);
      formData.append("Po_Plant", selectedPlant?.value);
      // fetch(`${baseUrl}SupplierDvaCollectionPdfUploadToBucket/`, {
      fetch(`${baseUrl}SupplierDvaCollectionPdfUploadToS3/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              //  contextText:getUrl(certificateUrl),
              contextText: "success",
              info: "Certificate Uploaded Successfully"
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: responseJson.url,
            });
          }
        })
        .catch((error) => {
          showPopup({
            type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            //  info: responseJson.url,
          })
        })
    }
  }

  const UploadMultipleCertification = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    // input.multiple = true;
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      const { files } = e.target
      // for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[0])
      // }
      formData.append('Project_code', projectCode?.value)
      formData.append('Supplier_code', Bc?.value)
      // formData.append('Plant_code', plant?.value)
      // formData.append('Po_Plant', plant?.value)
      formData.append("Po_Plant", selectedPlant?.value);


      try {
        const response = await axios.post(
          // `${baseUrl}MultipleSupplierDvaCollectionPdfUploadToS3/`,
          `${baseUrl}MultipleSupplierDvaCollectionPdfUploadToS3New/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        const responseJson = response.data
        console.log(responseJson)

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: 'success',
            info: 'Certificate Uploaded Successfully'
          })
        }
      } catch (error) {
        if (error.response) {
          // Handle specific status codes
          if (error.response.status === 400) {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: error?.response?.data?.error || 'Bad Request'
            })
          } else if (error.response.status === 404) {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: `Error 404: Not Found`
            })
          } else if (error.response.status === 500) {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: `Error 500: Internal Server Error`
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: `Error: ${
                error.response.statusText || 'An unexpected error occurred'
              }`
            })
          }
        } else {
          showPopup({
            type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            info: `Error: ${error.message || 'An unexpected error occurred'}`
          })
        }
      }
    }
  }

  
  // const Uploadannexure = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".pdf");
  //   input.click();
  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("Project_code", projectCode?.value)
  //     formData.append("Supplier_code", Bc?.value);
  //     formData.append("Plant_code", plant?.value)
  //     // fetch(`${baseUrl}SupplierDvaCollectionAnnexureUploadToBucket/`, {
  //     fetch(`${baseUrl}SupplierDvaCollectionAnnexureUploadToS3/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log(responseJson);
  //         if (responseJson) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             // info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
  //             info: 'Document Submitted Successfully!'
  //             //   info: responseJson.url,
  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             info: MESSAGE_TYPE_ANNEXURE.FAILURE
  //           });
  //         }


  //       })

  //       .catch((error) => {
  //         console.log('error:', error)
  //       });
  //   };
  // };

  // const downloadDvaCertificate = () => {
  //   const formData = new FormData()
  //   formData.append('Project_code', projectCode?.value)
  //   formData.append('Supplier_code', Bc?.value)
  //   formData.append('Plant_code', plant?.value)
  //   // const data = JSON.stringify({
  //   //   "project_code" : projectCode,
  //   //   "supplier_code" : Bc,
  //   //   "plant_code" : plant
  //   // })

  //   // fetch(`${baseUrl}DownloadSupplierDvaCollectionPDF/`, {
  //   fetch(`${baseUrl}SupplierDvaCollectionPdfDownloadAllFromS3InZip/`, {
  //     method: 'POST',
  //     // body: data,
  //     body: formData
  //   })
  //     .then(async response => {
  //       const res = await response.json()

  //       // console.log('DownloadSupplierAfsFile res: ', res)
  //       // Error case response
  //       // Object { error: "An error occurred while processing the request" }

  //       if (res?.error) {
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           info: 'Failed to download DVA Certificate'
  //         })
  //       } else {

  //         if (res?.status === 200 || res?.status === 201) {
  //           showPopup({
  //             type: MESSAGE_TYPE.INFORMATION,
  //             contextText: MESSAGE_TYPE.INFORMATION,
  //             info: 'Zip file is being downloaded, Please wait.'
  //           })
  //         }
  //         // eslint-disable-next-line camelcase
  //         const aws_url = res?.url
  //         const link = document.createElement('a')
  //         // eslint-disable-next-line camelcase
  //         link.href = aws_url
  //         document.body.appendChild(link)
  //         link.dispatchEvent(
  //           new MouseEvent('click', {
  //             bubbles: true,
  //             cancelable: true,
  //             view: window
  //           })
  //         )
  //         document.body.removeChild(link)
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Error while downloading Dva Certificate: ', error)
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.INFORMATION,
  //         info: 'Error while downloading Certificate'
  //       })
  //     })
  // }

  /**
   * Uploads an annexure file to the server.
   * Opens a file input dialog, and sends the selected PDF file along with project, supplier, and plant codes.
   * @param {string} projectCode - The code of the project for which the annexure is being uploaded.
   * @param {string} Bc - The supplier code.
  //  * @param {string} plant - The plant code.
   * @param {string} selectedPlant - The plant code.
   * @returns {void}
   */
  const Uploadannexure = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.setAttribute('multiple', '')
    input.click()

    input.onchange = e => {
      const formData = new FormData()
      const { files } = e.target
      // formData.append('file', e.target.files[0])
      Array.from(files).forEach((file) => {
        formData.append('files', file)
      });
      formData.append('Project_code', projectCode?.value)
      formData.append('Supplier_code', Bc?.value)
      // formData.append('Plant_code', plant?.value)
      // formData.append('Po_Plant', plant?.value)
      formData.append("Po_Plant", selectedPlant?.value);


      setLoader(true)
      axios
        // .post(`${baseUrl}SupplierDvaCollectionAnnexureUploadToS3/`, formData)
        .post(`${baseUrl}MultipleSupplierDvaCollectionPdfUploadToS3invoice/`, formData)
        .then(response => {
          const responseData = response.data

          if (response.status === 200 || response.status === 201) {
            setLoader(false)
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'Document Submitted Successfully!'
            })
          } else {
            setLoader(false)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Document uploading failed...`
            })
          }
        })
        .catch(error => {
          if (error.response) {
            setLoader(false)
            const { status } = error.response
            if (status === 400) {
              console.log('Bad Request: ', error.response.data)
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: error?.response?.data?.error
              })
            } else if (status === 404) {
              console.log('Not Found: ', error.response.data)
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Resource not found'
              })
            } else if (status === 500) {
              console.log('Internal Server Error: ', error?.response?.data)
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Server Error'
              })
            } else {
              console.log('Error: ', error?.response?.data)
            }

            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: error?.response?.data?.error || `Error occurred`
            })
          } else {
            console.log('Error: ', error.message)
          }
        })
    }
  }



  const downloadDvaCertificate = async () => {
    const formData = new FormData();
    formData.append('Project_code', projectCode?.value);
    formData.append('Supplier_code', Bc?.value);
    // formData.append('Plant_code', plant?.value);
    // formData.append('Po_Plant', plant?.value);
    formData.append("Po_Plant", selectedPlant?.value);

  
    showPopup({
      type: MESSAGE_TYPE.INFORMATION,
      contextText: MESSAGE_TYPE.INFORMATION,
      info: 'Zip file is being downloaded, Please wait...'
    })

    try {
      const response = await axios.post(
        // `${baseUrl}SupplierDvaCollectionPdfDownloadAllFromS3InZip/`,
        `${baseUrl}SupplierDvaCollectionPdfDownloadAllFromS3InZipNew/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
  
      const res = response.data;

      if (res?.error) {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Failed to download certificate'
        });
      } else if (response.status === 200 || response.status === 201) {
        console.log('response zip: ', response);
        
        const aws_url = res?.url;
        const link = document.createElement('a');
        link.href = aws_url;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        document.body.removeChild(link)

        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Zip file downloaded successfully!'
        })
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error('Bad Request: Check the sent data.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Failed to download certificate'
          })
        } else if (status === 404) {
          console.error('Not Found: The requested resource could not be found.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Failed to download certificate'
          })
        } else if (status === 500) {
          console.error('Internal Server Error: An error occurred on the server.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Failed to download certificate'
          })
        } else {
          console.error('Error:', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Failed to download certificate'
          })
        }
      } else {
        console.error('Network Error or the request was made but no response was received:', error.message)
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Failed to download certificate'
        })
      }
  
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Error while downloading Certificate'
      })
    }
  }

  // const downloadDvaDocuments = () => {
  //   const formData = new FormData()
  //   formData.append('project_code', projectCode?.value)
  //   formData.append('supplier_code', Bc?.value)
  //   formData.append('plant_code', plant?.value)
  //   // const data = JSON.stringify({
  //   //   "project_code" : projectCode,
  //   //   "supplier_code" : Bc,
  //   //   "plant_code" : plant
  //   // })

  //   fetch(`${baseUrl}DownloadSupplierDvaCollectionAnnexurePDF/`, {
  //     method: 'POST',
  //     // body: data,
  //     body: formData
  //   })
  //     .then(async response => {
  //       const res = await response.json()

  //       // console.log('DownloadSupplierAfsFile res: ', res)
  //       // Error case response
  //       // Object { error: "An error occurred while processing the request" }

  //       if (res?.error) {
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           info: 'Failed to Download Document'
  //         })
  //       } else {
  //         // eslint-disable-next-line camelcase
  //         const aws_url = res?.url
  //         const link = document.createElement('a')
  //         // eslint-disable-next-line camelcase
  //         link.href = aws_url
  //         document.body.appendChild(link)
  //         link.dispatchEvent(
  //           new MouseEvent('click', {
  //             bubbles: true,
  //             cancelable: true,
  //             view: window
  //           })
  //         )
  //         document.body.removeChild(link)
  //       }
  //     })
  //     .catch(error => {
  //       console.log('Error while downloading Dva Documents: ', error)
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.INFORMATION,
  //         info: 'Error while downloading Document'
  //       })
  //     })
  // }

  const downloadDvaDocuments = async () => {
    const formData = new FormData();
    formData.append('Project_code', projectCode?.value);
    formData.append('Supplier_code', Bc?.value);
    // formData.append('Plant_code', plant?.value);
    // formData.append('Po_Plant', plant?.value);
    formData.append("Po_Plant", selectedPlant?.value);


    showPopup({
      type: MESSAGE_TYPE.INFORMATION,
      contextText: MESSAGE_TYPE.INFORMATION,
      info: 'Zip file is being downloaded, Please wait...'
    })

    try {
      const response = await axios.post(
        // `${baseUrl}DownloadSupplierDvaCollectionAnnexurePDF/`,
        `${baseUrl}SupplierDvaCollectionOtherDownloadAllFromS3InZip/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
  
      const res = response.data;
  
      if (res?.error) {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to Download Document'
        });
      } else {
        const aws_url = res?.url;
        const link = document.createElement('a');
        link.href = aws_url;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        document.body.removeChild(link)
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Zip file downloaded successfully!'
        })
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error('Bad Request: Check the sent data.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Zip file download failed.'
          })
        } else if (status === 404) {
          console.error('Not Found: The requested resource could not be found.', error.response.data)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Zip file download failed.'
          })
        } else if (status === 500) {
          console.error('Internal Server Error: An error occurred on the server.', error.response.data);
        } else {
          console.error('Error:', error.response.data);
        }
      } else {
        console.error('Network Error or the request was made but no response was received:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Zip file download failed.'
        })
      }
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Error while downloading Document'
      })
    }
  }

  const secondaryActions = [
    {
      name: "Upload",
      authOperation: "upload",
      // shouldEnable: (selected) => true,
      shouldEnable: () => submitButtonFlag(),
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // handleOpen();
        uploadDocument()
      },
      customClass: "",
    },
    isVendorUser && {
      name: "Generate Certificate",
      authOperation: "submit",
      // shouldEnable: (selected) => true,
      shouldEnable: () => submitButtonFlag(),
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        downloadFile();
      },
      customClass: "",
    },
    // isVendorUser && {
    //   name: "Upload Certificate",
    //   authOperation: "uploadCertificate",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
    //   actionFn: (selected) => {
    //     Uploadcertification();
    //   },
    //   customClass: "",
    // },
    isVendorUser && {
      name: "Upload Certificate",
      authOperation: "uploadCertificate",
      // shouldEnable: (selected) => true,
      shouldEnable: () => submitButtonFlag(),
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
      actionFn: (selected) => {
        UploadMultipleCertification()
      },
      customClass: "",
    },
    isVendorUser && {
      // name: "Other Docs.",
      name: "Upload Invoice(s)",
      authOperation: "uploadAnnexure",
      shouldEnable: (selected) => true,
      // shouldEnable: () => submitButtonFlag(),
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        Uploadannexure();
      },
      customClass: "",
    },
    {
      name: "Download Certificate",
      // authOperation: "uploadAnnexure",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','TMLBSL','GDC','Purchase'),
      actionFn: (selected) => {
        downloadDvaCertificate();
      },
      customClass: "",
    },
    {
      // name: "Download Documents",
      name: "Download Invoices",
      // authOperation: "uploadAnnexure",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable: (selected) => auth.userRole.some(role => ['admin', 'TMLBSL', 'GDC', 'Purchase'].includes(role)) ,
      actionFn: (selected) => {
        // Uploadannexure();
        downloadDvaDocuments()
      },
      customClass: "",
    },

  ]
  const primaryActions = [
    isVendorUser && {
      name: 'Submit Data',
      authOperation: 'submit',
      // shouldEnable: () => true,
      shouldEnable: () => submitButtonFlag(),
      actionFn: handleOpenSubmissionPopup
    },
    (isGDCUser || isPMTUser || isPurchaseUser) && {
      name: 'Submit Data',
      authOperation: 'submit',
      shouldEnable: () => submitButtonFlag(),
      actionFn: handleOpenValidatePopup
      // actionFn: handleOpenFormModal,
    },
    isPMTUser && {
      name: 'Send Back',
      authOperation: 'submit',
      shouldEnable: () => true,
      actionFn: handleOpenFormModal
    }
  ]


  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions;
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase') || auth.userRole.includes('PMT')) {
      return secondaryActions.filter((item) => 
        // item.name === 'Download Documents' || 
        item.name === 'Download Invoices' || 
        item.name === 'Download Certificate' || 
        // item.name === 'Upload Invoice(s)' || 
        item.name === 'Upload');
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter((item) => 
        // item.name !== 'Download Documents' && 
        item.name !== 'Download Invoices' && 
        item.name !== 'Download Certificate');
    }
    return secondaryActions;
  };
  const handlePrimaryActionOnRole = () => {
    if (auth.userRole.includes('Vendor')) {
      return primaryActions.filter((item) => item.name === 'Submit Data')
    }
    if (auth.userRole.includes('PMT') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase') || auth.userRole.includes('admin')) {
      return primaryActions.filter((item) => item.name === 'Submit Data' || item.name === 'Send Back')
    }
    return primaryActions
  }

  // console.log('tableArray',tableArray);
  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Supplier DVA and Declaration" withOutCount isSelected />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              // columns={[...baseDefaultColumns]}
              // defaultColumns={[...baseDefaultColumns]}
              columns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              defaultColumns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={handlePrimaryActionOnRole()}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e) => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ValidationModal
        isValidatePopupOpen={isValidatePopupOpen}
        handleCloseValidatePopup={handleCloseValidatePopup}
        validationAPIFunction={validationAPIFunction}
      />
      <SubmitDataModal
        isSubmissionPopupOpen={isSubmissionPopupOpen}
        handleCloseSubmissionPopup={handleCloseSubmissionPopup}
        submitSupplierDVACollection={submitSupplierDVACollection}
      />
    </>
  );
};
function ValidationModal({ isValidatePopupOpen, handleCloseValidatePopup, validationAPIFunction }) {
  return (
    <Dialog
      open={isValidatePopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Validation</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be validated.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseValidatePopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseValidatePopup()
              validationAPIFunction()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SubmitDataModal({ isSubmissionPopupOpen, handleCloseSubmissionPopup, submitSupplierDVACollection }) {
  return (
    <Dialog
      open={isSubmissionPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submission</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be Submitted.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseSubmissionPopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseSubmissionPopup()
              submitSupplierDVACollection()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Send Back</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be sent back for Re-Submission</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
}

ValidationModal.propTypes = {
  isValidatePopupOpen: PropTypes.bool.isRequired,
  handleCloseValidatePopup: PropTypes.func.isRequired,
  validationAPIFunction: PropTypes.func.isRequired,
}
SubmitDataModal.propTypes = {
  isSubmissionPopupOpen: PropTypes.bool.isRequired,
  handleCloseSubmissionPopup: PropTypes.func.isRequired,
  submitSupplierDVACollection: PropTypes.func.isRequired,
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);

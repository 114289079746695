/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
// import $ from 'jquery';
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./BOM_BOE_ReportMIS.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from "../../components/StepBar1/StepBar";

const VendarData = () => {
  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  const { userName } = auth
  console.log("auth in Supplier: ", auth);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [projectCode, setProjectCode] = useState(null);
  const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false); // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null);
  console.log("Project state: ", selectedProjectCode);
  const [latestProject, setLatestProject] = useState("");
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [fyArray, setFyArray] = useState([]);
  const [Fy, setFY] = useState(null);
  const [quarterArray, setQuarterArray] = useState([]);
  const [quarter, setQuarter] = useState(null);
  const [asnCount, setAsnCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  })

  const [boeStatus, setBoeStatus] = useState()

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  };
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  //

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  }

  const showTable = () => {
    console.log("ProjectCode: ", projectCode);
    console.log("Project value: ", projectCode?.value);
    // if(projectCode === null || projectCode.value === ''){
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === "" ||
      selectedProjectCode === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Project Code is Mandatory",
      });
    } else {
      // getTableData()
      getBOEBOMStatusForMIS()
      show(true)
    }
  };

  // let Vendor_Code ='';
  // const handleSelect = (selectedOption, name) => {
  //   // alert(selectedOption.value);
  //   // console.log(selectedOption);
  //   setvendCode(selectedOption.value);
  //   if (name == "VC") {
  //     show(false);

  //     setBC(selectedOption);
  //     // getPlantData(selectedOption)
  //   } else {
  //     setPlant(selectedOption);
  //   }
  // };

  // const getTableData = async () => {
  //   console.log("Selected Project Code:", selectedProjectCode?.value);
  //   console.log("Selected VC:", Bc?.value);

  //   const apiFormattedData = {
  //     Project_code: selectedProjectCode?.value,
  //     VC: Bc?.value,
  //   };

  //   const apiFormattedDataJson = JSON.stringify(apiFormattedData);
  //   console.log("Formatted API Body:", apiFormattedDataJson);

  //   try {
  //     const response = await axios.post(
  //       `${baseUrl}ShowBOEData/`,
  //       apiFormattedData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("API Response:", response.data);

  //     if (response.data.error) {
  //       alert(`API Error: ${response.data.error}`);
  //       setLoading(false);
  //     } else if (Array.isArray(response.data)) {
  //       // If the response is an array, set it directly
  //       console.log("Table Data:", response.data);
  //       const arr = response.data.map((item) => ({
  //         ...item,
  //         Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
  //         is_deleted: item?.is_deleted === true ? "True" : "False",
  //         AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
  //       }));
  //       setTableArray(arr);
  //       setLoading(false);
  //     } else {
  //       // Unexpected format
  //       alert("Unexpected API response format.");
  //       console.log("Unexpected API response format:", response.data);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     alert("Error fetching data, please try again.");
  //     setLoading(false);
  //   }
  // }

  const getBOEBOMStatusForMIS = async () => {
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value
    }
    try {
      const response = await axios.post(
        `${baseUrl}BOEBOMStatusForMIS/`,
        apiFormattedData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      if (response.data.error) {
        // alert(`API Error: ${response.data.error}`)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'No data available'
        })
        setLoading(false)
      } 
      // else if (Array.isArray(response.data)) {
      //   console.log('BOE Data response: ', response?.data);
        
      //   setLoading(false)
      // } 
        else {
        // Unexpected format
        // alert("Unexpected API response format.");
        // showPopup({
          //   type: MESSAGE_TYPE.FAILURE,
          //   contextText: MESSAGE_TYPE.FAILURE,
          //   info: 'Unexpected Error'
          // })
          // console.log('Unexpected API response format:', response.data)
        setBoeStatus(response?.data)

        setTableArray([response?.data])
        setLoading(false)
      }
    } catch (error) {
      console.error('Fetch error:', error)
      // alert("Error fetching data, please try again.");
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Error while fetching data.'
      })
      setLoading(false)
    }
  }

  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforBOE/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions);
      await getRecentProjectCode(projectCodeOptions);
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  };

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforBOE/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      };
      setLatestProject(recentProj);
      setSelectedProjectCode(recentProj); // Automatically select the latest project code
    } catch (error) {
      console.error("Error fetching recent project codes:", error);
    }
  };

  useEffect(() => {
    getAllProjectCode();
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject);
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(selectedProjectCode.value === latestProject.value);
    }
  }, [selectedProjectCode, latestProject])

  function submitButtonFlag() {
    let buttonFlag
    if (boeStatus?.BOE_status === 'Submitted' && isLatestProjectSelected) {
      buttonFlag = false
    }
    else if (boeStatus?.BOE_status !== 'Submitted' && isLatestProjectSelected) {
      buttonFlag = true
    }
    return buttonFlag
  }

  // const getVcForProjectCode = (selectedCode) => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");

  //   fetch(`${baseUrl}VCForProjectCode/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: JSON.stringify({ Project_code: selectedCode?.value }),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const uniqueVc = [
  //         ...new Set(
  //           responseJson
  //             .filter((item) => item.VC !== null)
  //             .map((item) => item.VC)
  //         ),
  //       ];
  //       setBcArray(uniqueVc.map((value) => ({ value, label: value })));

  //       const { userRole, userID } = auth;

  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         const singleVendor = uniqueVc.find((item) => item === userID);
  //         if (singleVendor) {
  //           setBC({ value: singleVendor, label: singleVendor });
  //           setDisable(true);
  //         }
  //       } else {
  //         setDisable(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching VC:", error);
  //     });
  // };

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}UploadBOEData/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            // getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => {});
    };
  };

  const exportExcel = async (excelData) => {

    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Part_no,
          Part_description,
          BOE_number,
          BOE_date,
          CIF_value,
          BOE_part_quantity,
          BOE_IEC_of_TML
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Part_no,
          Part_description,
          BOE_number,
          BOE_date,
          CIF_value,
          BOE_part_quantity,
          BOE_IEC_of_TML
        }
        return obj
      }
    )
    console.log('inside Export Excel 2: ', newExcelData)

    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(newExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `BOM_BOE_Data${fileExt}`);
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: "BOE Satus",
      field: "BOE_status",
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 190,
      title: "BOE Validation Date",
      field: "BOE_validation_date",
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 190,
      title: "BOE Validator Name",
      field: "BOE_validater_name",
      enableSearch: true,
      enableFilter: true
    }
  ]

  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/');
  const BOMvalidationAPI = () => {
    // BOMvalidationAPIView
    console.log('inside submit api: ', Bc?.value);
    const apiBody = {
      Project_code: selectedProjectCode?.value,
      // VC: Bc?.value,
      BOE_validation_date: formattedCurrentDate,
      BOE_validater_name: userName
    }
    var body = JSON.stringify(apiBody);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // fetch(`${baseUrl}BOMvalidationAPIView/`, {
    fetch(`${baseUrl}UpdateBOEStatus/`, {
      method: 'POST',
      headers: headerss,
      body,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson', responseJson);
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "BOE Validation Successful!",
        });
        history.push("/");
      })
      .catch((error) => {
        console.log('error in submit: ', error);
      });
  }

  const goNextForm = () => {
    // var url;
    // var urlMethod;

    // let { userRole, userID } = auth;

    // if (userRole.includes("Purchase") || userRole.includes("Purchase-CV")) {
    //   url = "SupplierDataaddition";
    //   urlMethod = "POST";
    // }

    // const apiFormatedData = {
    //   VC: Bc?.value,
    // };

    // var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    // let headerss = new Headers();
    // headerss.append("Content-Type", "application/json");
    // fetch(`${baseUrl}${url}/`, {
    //   method: urlMethod,
    //   headers: headerss,
    //   body: apiFormatedDataJson,
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     showPopup({
    //       type: MESSAGE_TYPE.SUCCESS,
    //       contextText: MESSAGE_TYPE.SUCCESS,
    //       info: "Data is Succesfully Saved",
    //     });
    //     history.push("/");
    //   })
    //   .catch((error) => {});
  };

  const redirectToCreateProjectPage = () => history.push("/");

  const renderHTML = () => (
    <div className={styles.formGroup} >
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "left" }}
        columns={12}
      >
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "50%" }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="Project Code"
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={(selectedOption) => {
                  setSelectedProjectCode(selectedOption);
                  // getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid
          item
          md={5}
          lg={3}
          sm={12}
          style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "30%" }}>
              <label className={styles.label}>VC</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  setBC(selectedOption);
                }}
              />
            </div>
          </div>
        </Grid> */}

        {/* <Grid item md={2} lg={2} sm={12}>
         */}

        <Grid
          item
          md={5}
          lg={4}
          sm={12}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            style={{
              marginLeft: "5px",
              paddingRight: "10px",
              marginTop: "1px",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <AuthChecker operation="search">
              {(isAuthorized) => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: "12px !important" }}
                  variant="primary"
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid="confirm-action"
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>

            {/* {!auth.userRole.includes("Vendor") ? (
              <AuthChecker operation="search">
                {(isAuthorized) => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{
                      marginRight: "12px !important",
                      marginLeft: "5px",
                    }}
                    variant="primary"
                    onClick={() => {
                      getAllData();
                    }}
                    disabled={!isAuthorized}
                    data-testid="confirm-action"
                  >
                    Show All
                  </Button>
                )}
              </AuthChecker>
            ) : null} */}

            {/* {
      loader==false&&
  
        <>
      <AuthChecker operation="download">

        {isAuthorized => (
             <Button
               className={clsx(
                 styles.actionButton,
                 styles.primaryActionButton,
               )}
               variant="primary"
               //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
               onClick={()=>{

                if(tableArray.length>=1){exportExcel(tableArray);}
               }}
                disabled={!isAuthorized}
               data-testid="confirm-action"
               style={{marginLeft:"5px"}}

             >
               download
             </Button>
           )}
 
         </AuthChecker>
     
     </> 
  } */}
          </div>
        </Grid>
      </Grid>
    </div>
  );

  const uploadDocument = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".xlsx");
    input.click();
  
    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      // formData.append("Supplier_Code", Bc?.value);
      // formData.append('Project_code', projectCode?.value)
      // formData.append('')
      fetch(`${baseUrl}UploadBOEData/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response.json().then((errorData) => {
            throw new Error(errorData.status || "Unknown error");
          });
        })
        .then((responseData) => {
          console.log(responseData);
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: "Document Uploaded Successfully",
          });
          // getTableData();
        })
        .catch((error) => {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message}`,
          });
        });
    };
  };
  

  const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
    //   shouldEnable: () => submitButtonFlag(),
    //   actionFn: () => {
    //     uploadDocument();
    //   },
    //   customClass: "",
    // },

    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
        // getDataForExcel()
        exportExcel(tableArray);
      },
      customClass: "",
    }
  ];

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes("admin")) {
      return secondaryActions;
    }
    if (auth.userRole.includes("GDC") || auth.userRole.includes("TMLBSL")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate" ||
          item.name === "Upload"
      );
    }
    if (auth.userRole.includes("Vendor")) {
      return secondaryActions.filter(
        (item) =>
          item.name !== "Download Documents" &&
          item.name !== "Download Certificate"
      );
    }
    if (auth.userRole.includes("Purchase")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate"
      );
    }
    return secondaryActions;
  };

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: "10px" }}
          >
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: "0px" }}
            />
            <div
              style={{ width: "100%" }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: "flex", height: "3em" }}
                >
                  <div style={{ width: "100%" }}>
                    <CustomTab title="BOE - Report" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
             <Table
        columns={
          !auth.userRole.includes("Vendor")
            ? [...baseDefaultColumns]
            : [...baseDefaultColumns]
        }
        defaultColumns={[...baseDefaultColumns]}
        rowHeight={38}
        rows={tableArray}
        // primaryAction={{
        //   name: "Submit Data",
        //   authOperation: "submit",
        //   // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
        //   shouldEnable: () => submitButtonFlag(),
        //   actionFn: handleOpenFormModal,
        // }}
        secondaryActions={secondaryActions}
        setAsnCount={setCount}
        isDataLoading={loader}
        actionButtons
        onRowSelect={(e) => {}}
        removeRowSelection
      />

          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, BOE will be validated</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);
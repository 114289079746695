import moment from "moment";
import config from '../config/config';
import { DATE_FORMAT } from '../constants';

const { API_BASE_URL } = config;

// eslint-disable-next-line import/prefer-default-export
export const logoutAndClearData = keycloak =>
  keycloak.logout().then(() => {
    window.keycloak = null;
    window.location.href = '/';
  });


  export const getDatesForRange = (startDate, endDate) => {
    const startDateCopy = moment(startDate, DATE_FORMAT.ISO);
    const endDateCopy = moment(endDate, DATE_FORMAT.ISO);
  
    const dates = [];
    while (startDateCopy.isSameOrBefore(endDateCopy, 'day')) {
      dates.push(moment(startDateCopy));
      startDateCopy.add(1, 'days');
    }
    return dates;
  };

export const getISTTime = unixTimeInSeconds =>
  moment.unix(unixTimeInSeconds).format('hh:mm:ssa');

export const getApiRelativeUrl = url =>
  url.substring(API_BASE_URL.length)

export const convertTimestampToIndianDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
} 
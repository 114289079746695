

/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Grid
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
// import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
// import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table1 from "../../components/Table/Table";
// import Table from "../../../components/Table1/Table1";
import Table from '../../components/TableForAdmin/TableForAdmin'

import { AuthChecker, Button, CustomTab } from "../../atomicComponents";
import BackButton from "../../components/BackButton/BackButton";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import {
  API_RESOURCE_URLS,
  DATE_FORMAT,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_ANNEXURE,
  MESSAGE_TYPE_CERTIFICATION,
  RESOURCE_TYPE,
  Regex,
  USER_OPERATIONS,
} from "../../constants";
// import FileSaver from 'file-saver'
import { withAllowedOperationsProvider } from "../../hocs";
import styles from "./PLReportMIS.module.css";
// import { API } from "../../../apis/api";
// import { buildErrorMessage } from "../../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

// import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const AFSMaster = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  //   const [plant, setPlant] = useState(null);
  //   const [flag, show] = useState(false);
  //   const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [flag, show] = useState(false);
  //   const [projectCode,setProjectCode] = useState(null)
  //   const [bcArray, setBcArray] = useState([]);
  //   const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0)
  //   const [Fy,setFY] = useState(null)
  //   const [fyArray,setFyArray] = useState([])
  //   const [quarter,setQuarter] = useState(null)
  //   const [quarterArray,setQuarterArray] = useState([])
  //   const [projectCodeArray,setProjectCodeArray] = useState([])

  const [tableData, setTableData] = useState([])
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  //   const [vendCode, setvendCode] = useState();
  //   const [datesData, setDates] = useState({
  //     endDate:"",
  //     startDate:"",
  //   });

  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false)
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState("")

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  const [confirmWelcomeEmailPopup, setConfirmWelcomeEmailPopup] = useState(false)
  const handleOpenConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(true)
  const handleCloseConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(false)

  const [confirmSummaryEmailPopup, setConfirmSummaryEmailPopup] = useState(false)
  const handleOpenConfirmSummaryEmailPopup = () => setConfirmSummaryEmailPopup(true)
  const handleCloseConfirmSummaryEmailPopup = () => setConfirmSummaryEmailPopup(false)

  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })



  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const setCount = (count) => {
    setAsnCount(count);
  }

  const headers = {
    'Content-Type': 'application/json',
  }


  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }
  const getData = async () => {
  
    try {
      const response = await axios.get(`${baseUrl}SyncUniqueVCAPIView/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
  
      console.log('axios response: ', response?.data)
      
      if (response.status === 200) {
        const Jsonresponse = response.data;
        
        // const updatedResponse = Jsonresponse.map((item) => ({
        //   ...item,
        //   Afs_Document_mandatory: item.Afs_Document_mandatory === false ? 'False' : 'True',
        //   Test1: (item?.Test1 == 'True' || item?.Test1 == 'TRUE') ? 'True' : 'False',
        // }));
  
        // setTableData(updatedResponse);
      } else {
        throw new Error(response.data.status);
      }
    } catch (error) {
      console.error('Error:', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error : ${error.message}`,
      });
    }
  }
  


  // Download All AFS Document API
  const downloadAllAFSDocumentsZip = () => {
    // const formData = new FormData()
    // formData.append('Vendor_code', vendorCode?.vendorCode)
    // formData.append('Financial_year', vendorCode?.financialYear?.value)
    showPopup({
      type: MESSAGE_TYPE.INFORMATION,
      contextText: MESSAGE_TYPE.INFORMATION,
      info: 'ZIP File is being downloaded. Please wait.'
    })
    fetch(`${baseUrl}DownloadAllPDFFilesfromS3/`, {
      method: 'GET',
    })
      .then(async response => {
        const res = await response.json()
        // showPopup({
        //   type: MESSAGE_TYPE.INFORMATION,
        //   contextText: MESSAGE_TYPE.INFORMATION,
        //   info: 'File is being downloaded. Please wait.'
        // })
        if (res?.error) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Zip file download failed!'
          })
        } else {
          // eslint-disable-next-line camelcase
          const aws_url = res?.url
          const link = document.createElement('a')
          // eslint-disable-next-line camelcase
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Zip file is downloaded successfully!'
          })
        }
      })
      .catch(error => {
        console.log('Error while calling DownloadSupplierAfsFile API: ', error)
      })
  }

  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      // getProjectDescription()
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
    // else if (name === 'projDesc') {
    //   console.log('inside projDesc');
      
    //   setSelectedProjectCode(selectedOption)
    //   getProjectDescription(selectedOption)
    // }
  }

  const showTable = () => {
    // console.log("ProjectCode: ", projectCode)
    // console.log("Project value: ", projectCode?.value)
    // if(projectCode === null || projectCode.value === ''){
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === "" ||
      selectedProjectCode.value === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Project Code is Mandatory",
      })
    } 
    // else if (Bc === null || Bc?.value === '' || Bc?.value === null || Bc === undefined) {
    //   showPopup({
    //     type: MESSAGE_TYPE.INFORMATION,
    //     contextText: MESSAGE_TYPE.INFORMATION,
    //     info: "VC is Mandatory",
    //   })
    // } 
    else {
      // getProjectDescription()
      // getTableData();
      getPLStatusAPIView()
      show(true);
    }
  };

  useEffect(() => {
    // console.log(tableData)
    getVCSPOCData()
    getData()
    getAllProjectCode()
    getRecentProjectCode()
    // getPLStatusAPIView()
  }, [])
  
  const getPLStatusAPIView = async () => {
    const body = {
      Project_code: selectedProjectCode?.value
    }
    try {
      const response = await axios.post(`${baseUrl}PLStatusAPIView/`, body, {
        headers
      })

      console.log('axios response: ', response?.data)
      
      if (response.status === 200) {
        const Jsonresponse = response?.data;
        
        // const updatedResponse = Jsonresponse.map((item) => ({
          
        // }));
  
        setTableData(Jsonresponse);
        // setTableData(updatedResponse);
      } else {
        throw new Error(response.data.status);
      }
    } catch (error) {
      console.error('Error:', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error : ${error.message}`,
      });
    }
  }

  const getVCSPOCData = async () => {
    try {
      const response = await axios.get(`${baseUrl}VCSOPCMappingAPIView/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
  
      console.log('axios response: ', response?.data)
      
      if (response.status === 200) {
        const Jsonresponse = response?.data;
        
        // const updatedResponse = Jsonresponse.map((item) => ({
          
        // }));
  
        // setTableData(Jsonresponse);
        // setTableData(updatedResponse);
      } else {
        throw new Error(response.data.status);
      }
    } catch (error) {
      console.error('Error:', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error : ${error.message}`,
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      // const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`)
      console.log('data in getall', data)
      
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }))
      setProjectCodeArray(projectCodeOptions)
    } catch (error) {
      console.error("Error fetching project codes:", error)
    }
  };

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`)
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      // console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      };
      setLatestProject(recentProj);
      setSelectedProjectCode(recentProj); // Automatically select the latest project code
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            // getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  const exportExcel = async (tableArray1) => {
    const excelData = tableArray1?.map(
      (
        { 
          Project_code, 
          VC, 
          ProductLine_status, 
          ProductLine_validation_date,
          ProductLine_validater_name
        },
        id
      ) => {
        let obj = {
          id,
          Project_code, 
          VC,
          ProductLine_status, 
          ProductLine_validation_date,
          ProductLine_validater_name
        }
        return obj
      }
    )
    // console.log('excelData email: ', excelData);

    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    // FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
    FileSaver.saveAs(data, `PL_Report${fileExt}`);
  }











  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

  //   const showTable = () => {
  //     const {userRole} = auth
  //     if(userRole[0] === 'admin' || userRole[0] === 'TMLBSL' || userRole[0] === 'GDC' || userRole[0] === 'Purchase'){
  //       if(projectCode === null || projectCode.value === ''){
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.INFORMATION,
  //           info: "Project Code is Mandatory",
  //       })}else{
  //         getTableData();
  //         show(true);
  //       }

  //     }else if(userRole[0] === 'Vendor'){
  //       if(projectCode === null || projectCode.value === ''){
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Project Code is Mandatory",
  //       })}else if(Bc === null || Bc.value === ''){
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Supplier Code is Mandatory",
  //       })

  //       }
  //       else if(plant === null || plant.value === ''){
  //         showPopup({
  //           type: MESSAGE_TYPE.INFORMATION,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Plant Code is Mandatory",
  //       })  
  //     }else{
  //       getTableData();
  //       show(true);
  //     }

  //     }
  //     else{
  //       getTableData();
  //       show(true);

  //     }


  //   };

  //   // let Vendor_Code ='';
  //   const handleSelect = (selectedOption, name) => {
  //     // alert(selectedOption.value);
  //     // console.log(selectedOption);
  //     setvendCode(selectedOption.value);
  //     if (name == "VC") {
  //       show(false);

  //       setBC(selectedOption);
  //       // getPlantData(selectedOption)
  //     } else {
  //       setPlant(selectedOption);
  //     }
  //   };

  //   const getTableData = () => {


  //     const apiFormatedData = {
  //       Supplier_Code: Bc?.value,
  //       Project_code : projectCode?.value,
  //       Financial_year : Fy?.value,
  //       Quarter: quarter?.value,
  //       Plant_code : plant?.value
  //     };
  //     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //     let headerss = new Headers();
  //     headerss.append("Content-Type", "application/json");
  //     fetch(`${baseUrl}SupplierDvaCollection/`, {
  //       method: "POST",
  //       headers: headerss,
  //       body: apiFormatedDataJson,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log('table data response',responseJson)
  //         const tableData = responseJson.data;

  //         setTableArray(tableData);
  //         // const excelData = tableData.map(
  //         //   (
  //         //     {
  //         //       Vendor_Code,
  //         //       Plant_code,
  //         //       Part_number,
  //         //       Part_Description,
  //         //       Selling_Price,
  //         //       Value_of_Direct_Import_by_us,
  //         //       Broad_Description_of_parts_imported_by_us,
  //         //       Value_of_parts_imported_by_our_suppliers,
  //         //       Broad_Description_of_parts_imported_by_our_suppliers,
  //         //       Local_Content,
  //         //       DVA,

  //         //     },
  //         //     id
  //         //   ) => {
  //         //     let obj = {
  //         //       id,
  //         //       Vendor_Code,
  //         //       Plant_code,
  //         //       Part_number,
  //         //       Part_Description,
  //         //       Selling_Price,
  //         //       Value_of_Direct_Import_by_us,
  //         //       Broad_Description_of_parts_imported_by_us,
  //         //       Value_of_parts_imported_by_our_suppliers,
  //         //       Broad_Description_of_parts_imported_by_our_suppliers,
  //         //       Local_Content,
  //         //       DVA,
  //         //     };
  //         //     return obj;
  //         //   }
  //         // );

  //         // setTableArray(excelData)
  //         // setTableArray(responseJson)
  //         setLoading(false)
  //       })
  //       .catch((error) => {
  //         console.log(error)

  //       });
  //   };

  //   const getTableData1 = (value) => {
  //     const apiFormatedData = {
  //       VC: value,
  //     };
  //     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //     let headerss = new Headers();
  //     headerss.append("Content-Type", "application/json");
  //     fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code/`, {
  //       method: "POST",
  //       headers: headerss,
  //       body: apiFormatedDataJson,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         if (responseJson.data === null || responseJson.status === 500){
  //           showPopup({
  //               type: MESSAGE_TYPE.FAILURE,
  //               contextText: MESSAGE_TYPE.FAILURE,
  //               info: "Data not found",

  //           })
  //         }else{
  //           const tableData = responseJson.data;
  //           setTableArray(tableData);
  //         }

  //         // const excelData = tableData.map(
  //         //   (
  //         //     {
  //         //       Vendor_Code,
  //         //       Plant_code,
  //         //       Part_number,
  //         //       Part_Description,
  //         //       Selling_Price,
  //         //       Value_of_Direct_Import_by_us,
  //         //       Broad_Description_of_parts_imported_by_us,
  //         //       Value_of_parts_imported_by_our_suppliers,
  //         //       Broad_Description_of_parts_imported_by_our_suppliers,
  //         //       Local_Content,
  //         //       DVA,

  //         //     },
  //         //     id
  //         //   ) => {
  //         //     let obj = {
  //         //       id,
  //         //       Vendor_Code,
  //         //       Plant_code,
  //         //       Part_number,
  //         //       Part_Description,
  //         //       Selling_Price,
  //         //       Value_of_Direct_Import_by_us,
  //         //       Broad_Description_of_parts_imported_by_us,
  //         //       Value_of_parts_imported_by_our_suppliers,
  //         //       Broad_Description_of_parts_imported_by_our_suppliers,
  //         //       Local_Content,
  //         //       DVA,
  //         //     };
  //         //     return obj;
  //         //   }
  //         // );

  //         // exportExcel(excelData);
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //         setDisable(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: `${error.message}`,
  //         });
  //       });
  //   };

  //   const getAllData = () => {
  //     let headerss = new Headers();
  //     headerss.append("Content-Type", "application/json");
  //     fetch(`${baseUrl}AllSupplierDvaCollectionData/`, {
  //       method: "GET",
  //       headers: headerss,

  //     }).then((response) => response.json() )
  //     .then((responseJson)=>{
  //       if(responseJson === null || responseJson.length === 0){
  //         // setTableArray(responseJson)
  //         showPopup({
  //               type: MESSAGE_TYPE.FAILURE,
  //               contextText: MESSAGE_TYPE.FAILURE,
  //               info: "No data available",

  //         })

  //       }
  //       else{
  //         setTableArray(responseJson)
  //         show(true)
  //         setLoading(false)
  //       }

  //     }).catch((error)=>{
  //       console.log('error',error)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Error while fetching data.',
  //       })


  //     })

  //   }
  //   console.log(auth.userRole)

 



  const baseDefaultColumns = [
    {
      width: 150,
      title: 'VC',
      field: 'VC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Product Line Status',
      field: 'ProductLine_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 240,
      title: 'Product Line Validation Date',
      field: 'ProductLine_validation_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 240,
      title: 'Product Line Validator Name',
      field: 'ProductLine_validater_name',
      enableSearch: true,
      enableFilter: true
    }
  ]

  // const goNextForm = () => {
  //   var url;
  //   var urlMethod;
  //   let { userRole, userID } = auth;
  //   if (userRole.includes("Purchase") || userRole.includes("Purchase-CV")) {
  //     url = "SupplierDataaddition";
  //     urlMethod = "POST";
  //   }
  //   const apiFormatedData = {
  //     VC: Bc.value,
  //   }
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  //   fetch(`${baseUrl}${url}/`,
  //     {
  //       method: urlMethod,
  //       headers: headerss,
  //       body: apiFormatedDataJson
  //     }).then((response) => response.json())
  //     .then((responseJson) => {
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "Data is Succesfully Saved"
  //       });
  //       history.push('/')
  //     })
  //     .catch((error) => {
  //     });
  // };

  // const rowData = [
  //   {
  //     "Vendor_code": "P83251",
  //     "Buyer_name": "test123",
  //     "AFS_mandatory": "Yes",
  //     "MPFD_mandatory": "No"
  //   },
  //   {
  //     "Vendor_code": "P83250",
  //     "Buyer_name": "test1234",
  //     "AFS_mandatory": "Yes",
  //     "MPFD_mandatory": "No"
  //   },
  //   {
  //     "Vendor_code": "R83255",
  //     "Buyer_name": "testing",
  //     "AFS_mandatory": "Yes",
  //     "MPFD_mandatory": "No"
  //   },
  //   {
  //     "Vendor_code": "R83279",
  //     "Buyer_name": "testing12",
  //     "AFS_mandatory": "Yes",
  //     "MPFD_mandatory": "Yes"
  //   }

  // ]



  //   const redirectToCreateProjectPage = () => history.push("/");
    const renderHTML = () => (
      <div className={styles.formGroup}>
      <Grid
          container
          spacing={1}
          style={{ display: 'flex', justifyContent: "left"}}
          columns={12}
        >
        <Grid
          item
          // sm={12}
          md={4}
          lg={4}
          xs={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {/* <div className={styles.rowDiv}> */}
            <div className={clsx(styles.lableDiv)} style={{ width: "36%", display: 'flex', alignItems: 'center' }}>
              <label className={styles.label} style={{ }}>Project Code</label>
            </div>
            <div className={styles.formDiv} style={{ width: '54%' }}>
              <CustomSelect
                name="Project_Code"
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={(selectedOption) => {
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  // if (selectedOption !== null) {
                    // getVcForProjectCode(selectedOption)
                  // }
                  // getVcForProjectCode(selectedOption); // Fetch VC for selected project code

                  // const selectedValue = selectedOption.target.value;
                  // const selectedLabel = options.find(option => option.value === selectedValue)?.label || ''
                  // console.log('selectedLabel: ', selectedLabel)
                  // setSelectedOption({ value: selectedValue, label: selectedLabel })
                }}
                style={{ width: '100% !important' }}
              />
            </div>
          {/* </div> */}
        </Grid>
          <Grid item md={5} lg={4} sm={12}>
             <div                
               style={{marginLeft:"5px", paddingRight:"10px",marginTop: "1px",display:"flex"}}
              >
                    <AuthChecker operation="search">
               {isAuthorized => (
                 <Button
                   className={clsx(
                     styles.actionButton,
                     styles.primaryActionButton,
                   )}
                   style={{marginRight:"12px !important"}}
                   variant="primary"
                   //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                   onClick={showTable}
                    disabled={!isAuthorized}
                   data-testid="confirm-action"
                 >
                   Show Data
                 </Button>
               )}
             </AuthChecker>
             </div>
          </Grid>
        </Grid>
        </div>
    )
  //            {!auth.userRole.includes('Vendor') ? (
  //   <AuthChecker operation="search">
  //     {isAuthorized => (
  //       <Button
  //         className={clsx(
  //           styles.actionButton,
  //           styles.primaryActionButton,
  //         )}
  //         style={{marginRight:"12px !important",marginLeft:"5px"}}
  //         variant="primary"
  //         onClick={() => {
  //           getAllData();
  //         }}
  //         disabled={!isAuthorized}
  //         data-testid="confirm-action"
  //       >
  //         Show All
  //       </Button>
  //     )}
  //   </AuthChecker>
  // ) : null}

  //       {/* {
  //         loader==false&&

  //           <>
  //         <AuthChecker operation="download">

  //           {isAuthorized => (
  //                <Button
  //                  className={clsx(
  //                    styles.actionButton,
  //                    styles.primaryActionButton,
  //                  )}
  //                  variant="primary"
  //                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                  onClick={()=>{

  //                   if(tableArray.length>=1){exportExcel(tableArray);}
  //                  }}
  //                   disabled={!isAuthorized}
  //                  data-testid="confirm-action"
  //                  style={{marginLeft:"5px"}}

  //                >
  //                  download
  //                </Button>
  //              )}

  //            </AuthChecker>

  //        </> 
  //     } */}


  //               </div>


  //         </Grid>

  //         </Grid>

  //     </div>
  //   );



  // console.log('tableArray',tableArray);
  const uploadDocument = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".xlsx");
    input.click();


    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDVAdataUpload' : 'SupplierDvaCollectionAdminGDC'
      // fetch(`${baseUrl}${url}/`, {
      // fetch(`${baseUrl}BuyerMasterAfsMpfdData/`, {
      // fetch(`${baseUrl}UniqueVendorCodeAPI2/`, {
      fetch(`${baseUrl}VCSOPCMappingAPIView/`, {
        // method: "PUT",
        method: "POST",
        body: formData,
      })
        .then((response) => {
          console.log(response);
          // return response.json(); // Parse JSON response
          if (response.ok) {
            getVCSPOCData()
            return response.json()
          }
          if (response.status === 400) {
            return response.json().then(data => {
              console.log(data)
              // const error = data?.error.match(/="(.*?)"/)[1];
              throw new Error(data?.message || 'Local Content does not match the calculated value');
            });

          } if (response.status === 500) {
            return response.json().then(data => {
              console.log(data)
              throw new Error(data?.message);
            });

          }
          return response.json().then(data => {
            console.log(data)
            throw new Error(data?.message);
          });

        })
        .then((myResponse) => {
          console.log('MyResponse', myResponse);
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: "Document Uploaded Successfully"
          });

          getData()
        })
        .catch((error) => {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error : ${error.message}`,
          });
        });
    };

  }

  const SendWelcomeEmail = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}SendEmailToAllVendors/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response =>
        response.json().then(jsonRes => ({
          status: response.status,
          body: jsonRes
        }))
      )
      .then(responseJson => {
        // console.log('responseJson sendEmail: ', responseJson);
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Welcome email sent successfully!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Welcome email trigger failed!'
            // info: responseJson?.error
          })
        }
      })
      .catch(error => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error
        })
      })
  }

  const SendSummaryEmail = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(
      `${baseUrl}SendSummaryEmailFromTMLBSLtoPurchase/?financial_year=${financialYear?.value}`,
      {
        method: 'GET',
        headers: headerss
      }
    )
      .then(response =>
        response.json().then(jsonRes => ({
          status: response.status,
          body: jsonRes
        }))
      )
      .then(responseJson => {
        console.log('responseJson sendEmail: ', responseJson)
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Summary email sent successfully!'
          })
        } else if (responseJson?.status < 404) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Summary email trigger failed!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Summary email trigger failed!'
          })
        }
      })
      .catch(error => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error
        })
      })
  }

  const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     // handleOpen();
    //     uploadDocument()
    //   },
    //   customClass: "",
    // },
    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // downloadFile();
        exportExcel(tableData)
      },
      customClass: "",
    },
    // {
    //   name: "Send Welcome Email",
    //   authOperation: "sendWelcomeEmail",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     handleOpenConfirmEmailPopup()
    //   },
    // },
    // {
    //   name: "Send Summary Email",
    //   authOperation: "sendSummaryEmail",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     handleOpenConfirmSummaryEmailPopup()
    //   },
    // },
    // {
    //   name: "Download All AFS",
    //   authOperation: "download",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     downloadAllAFSDocumentsZip()
    //   },
    // },
  ]

  // const primaryActions = [
  //   {
  //     name: "Submit Data",
  //     authOperation: "submit",
  //     shouldEnable: () => true,
  //     actionFn: handleOpenFormModal,
  //   }

  // ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="BOM Validation Report" withOutCount isSelected />
                  </div>
                  {/* <StepBar1 enable={5} /> */}

                </div>
              </div>
            </div>


          </div>


        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup  body={renderHTML()} />
          </div>
        </div>
        {flag && (
        <div
          className={styles.tableView}
          style={{ height: "86%", padding: "0 2em" }}
        >
          <Table
            // columns={[...baseDefaultColumns]}
            // defaultColumns={[...baseDefaultColumns]}
            columns={baseDefaultColumns}
            defaultColumns={baseDefaultColumns}
            rowHeight={38}
            rows={tableData}
            primaryAction={[]}
            secondaryActions={secondaryActions}
            setAsnCount={setCount}
            //   isDataLoading={loader}
            actionButtons
            onRowSelect={(e) => { }}
            removeRowSelection
          />
        </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}

      <WelcomeEmailConfirmationPopup
        confirmWelcomeEmailPopup={confirmWelcomeEmailPopup}
        handleCloseConfirmEmailPopup={handleCloseConfirmEmailPopup}
        SendWelcomeEmail={SendWelcomeEmail}
      />
      <SummaryEmailConfirmationPopup
        confirmSummaryEmailPopup={confirmSummaryEmailPopup}
        handleCloseConfirmSummaryEmailPopup={handleCloseConfirmSummaryEmailPopup}
        SendSummaryEmail={SendSummaryEmail}
      />

    </>
  );
};

function WelcomeEmailConfirmationPopup({
  confirmWelcomeEmailPopup,
  handleCloseConfirmEmailPopup,
  SendWelcomeEmail
}) {
  return (
    <Dialog
      open={confirmWelcomeEmailPopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Email Trigger
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Welcome email will be sent to all onboarded
              vendors.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseConfirmEmailPopup}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              SendWelcomeEmail()
              handleCloseConfirmEmailPopup()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function SummaryEmailConfirmationPopup({
  confirmSummaryEmailPopup,
  handleCloseConfirmSummaryEmailPopup,
  SendSummaryEmail
}) {
  return (
    <Dialog
      open={confirmSummaryEmailPopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Summary Email Trigger
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Summary email will be sent from TMLBSL to PMT.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseConfirmSummaryEmailPopup}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              SendSummaryEmail()
              handleCloseConfirmSummaryEmailPopup()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

WelcomeEmailConfirmationPopup.propTypes = {
  confirmWelcomeEmailPopup: PropTypes.bool.isRequired,
  handleCloseConfirmEmailPopup: PropTypes.func.isRequired,
  SendWelcomeEmail: PropTypes.func.isRequired
}
SummaryEmailConfirmationPopup.propTypes = {
  confirmSummaryEmailPopup: PropTypes.bool.isRequired,
  handleCloseConfirmSummaryEmailPopup: PropTypes.func.isRequired,
  SendSummaryEmail: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(AFSMaster, RESOURCE_TYPE.PROJECT)

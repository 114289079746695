/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
// import Table1 from "../../components/Table/Table";
// import Table from "../../../components/Table1/Table1";
import Table from '../../../components/TableForAdmin/TableForAdmin'

import { AuthChecker, Button, CustomTab } from '../../../atomicComponents'
import BackButton from '../../../components/BackButton/BackButton'
import {
  CustomFormGroup,
  CustomSelect
} from '../../../components/FormComponents'
import {
  API_RESOURCE_URLS,
  DATE_FORMAT,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_ANNEXURE,
  MESSAGE_TYPE_CERTIFICATION,
  RESOURCE_TYPE,
  Regex,
  USER_OPERATIONS
} from '../../../constants'
// import FileSaver from 'file-saver'
import { withAllowedOperationsProvider } from '../../../hocs'
import styles from './VCSPOCMapping.module.css'
// import { API } from "../../../apis/api";
import { buildErrorMessage } from '../../../apis/calls'
import { usePopupManager } from '../../../providers/PopupManager/PopupManager'
import { GridLoadingSpinner } from '../../../components'

// import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const AFSMaster = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl)
  const auth = useSelector(state => state.auth)
  const [asnCount, setAsnCount] = useState(0)

  const [tableData, setTableData] = useState([])
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  const [confirmWelcomeEmailPopup, setConfirmWelcomeEmailPopup] =
    useState(false)
  const handleOpenConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(true)
  const handleCloseConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(false)

  const [confirmSummaryEmailPopup, setConfirmSummaryEmailPopup] =
    useState(false)
  const handleOpenConfirmSummaryEmailPopup = () =>
    setConfirmSummaryEmailPopup(true)
  const handleCloseConfirmSummaryEmailPopup = () =>
    setConfirmSummaryEmailPopup(false)

  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })

  // const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const setCount = count => {
    setAsnCount(count)
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }
  const getData = async () => {
    try {
      const response = await axios.get(`${baseUrl}SyncUniqueVCAPIView/`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log('axios response: ', response?.data)

      if (response.status === 200) {
        const Jsonresponse = response.data

        // const updatedResponse = Jsonresponse.map((item) => ({
        //   ...item,
        //   Afs_Document_mandatory: item.Afs_Document_mandatory === false ? 'False' : 'True',
        //   Test1: (item?.Test1 == 'True' || item?.Test1 == 'TRUE') ? 'True' : 'False',
        // }));

        // setTableData(updatedResponse);
      } else {
        throw new Error(response.data.status)
      }
    } catch (error) {
      console.error('Error:', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error : ${error.message}`
      })
    }
  }

  useEffect(() => {
    getVCSPOCData()
    getData()
  }, [])

  const getVCSPOCData = async () => {
    try {
      const response = await axios.get(`${baseUrl}VCSOPCMappingAPIView/`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      console.log('axios response: ', response?.data)

      if (response.status === 200) {
        const Jsonresponse = response?.data

        // const updatedResponse = Jsonresponse.map((item) => ({

        // }));

        setTableData(Jsonresponse)
        // setTableData(updatedResponse);
      } else {
        throw new Error(response.data.status)
      }
    } catch (error) {
      console.error('Error:', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error : ${error.message}`
      })
    }
  }

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            // getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const exportExcel = async tableArray => {
    const excelData = tableArray?.map(({ VC, SPOC }, id) => {
      let obj = {
        id,
        VC,
        SPOC
      }
      return obj
    })

    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    // FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
    FileSaver.saveAs(data, `VC_SPOC_Mapping${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 170,
      title: 'VC',
      field: 'VC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 300,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDVAdataUpload' : 'SupplierDvaCollectionAdminGDC'
      // fetch(`${baseUrl}${url}/`, {
      // fetch(`${baseUrl}BuyerMasterAfsMpfdData/`, {
      // fetch(`${baseUrl}UniqueVendorCodeAPI2/`, {
      setLoader(true)
      fetch(`${baseUrl}VCSOPCMappingAPIView/`, {
        // method: "PUT",
        method: 'POST',
        body: formData
      })
        .then(response => {
          console.log(response)
          // return response.json(); // Parse JSON response
          if (response.ok) {
            getVCSPOCData()
            return response.json()
          }
          if (response.status === 400) {
            return response.json().then(data => {
              console.log(data)
              // const error = data?.error.match(/="(.*?)"/)[1];
              throw new Error(
                data?.message ||
                  'Local Content does not match the calculated value'
              )
            })
          }
          if (response.status === 500) {
            return response.json().then(data => {
              console.log(data)
              throw new Error(data?.message)
            })
          }
          return response.json().then(data => {
            console.log(data)
            throw new Error(data?.message)
          })
        })
        .then(myResponse => {
          console.log('MyResponse', myResponse)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          })

          getData()
        })
        .catch(error => {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error : ${error.message}`
          })
        })
      setLoader(false)
    }
  }

  const SendWelcomeEmail = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}SendEmailToAllVendors/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response =>
        response.json().then(jsonRes => ({
          status: response.status,
          body: jsonRes
        }))
      )
      .then(responseJson => {
        // console.log('responseJson sendEmail: ', responseJson);
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Welcome email sent successfully!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Welcome email trigger failed!'
            // info: responseJson?.error
          })
        }
      })
      .catch(error => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error
        })
      })
  }

  const SendSummaryEmail = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(
      `${baseUrl}SendSummaryEmailFromTMLBSLtoPurchase/?financial_year=${financialYear?.value}`,
      {
        method: 'GET',
        headers: headerss
      }
    )
      .then(response =>
        response.json().then(jsonRes => ({
          status: response.status,
          body: jsonRes
        }))
      )
      .then(responseJson => {
        console.log('responseJson sendEmail: ', responseJson)
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Summary email sent successfully!'
          })
        } else if (responseJson?.status < 404) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Summary email trigger failed!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Summary email trigger failed!'
          })
        }
      })
      .catch(error => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error
        })
      })
  }

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: selected => {
        // handleOpen();
        uploadDocument()
      },
      customClass: ''
    },
    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: selected => {
        // downloadFile();
        exportExcel(tableData)
      },
      customClass: ''
    }
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            {loading && (
              <div className={styles.gridLoader}>
                <GridLoadingSpinner />
              </div>
            )}
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='VC SPOC Mapping'
                      withOutCount
                      isSelected
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.tableView}
          style={{ height: '86%', padding: '0 2em' }}
        >
          <Table
            // columns={[...baseDefaultColumns]}
            // defaultColumns={[...baseDefaultColumns]}
            columns={baseDefaultColumns}
            defaultColumns={baseDefaultColumns}
            rowHeight={38}
            rows={tableData}
            primaryAction={[]}
            secondaryActions={secondaryActions}
            setAsnCount={setCount}
            //   isDataLoading={loader}
            actionButtons
            onRowSelect={e => {}}
            removeRowSelection
          />
        </div>
      </div>

      <WelcomeEmailConfirmationPopup
        confirmWelcomeEmailPopup={confirmWelcomeEmailPopup}
        handleCloseConfirmEmailPopup={handleCloseConfirmEmailPopup}
        SendWelcomeEmail={SendWelcomeEmail}
      />
      <SummaryEmailConfirmationPopup
        confirmSummaryEmailPopup={confirmSummaryEmailPopup}
        handleCloseConfirmSummaryEmailPopup={
          handleCloseConfirmSummaryEmailPopup
        }
        SendSummaryEmail={SendSummaryEmail}
      />
    </>
  )
}

function WelcomeEmailConfirmationPopup ({
  confirmWelcomeEmailPopup,
  handleCloseConfirmEmailPopup,
  SendWelcomeEmail
}) {
  return (
    <Dialog
      open={confirmWelcomeEmailPopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Email Trigger
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Welcome email will be sent to all onboarded
              vendors.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseConfirmEmailPopup}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              SendWelcomeEmail()
              handleCloseConfirmEmailPopup()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function SummaryEmailConfirmationPopup ({
  confirmSummaryEmailPopup,
  handleCloseConfirmSummaryEmailPopup,
  SendSummaryEmail
}) {
  return (
    <Dialog
      open={confirmSummaryEmailPopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Summary Email Trigger
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, Summary email will be sent from TMLBSL to PMT.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseConfirmSummaryEmailPopup}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              SendSummaryEmail()
              handleCloseConfirmSummaryEmailPopup()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

WelcomeEmailConfirmationPopup.propTypes = {
  confirmWelcomeEmailPopup: PropTypes.bool.isRequired,
  handleCloseConfirmEmailPopup: PropTypes.func.isRequired,
  SendWelcomeEmail: PropTypes.func.isRequired
}
SummaryEmailConfirmationPopup.propTypes = {
  confirmSummaryEmailPopup: PropTypes.bool.isRequired,
  handleCloseConfirmSummaryEmailPopup: PropTypes.func.isRequired,
  SendSummaryEmail: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(AFSMaster, RESOURCE_TYPE.PROJECT)

/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'

import clsx from 'clsx';

import { useHistory } from 'react-router-dom';
import styles from './LandingPage.module.css'
import { ListView, SubHeader, ToolsListView } from '../../components';
import { USER_OPERATIONS, PPAP_APP_NAME, PERSONAS, TOOLS } from '../../constants';
import { useAuthorizationContext } from '../../providers/AuthorizationHandler/AuthorizationHandler';
import useAuthorizedEpApps from '../../hooks/useAuthorizedEpApps';
import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import config from '../../config/config';




// function LandingPage() {
const LandingPage = () => {

  const { isAuthenticated, user, authResources } = useAuthorizationContext();
  const history = useHistory();

  const [selectedColumnsQueue, setSelectedColumsQueue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [columnsRoot, setColumnsRoot] = useState();
  const [classFlag, setClass] = useState('center');
  const authorizedApps = useAuthorizedEpApps(isAuthenticated, authResources);
  const { showLoading } = usePopupManager();
  const auth = useSelector(state => state.auth);
  const role = auth.userRole;
  
  const changeClass = () => {
    setClass('right')
  }
  const handleSelection = selection => {
    console.log('selection in landing Page', selection);
    setSelectedColumsQueue(prev => {
      changeClass();
      if (prev.length) {
        const lastSelection = prev[prev.length - 1];
        if (lastSelection === selection) return prev;
        while (prev.length) {
          const existingTopLevel = prev[prev.length - 1].level;
          if (existingTopLevel >= selection.level) prev.pop();
          else break;
        }
      }
      prev.push(selection);
      return [...prev];
    });
  }


  const getArrayOfOptionSetsToRender = queue =>
    queue.map(item => item.next).filter(item => item);

  const getToolOptions = () =>
    [

      {
        label: 'Barcode Pattern',
        action: () => {
          history.push('/document-master');
        },
        authOperation: USER_OPERATIONS.CREATE_PPAP_STAGE_DOCUMENT,
        testId: 'document-master-btn'
      },



    ];

  const toolOptions = getToolOptions();
  const urlBaseAndPersonaMap = new Map();
  urlBaseAndPersonaMap.set(PERSONAS.BUYER, 'buyer');
  urlBaseAndPersonaMap.set(PERSONAS.VENDOR, 'vendor');
  urlBaseAndPersonaMap.set(PERSONAS.FBV_VENDOR, 'vendor');
  urlBaseAndPersonaMap.set(PERSONAS.FBV, 'fbv');
  const deriveNextLaneForMaterialGroups = (persona, materialGroup) => {
    const urlBaseForPersona = urlBaseAndPersonaMap.get(persona);
    if (persona === PERSONAS.BUYER)
      return [
        {
          label: 'View Plan',
          next: null,
          level: 3,
          action: () => {
            history.push(`/${urlBaseForPersona}/${materialGroup}`);
          },
        },
        {
          label: 'ASN DETAILS',
          next: null,
          level: 3,
          action: () => {
            history.push(`/${urlBaseForPersona}/${materialGroup}/asn-details`);
          },
        },
      ];
    return [
      {
        label: 'Plan',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}`);
        },
      },
      {
        label: 'Drop',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}/drop`);
        },
      },
      {
        label: 'Dispatch',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}/dispatch`);
        },
      },
    ];
  };

  const deriveMaterialGroupLaneFromMaterialGroups = (
    userPersona,
    materialGroups,
  ) =>
    materialGroups.map(record => ({
      value: record.materialGroup,
      label: record.description,
      level: 2,
      next: deriveNextLaneForMaterialGroups(userPersona, record.materialGroup), // step 4
      action: null,
    }));

  //  Admin Role
  const adminMenu = [

    /* Admin control tabs */
    {
      label: "Admin Control",
      next: [
        {
          label: 'AFS Master',
          next: null,
          level: 2,
          action: () => {
            history.push('/afs-master');
          },
        },
        {
          label: 'VC SPOC Mapping',
          next: null,
          level: 2,
          action: () => {
            history.push('/vc-spoc-mapping');
          },
        },
        // {
        //   label: 'Buyer Master',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/buyer-master');
        //   },
        // },
        // {
        //   label: 'MPFD Master',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/mpfd-master');
        //   },
        // },
      ],
      level: 1,
      action: null
    },
    {
      label: "Annual Financial Details Form",
      next: null,
      level: 1,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },
    /* Old Report section in admin */
    // {
    //   label: "Reports",
    //   next: [
    //     {
    //       label: "AFS Report",
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/AFSMIS')
    //       },
    //     },
    //     {
    //       label: "PL Report",
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/pl-report')
    //       }
    //     },
    //     {
    //       // label: "PL2 Report",
    //       label: "BOM Validation - 2 Report",
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/pl2-report')
    //       }
    //     },
    //     {
    //       // label: "PMT BOM Report",
    //       label: "Unique Part Selection Report",
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/pmt-bom-report')
    //       }
    //     },
    //     // {
    //     //   label: 'BOM - Report',
    //     //   next: null,
    //     //   level: 1,
    //     //   action: () => {
    //     //     history.push('/bom-boe-report');
    //     //   },
    //     // },
    //   ],
    //   level: 1,
    //   action: null
    // },


    /* New report section in admin */
        /* Reports */
        {
          label: 'Reports',
          next: [
            /* AFS Report */
            {
              label: 'AFS Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/AFSMIS')
              }
            },
    
            /* BOM Validation Report */
            {
              label: 'BOM Validation Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/pl-report')
              }
            },
    
            /* BOM Validation - 2 Report */
            {
              // label: "PL2 Report",
              label: 'BOM Validation - 2 Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/pl2-report')
              }
            },
    
            /* Unique Part Selection Report */
            {
              // label: "PMT BOM Report",
              label: 'Unique Part Selection Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/pmt-bom-report')
              }
            },
            // {
            //   label: 'BOM - Report',
            //   next: null,
            //   level: 2,
            //   action: () => {
            //     history.push('/bom-boe-report');
            //   },
            // },
    
            /* DVA Report */
            {
              // label: "DVA Declaration MIS",
              label: 'DVA Declaration Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/supplier-dva-declaration-mis')
              }
            },
            /* Under PLI Report */
            {
              // label: "Supplier Under PLI Declaration MIS",
              label: 'Supplier Under PLI Declaration Report',
              next: null,
              level: 2,
              action: () => {
                history.push('/supplier-pli-declaration-mis')
              }
            }
          ],
          level: 1,
          action: null
        },

    // MHI Data Section
    /* Role: MHI */
    // {
    //   label: "MHI Data",
    //   next: [
    //     {
    //       label: 'MHI Data Formation',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         // history.push('/mhi-data-cv');
    //         history.push('/mhi-data-formation');
    //       },
    //     },
    //     {
    //       label: 'MHI Data Submission',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         // history.push('/mhi-data-pv');
    //         history.push('/mhi-data-submission');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Audit Report",
    //   next: [
    //     {
    //       label: 'Report-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/audit-report-cv');
    //       },
    //     },
    //     {
    //       label: 'Report-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/audit-report-pv');
    //       },
    //     },
    //     {
    //       label: 'Report-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }
    //   ],
    //   level: 1,
    //   action: null
    // },
    {
      label: "Supplier DVA",
      next: null,
      level: 1,
      action: () => {
        history.push('/Supplier-DVA-Collection-CV-Purchase');
      },
    },
    // {
    //   label: "VC Wise DVA Calculation",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/vcwise-dva-calculation');
    //   },
    // },
    // {
    //   label: 'Supplier DVA Report',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/supplier-dva-report');
    //   },
    // },
    {
      // label: "Supplier Declaration (Applicable for PLI approved supplier)",
      // label: "Supplier PLI Declaration",
      label: 'Supplier Declaration (Applicable for PLI approved supplier)',
      next: null,
      level: 1,
      action: () => {
        // history.push('/Supplier-DVA-Collection-CV-Purchase-Page3');
        history.push('/supplier-dva-declaration-for-pli');
      },
    },
    // {
    //   label: "Master Sheet Data",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/bom-master-data');
    //   },
    // },
    {
      label: 'AFS Master Data',
      next: null,
      level: 1,
      action: () => {
        history.push('/afs_master_data-pv');
      },
    },
    {
      label: 'BOM Validation',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-validation');
      },
    },
    {
      label: 'BOM Validation - 2',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-validation_2');
      },
    },
    // Base Sheet Data Section
    // {
    //   label: "Base Sheet Data",
    //   next: [
    //     {
    //       label: 'Base Sheet Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/BaseSheetData');  
    //       },
    //     },
    //     {
    //       label: 'Base Sheet Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/BaseSheetDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "TML Data Sheet",
    //   next: [
    //     {
    //       label: 'TML Data Sheet (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/TMLDataSheet');  
    //       },
    //     },
    //     {
    //       label: 'TML Data Sheet (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/TMLDataSheetPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Part Level Data",
    //   next: [
    //     {
    //       label: 'Part Level Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/PartLevelData');  
    //       },
    //     },
    //     {
    //       label: 'Part Level Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/PartLevelDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Vendor Part Data",
    //   next: [
    //     {
    //       label: 'Vendor Part Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/VendorPartData');  
    //       },
    //     },
    //     {
    //       label: 'Vendor Part Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/VendorPartDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

  ]
  //   Role Vendor CV
  const vendorCV = [{
    label: "Supplier DVA Declaration",
    next: [
      {
        label: 'Supplier DVA Declaration-CV',
        next: null,
        level: 2,
        action: () => {
          history.push('/supplier_import_details-cv');
        },
      },
    ],
    level: 1,
    action: null
  },];
  //   Role Vendor PV
  const vendor = [
    {
      label: "Annual Financial Details Form",
      next: null,
      level: 1,
      action: () => {
        history.push('/annual_financial_statement');
      }
    },
    {
      label: "Supplier DVA and Declaration",
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-collection');
      },
    }, 
    {
      // label: "Supplier Declaration (Applicable for PLI approved supplier)",
      // label: "Supplier PLI Declaration",
      label: 'Supplier Declaration (Applicable for PLI approved supplier)',
      next: null,
      level: 1,
      action: () => {
        // history.push('/Supplier-DVA-Collection-PV-Purchase-Page3');
        history.push('/supplier-dva-declaration-for-pli');
      },
    },
  ];

  const vendorPV = [{
    label: "Supplier DVA Declaration",
    next: [

      {
        label: 'Supplier DVA Declaration-PV',
        next: null,
        level: 2,
        action: () => {
          history.push('/vendor-data-pv');
        },
      },


    ],
    level: 1,
    action: null
  },];


  //   Role Vendor EV
  const vendorEV = [{
    label: "Supplier DVA Declaration",
    next: [

      {
        label: 'Supplier DVA Declaration-EV',
        next: null,
        level: 2,
        action: () => {
          history.push('/');
        },
      }

    ],
    level: 1,
    action: null
  },];




  const roleGDC = [
    {
      label: "Milestone Calendar",
      next: [
        {
          label: 'Calendar-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-cv');
          },
        },
        {
          label: 'Calendar-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-pv');
          },
        },
        {
          label: 'Calendar-EV',
          next: null,
          level: 1,
          action: () => {
            history.push('/');
          },
        }


      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [
        {
          label: 'Mass VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-cv');
          },
        },
        {
          label: 'Mass VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-pv');
          },
        },
        {
          label: 'Mass VC Declaration-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [
        {
          label: 'VC BoM Data-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data');
          },
        },
        {
          label: 'VC BoM Data-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data-pv');
          },
        },
        {
          label: 'VC BoM Data-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },
    // {
    //   label: "Vendor DVA Declaration",
    //   next: [
    //     {
    //       label: 'Vendor DVA Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-data');
    //       },
    //     },
    //     {
    //       label: 'Vendor DVA Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-data-pv');
    //       },
    //     },
    //     {
    //       label: 'Vendor DVA Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }

    //   ],
    //   level: 1,
    //   action: null
    // }
    {
      label: "Supplier Certification",
      next: [
        {
          label: 'Supplier Certification-CV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate');
          },
        },
        {
          label: 'Supplier Certification-PV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-pv');
          },
        },
        {
          label: 'Supplier Certification-EV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-ev');
          },
        },
      ],
      level: 1,
      action: null
    },


  ];

  // purchase

  const rolePurchase = [
    // {
    //   label: "Annual Financial Details Form",
    //   next: null,
    //   level: 2,
    //   action: () => {
    //     history.push('/annual_financial_statement')
    //   }
    // },
    // {
    //   label: "AFS Report",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/AFSMIS');
    //   },
    // },
    // {
    //   label: 'AFS Master Data',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/afs_master_data-pv');
    //   },
    // },
    // {
    //   label: 'BOM Validation',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/bom-validation');
    //   },
    // },
    // {
    //   label: 'Unique Part Selection',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/unique-part-selection');
    //   },
    // },
    {
      label: "Supplier DVA and Declaration",
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-collection');
      },
    },

    // {
    //   label: "Supplier Under PLI Declaration MIS",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/supplier-pli-declaration-mis')
    //   },
    // },
    {
      label: "DVA Declaration Report",
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-declaration-mis')
      },
    },

    /* Cancel in between development */
    // {
    //   label: "Supplier Declaration (Applicable for PLI approved supplier)",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/supplier-dva-declaration-for-pli_purchase');
    //   },
    // },
    // {
    //   label: "DVA Master Sheet Data",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/bom-master-data');
    //   },
    // },
    // {
    //   label: "VC Wise DVA Calculation",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/vcwise-dva-calculation');
    //   },
    // },
    // {
    //   label: "Annexures",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/annexures');
    //   },
    // },

    // {
    //   label: "Milestone Calendar",
    //   next: [
    //     {
    //       label: 'Calendar-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-cv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-pv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }


    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Mass VC Declaration",
    //   next: [
    //     {
    //       label: 'Mass VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-cv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-pv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "VC BoM Data",
    //   next: [
    //     {
    //       label: 'VC BoM Data-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data-pv');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Supplier Certification",
    //   next: [
    //     {
    //       label: 'Supplier Certification-CV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-PV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-ev');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },

    /* Previous menus, Date: 02-08-2024 */
    // {
    //   label: 'AFS Report',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/AFSMIS')
    //   }
    // },
    // {
    //   label: 'AFS Master Data',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/afs_master_data-pv');
    //   },
    // },
    // {
    //   label: 'Supplier DVA',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/Supplier-DVA-Collection-CV-Purchase');
    //   },
    // },
    // {
    //   label: "Supplier Declaration (Applicable for PLI approved supplier)",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/Supplier-DVA-Collection-PV-Purchase-Page3');
    //     // history.push('/supplier-dva-declaration-for-pli');
    //   },
    // },
    
    // {
    //   label: "Annual Financial Statement - Purchase",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/AFSpurchase')
    //   }
    // },
    // {
    //   label: "Supplier Declaration (Applicable for PLI approved supplier)",
    //   next: [
    //     {
    //       label: 'Supplier Declaration (Applicable for PLI approved supplier)-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-CV-Purchase-Page3');
    //       },
    //     },
    //     {
    //       label: 'Supplier Declaration (Applicable for PLI approved supplier)-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-PV-Purchase-Page3');
    //       },
    //     },
    //     // {
    //     //   label: 'Supplier DVA Collection-EV (Purchase)',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/');
    //     //   },
    //     // },
    //     // {
    //     //   label: 'Supplier DVA Collection-CV',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/Supplier-DVA-Collection-CV');
    //     //   },
    //     // },
    //     // {
    //     //   label: 'Supplier DVA Collection-PV',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/Supplier-DVA-Collection-pv');
    //     //   },
    //     // },
    //     // {
    //     //   label: 'Supplier DVA Collection-EV',
    //     //   next: null,
    //     //   level: 1,
    //     //   action: () => {
    //     //     history.push('/');
    //     //   },
    //     // }


    //   ],
    //   level: 1,
    //   action: null
    // },
  ];

  const rolePurchaseCV = [
    {
      label: "Milestone Calendar",
      next: [
        {
          label: 'Calendar-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-cv');
          },
        },
      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [
        {
          label: 'Mass VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-cv');
          },
        }

      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [
        {
          label: 'VC BoM Data-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data');
          },
        },
      ],
      level: 1,
      action: null
    },

    {
      label: "Supplier Certification",
      next: [
        {
          label: 'Supplier Certification-CV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate');
          },
        },

      ],
      level: 1,
      action: null
    },

  ];

  const rolePurchasePV = [
    {
      label: "Milestone Calendar",
      next: [

        {
          label: 'Calendar-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-pv');
          },
        },



      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [

        {
          label: 'Mass VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-pv');
          },
        },


      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [

        {
          label: 'VC BoM Data-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data-pv');
          },
        },


      ],
      level: 1,
      action: null
    },

    {
      label: "Supplier Certification",
      next: [

        {
          label: 'Supplier Certification-PV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-pv');
          },
        },

      ],
      level: 1,
      action: null
    },


  ];

  // product line 

  const roleProductLine = [

    {
      label: 'BOM Validation',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-validation');
      },
    },
    // {
    //   label: "Milestone Calendar",
    //   next: [
    //     {
    //       label: 'Calendar-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-cv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-pv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }


    //   ],
    //   level: 1,
    //   action: null
    // },


    // {
    //   label: "VC Declaration",
    //   next: [
    //     {
    //       label: 'VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/add-vcbc-cv');
    //       },
    //     },
    //     {
    //       label: 'VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/add-vcbc-pv');
    //       },
    //     },
    //     {
    //       label: 'VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Mass VC Declaration",
    //   next: [
    //     {
    //       label: 'Mass VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-cv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-pv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //     // {
    //     //   component: 'PLIList',
    //     //   url: '/vcbc-list-cv',
    //     //   label: "Mass VC Declaration"
    //     // },
    //     // {
    //     //   component: 'PLIListPV',
    //     //   url: '/vcbc-list-pv',
    //     //   label: "Mass VC Declaration"
    //     // },
    //     // {
    //     //   component: 'PLIListEV',
    //     //   url: '/vcbc-list-ev',
    //     //   label: "Mass VC Declaration"
    //     // },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "VC BoM Data",
    //   next: [
    //     {
    //       label: 'VC BoM Data-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data-pv');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "DVA Calculation",
    //   next: [
    //     {
    //       label: 'DVA Calculation CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/dva-calculation-cv');
    //       },
    //     },
    //     {
    //       label: 'DVA Calculation PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/dva-calculation-pv');
    //       },
    //     },
    //     {
    //       label: 'DVA Calculation EV',
    //       next: null,
    //       level: 3,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "MHI Data",
    //   next: [
    //     {
    //       label: 'MHI Data CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/mhi-data-cv');
    //       },
    //     },
    //     {
    //       label: 'MHI Data PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/mhi-data-pv');
    //       },
    //     },
    //     {
    //       label: 'MHI Data EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },


  ];

  // Newly added TMLBSL role
  const roleTMLBSL = [

    /* Project Setup */
    {
      label: 'Project Setup',
      next: [
        {
          label: 'Part Buyer Mapping',
          next: null,
          level: 2,
          action: () => {
            history.push('/part-buyer-mapping')
          }
        },
        {
          label: 'VC SPOC Mapping',
          next: null,
          level: 2,
          action: () => {
            history.push('/vc-spoc-mapping')
          }
        }
      ],
      level: 1,
      action: null
    },

    /* AFS */
    {
      label: 'Annual Financial Details Form',
      next: null,
      level: 2,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },

    /* BOM Validation - 2 */
    // {
    //   label: 'BOM Validation - 2',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/bom-validation_2')
    //   }
    // },

    /* Unique Part Selection */
    // {
    //   label: 'Unique Part Selection',
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/unique-part-selection')
    //   }
    // },

    /* Supplier DVA */
    {
      label: 'Supplier DVA and Declaration',
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-collection')
      }
    },

    /* Supplier PLI */
    {
      label: 'Supplier Declaration (Applicable for PLI approved supplier)',
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-declaration-for-pli_tmlbsl')
      } 
    },

    /* Master Data Section */
    {
      label: 'Master Data',
      next: [
        {
          label: 'AFS Master Data',
          next: null,
          level: 2,
          action: () => {
            history.push('/afs_master_data-pv')
          }
        },
        {
          label: 'DVA Master Data',
          next: null,
          level: 2,
          action: () => {
            history.push('/bom-master-data')
          }
        }
      ],
      level: 1,
      action: null
    },

    /* Reports */
    {
      label: 'Reports',
      next: [
        /* AFS Report */
        {
          label: 'AFS Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/AFSMIS')
          }
        },

        /* BOM Validation Report */
        {
          label: 'BOM Validation Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pl-report')
          }
        },

        /* BOM Validation - 2 Report */
        {
          // label: "PL2 Report",
          label: 'BOM Validation - 2 Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pl2-report')
          }
        },

        /* Unique Part Selection Report */
        {
          // label: "PMT BOM Report",
          label: 'Unique Part Selection Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pmt-bom-report')
          }
        },
        // {
        //   label: 'BOM - Report',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/bom-boe-report');
        //   },
        // },

        /* DVA Report */
        {
          // label: "DVA Declaration MIS",
          label: 'DVA Declaration Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/supplier-dva-declaration-mis')
          }
        },
        /* Under PLI Report */
        {
          // label: "Supplier Under PLI Declaration MIS",
          label: 'Supplier Under PLI Declaration Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/supplier-pli-declaration-mis')
          }
        }
      ],
      level: 1,
      action: null
    }
  ]

  /* PMT */
  const rolePMT = [

    /* Project Setup */
    {
      label: 'Project Setup',
      next: [
        {
          label: 'Part Buyer Mapping',
          next: null,
          level: 2,
          action: () => {
            history.push('/part-buyer-mapping')
          }
        },
        {
          label: 'VC SPOC Mapping',
          next: null,
          level: 2,
          action: () => {
            history.push('/vc-spoc-mapping')
          }
        }
      ],
      level: 1,
      action: null
    },

    /* AFS */
    {
      label: 'Annual Financial Details Form',
      next: null,
      level: 2,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },

    /* BOM Validation - 2 */
    {
      label: 'BOM Validation - 2',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-validation_2')
      }
    },

    /* Unique Part Selection */
    {
      label: 'Unique Part Selection',
      next: null,
      level: 1,
      action: () => {
        history.push('/unique-part-selection')
      }
    },

    /* Supplier DVA */
    {
      label: 'Supplier DVA and Declaration',
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-collection')
      }
    },

    /* Supplier PLI */
    {
      label: 'Supplier Declaration (Applicable for PLI approved supplier)',
      next: null,
      level: 1,
      action: () => {
        history.push('/supplier-dva-declaration-for-pli_pmt')
      }
    },

    /* Master Data Section */
    {
      label: 'Master Data',
      next: [
        {
          label: 'AFS Master Data',
          next: null,
          level: 2,
          action: () => {
            history.push('/afs_master_data-pv')
          }
        },
        {
          label: 'DVA Master Data',
          next: null,
          level: 2,
          action: () => {
            history.push('/bom-master-data')
          }
        }
      ],
      level: 1,
      action: null
    },

    /* Reports */
    {
      label: 'Reports',
      next: [
        /* AFS Report */
        {
          label: 'AFS Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/AFSMIS')
          }
        },

        /* BOM Validation Report */
        {
          label: 'BOM Validation Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pl-report')
          }
        },

        /* BOM Validation - 2 Report */
        {
          // label: "PL2 Report",
          label: 'BOM Validation - 2 Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pl2-report')
          }
        },

        /* Unique Part Selection Report */
        {
          // label: "PMT BOM Report",
          label: 'Unique Part Selection Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/pmt-bom-report')
          }
        },
        // {
        //   label: 'BOM - Report',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/bom-boe-report');
        //   },
        // },

        /* DVA Report */
        {
          // label: "DVA Declaration MIS",
          label: 'DVA Declaration Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/supplier-dva-declaration-mis')
          }
        },
        /* Under PLI Report */
        {
          // label: "Supplier Under PLI Declaration MIS",
          label: 'Supplier Under PLI Declaration Report',
          next: null,
          level: 2,
          action: () => {
            history.push('/supplier-pli-declaration-mis')
          }
        }
      ],
      level: 1,
      action: null
    },

    {
      label: "VC Wise DVA Calculation",
      next: null,
      level: 1,
      action: () => {
        history.push('/vcwise-dva-calculation');
      },
    },
    // {
    //   label: "Finance Master",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/finance-master');
    //   },
    // },
    {
      label: "Exempted Data",
      next: null,
      level: 1,
      action: () => {
        history.push('/exempted-data');
      },
    },
    {
      label: "Annexures",
      next: null,
      level: 1,
      action: () => {
        history.push('/annexures');
      },
    },
  ]


  /* PMT */
  const roleBOE = [
    {
      label: 'BOM - BOE',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-boe-data');
      },
    },
    {
      label: 'BOM - Report',
      next: null,
      level: 1,
      action: () => {
        history.push('/bom-boe-report');
      },
    },
  ]

  /* GPA */
  const roleGPA = [
    {
      label: 'MHI Data',
      next: null,
      level: 1,
      action: () => {
        history.push('/mhi-data-submission');
      },
    },
    {
      label: "Finance Master",
      next: null,
      level: 1,
      action: () => {
        history.push('/finance-master');
      },
    },
  ]

  /* Finance */
  const roleFinance = [
    {
      label: "Finance Master",
      next: null,
      level: 1,
      action: () => {
        history.push('/finance-master');
      },
    },
  ]

  useEffect(() => {
    // const role = auth.userRole;
    if (role?.includes("admin", "Purchase", "Business-Finance", "Govt", "TMLBSL", 'PMT')) {
      setColumnsRoot(adminMenu)
    }
    else if (role.includes("Vendor")) {
      setColumnsRoot(vendor)

    }
    else if (role.includes("Vendor-CV")) {
      setColumnsRoot(vendorCV)

    }
    else if (role.includes("Vendor-PV")) {
      setColumnsRoot(vendorPV)
    }
    else if (role.includes("Vendor-EV")) {
      setColumnsRoot(vendorEV)
    }

    else if (role.includes("GDC")) {
      setColumnsRoot(roleGDC)
    }
    else if (role.includes("Purchase")) {
      setColumnsRoot(rolePurchase)
    }
    else if (role.includes("Purchase-CV")) {
      setColumnsRoot(rolePurchaseCV)
    }
    else if (role.includes("Purchase-PV")) {
      setColumnsRoot(rolePurchasePV)
    }
    else if (role.includes("Product-line")) {
      setColumnsRoot(roleProductLine)
    }
    else if (role.includes("TMLBSL")) {
      setColumnsRoot(roleTMLBSL)
    }
    else if (role.includes('PMT')) {
      setColumnsRoot(rolePMT)
    }
    else if (role?.includes('BOE')) {
      setColumnsRoot(roleBOE)
    }
    else if (role?.includes('GPA')) {
      setColumnsRoot(roleGPA)
    }
    else if (role?.includes('Finance')) {
      setColumnsRoot(roleFinance)
    }

    if(auth?.userRole?.length > 0){
      let datas=JSON.stringify(auth.userRole[0])
      localStorage.setItem("pli_role",datas)
    }


  }, [auth.userRole])

  // useEffect(()=>{
  //   if(menu?.menu?.userRoleMenu?.length > 0){
  //     let datas=JSON.stringify(menu.menu.userRoleMenu[0].user_role)
  //     localStorage.setItem("role",datas)
  //   }
  // },[menu])

  // useEffect(() => {
  //   if (
  //     authorizedApps.length &&
  //     !authorizedApps.includes(PPAP_APP_NAME)
  //   ) {
  //     showLoading();
  //     window.location.replace(config.HOME_UI_BASE_URL);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps


  // }, [authorizedApps, user.userID]);





  return (
    <div className={styles.container}>
      <div style={{ marginBottom: '1em' }}>
        <SubHeader title="Production Linked lncentive(PLI)" />
      </div>
      <div className={classFlag == 'center' ? clsx(styles.columnsContainer, styles.centerIMg) : clsx(styles.columnsContainer, styles.rightIMg)}>
        <div style={{ height: '100%' }} className={styles.columnsContainerLeft}>
          <ListView
            title="ACTION"
            listItems={columnsRoot}
            handleSelection={handleSelection}
          />
          {getArrayOfOptionSetsToRender(selectedColumnsQueue).map(
            (listItems, index) => (
              <ListView
                key={`${selectedColumnsQueue[0].label}-${listItems[0]?.level}`}
                title={index === 1 ? 'Action' : 'Select'}
                listItems={listItems}
                handleSelection={handleSelection}
                changeClass={changeClass}
              />
            ),
          )}
        </div>
        {/* <div style={{ height: '100%' }}>
          <ToolsListView
            listItems={toolOptions}
            handleSelection={handleSelection}
          />
        </div> */}
      </div>
    </div>
  )
}

export default LandingPage






// /* eslint-disable vars-on-top */
// /* eslint-disable prefer-const */
// /* eslint-disable no-var */
// /* eslint-disable no-redeclare */
// /* eslint-disable eqeqeq */
// /* eslint-disable array-callback-return */
// /* eslint-disable no-undef */
// /* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux'

// import clsx from 'clsx';

// import { useHistory } from 'react-router-dom';
// import styles from './LandingPage.module.css'
// import { ListView, SubHeader, ToolsListView } from '../../components';
// import { USER_OPERATIONS, PPAP_APP_NAME, PERSONAS, TOOLS } from '../../constants';
// import { useAuthorizationContext } from '../../providers/AuthorizationHandler/AuthorizationHandler';
// import useAuthorizedEpApps from '../../hooks/useAuthorizedEpApps';
// import { usePopupManager } from '../../providers/PopupManager/PopupManager';
// import config from '../../config/config';




// // function LandingPage() {
// const LandingPage = () => {

//   const { isAuthenticated, user, authResources } = useAuthorizationContext();
//   const history = useHistory();

//   const [selectedColumnsQueue, setSelectedColumsQueue] = useState([]);
//   // eslint-disable-next-line no-unused-vars
//   const [columnsRoot, setColumnsRoot] = useState();
//   const [classFlag, setClass] = useState('center');
//   const authorizedApps = useAuthorizedEpApps(isAuthenticated, authResources);
//   const { showLoading } = usePopupManager();
//   const auth = useSelector(state => state.auth);

//   const changeClass=()=>{
//     setClass('right')
//    }
//   const handleSelection = selection =>
//     setSelectedColumsQueue(prev => {
//       changeClass();
//       if (prev.length) {
//         const lastSelection = prev[prev.length - 1];
//         if (lastSelection === selection) return prev;
//         while (prev.length) {
//           const existingTopLevel = prev[prev.length - 1].level;
//           if (existingTopLevel >= selection.level) prev.pop();
//           else break;
//         }
//       }
//       prev.push(selection);
//       return [...prev];
//     });



//   const getArrayOfOptionSetsToRender = queue =>
//     queue.map(item => item.next).filter(item => item);

//   const getToolOptions = () =>
//     [

//       {
//         label: 'Barcode Pattern',
//         action: () => {
//           history.push('/document-master');
//         },
//         authOperation: USER_OPERATIONS.CREATE_PPAP_STAGE_DOCUMENT,
//         testId: 'document-master-btn'
//       },



//     ];

//   const toolOptions = getToolOptions();
//   const urlBaseAndPersonaMap = new Map();
//   urlBaseAndPersonaMap.set(PERSONAS.BUYER, 'buyer');
//   urlBaseAndPersonaMap.set(PERSONAS.VENDOR, 'vendor');
//   urlBaseAndPersonaMap.set(PERSONAS.FBV_VENDOR, 'vendor');
//   urlBaseAndPersonaMap.set(PERSONAS.FBV, 'fbv');
//   const deriveNextLaneForMaterialGroups = (persona, materialGroup) => {
//     const urlBaseForPersona = urlBaseAndPersonaMap.get(persona);
//     if (persona === PERSONAS.BUYER)
//       return [
//         {
//           label: 'View Plan',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push(`/${urlBaseForPersona}/${materialGroup}`);
//           },
//         },
//         {
//           label: 'ASN DETAILS',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push(`/${urlBaseForPersona}/${materialGroup}/asn-details`);
//           },
//         },
//       ];
//     return [
//       {
//         label: 'Plan',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}`);
//         },
//       },
//       {
//         label: 'Drop',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}/drop`);
//         },
//       },
//       {
//         label: 'Dispatch',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}/dispatch`);
//         },
//       },
//     ];
//   };

//   const deriveMaterialGroupLaneFromMaterialGroups = (
//     userPersona,
//     materialGroups,
//   ) =>
//     materialGroups.map(record => ({
//       value: record.materialGroup,
//       label: record.description,
//       level: 2,
//       next: deriveNextLaneForMaterialGroups(userPersona, record.materialGroup), // step 4
//       action: null,
//     }));

//   //  Admin Role
//   const adminMenu = [
    
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },


//     {
//       label: "VC Declaration",
//       next: [
//         {
//           label: 'VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-cv');
//           },
//         },
//         {
//           label: 'VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-pv');
//           },
//         },
//         {
//           label: 'VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor DVA Declaration",
//       next: [
//         {
//           label: 'Vendor DVA Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-data');
//           },
//         },
//         {
//           label: 'Vendor DVA Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-data-pv');
//           },
//         },
//         {
//           label: 'Vendor DVA Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         }

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },


//     {
//       label: "VC MIS",
//       next: [
//         {
//           label: 'VC MIS-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/colour-vc-mis');
//           },
//         },
//         {
//           label: 'VC MIS-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/colour-vc-mis-pv');
//           },
//         },
//         {
//           label: 'VC MIS-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor MIS",
//       next: [
//         {
//           label: 'Vendor MIS-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-mis');
//           },
//         },
//         {
//           label: 'Vendor MIS-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-mis-pv');
//           },
//         },
//         {
//           label: 'Vendor MIS-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },

    
//     {
//       label: "DVA Calculation",
//       next: [
//         {
//           label: 'DVA Calculation CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-cv');
//           },
//         },
//         {
//           label: 'DVA Calculation PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-pv');
//           },
//         },
//         {
//           label: 'DVA Calculation EV',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "MHI Data",
//       next: [
//         {
//           label: 'MHI Data CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-cv');
//           },
//         },
//         {
//           label: 'MHI Data PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-pv');
//           },
//         },
//         {
//           label: 'MHI Data EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },

//     // {
//     //   label: "Audit Report",
//     //   next: [
//     //     {
//     //       label: 'Report-CV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/audit-report-cv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Report-PV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/audit-report-pv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Report-EV',
//     //       next: null,
//     //       level: 1,
//     //       action: () => {
//     //         history.push('/');
//     //       },
//     //     }


//     //   ],
//     //   level: 1,
//     //   action: null
//     // },

//   ]
//   //   Role Vendor CV
//   const vendorCV = [{
//     label: "Vendor DVA Declaration",
//     next: [
//       {
//         label: 'Vendor DVA Declaration-CV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data');
//         },
//       },
      


//     ],
//     level: 1,
//     action: null
//   },];
//   //   Role Vendor PV
//   const vendor = [{
//     label: "Vendor DVA Declaration",
//     next: [
//       {
//         label: 'Vendor DVA Declaration-CV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data');
//         },
//       },

//       {
//         label: 'Vendor DVA Declaration-PV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data-pv');
//         },
//       },
//       {
//         label: 'Vendor DVA Declaration-EV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/');
//         },
//       }


//     ],
//     level: 1,
//     action: null
//   },];
//   const vendorPV = [{
//     label: "Vendor DVA Declaration",
//     next: [

//       {
//         label: 'Vendor DVA Declaration-PV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data-pv');
//         },
//       },


//     ],
//     level: 1,
//     action: null
//   },];


//   //   Role Vendor EV
//   const vendorEV = [{
//     label: "Vendor DVA Declaration",
//     next: [

//       {
//         label: 'Vendor DVA Declaration-EV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/');
//         },
//       }

//     ],
//     level: 1,
//     action: null
//   },];




//   const roleGDC = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     // {
//     //   label: "Vendor DVA Declaration",
//     //   next: [
//     //     {
//     //       label: 'Vendor DVA Declaration-CV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/vendor-data');
//     //       },
//     //     },
//     //     {
//     //       label: 'Vendor DVA Declaration-PV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/vendor-data-pv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Vendor DVA Declaration-EV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/');
//     //       },
//     //     }

//     //   ],
//     //   level: 1,
//     //   action: null
//     // }
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   // purchase

//   const rolePurchase = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   const rolePurchaseCV = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         }, 
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         } 

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         }, 
//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
   
//       ],
//       level: 1,
//       action: null
//     }, 

//   ];

//   const rolePurchasePV = [
//     {
//       label: "Milestone Calendar",
//       next: [
   
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },



//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
   
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
 

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
  
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
  

//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
 
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
   
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   // product line 

//   const roleProductLine = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
 

//     {
//       label: "VC Declaration",
//       next: [
//         {
//           label: 'VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-cv');
//           },
//         },
//         {
//           label: 'VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-pv');
//           },
//         },
//         {
//           label: 'VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
    
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//         // {
//         //   component: 'PLIList',
//         //   url: '/vcbc-list-cv',
//         //   label: "Mass VC Declaration"
//         // },
//         // {
//         //   component: 'PLIListPV',
//         //   url: '/vcbc-list-pv',
//         //   label: "Mass VC Declaration"
//         // },
//         // {
//         //   component: 'PLIListEV',
//         //   url: '/vcbc-list-ev',
//         //   label: "Mass VC Declaration"
//         // },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "DVA Calculation",
//       next: [
//         {
//           label: 'DVA Calculation CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-cv');
//           },
//         },
//         {
//           label: 'DVA Calculation PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-pv');
//           },
//         },
//         {
//           label: 'DVA Calculation EV',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "MHI Data",
//       next: [
//         {
//           label: 'MHI Data CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-cv');
//           },
//         },
//         {
//           label: 'MHI Data PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-pv');
//           },
//         },
//         {
//           label: 'MHI Data EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//   ];



//   useEffect(() => {
//     const role = auth.userRole;
//     if (role.includes("admin", "Purchase", "Business-Finance", "Govt")) {
//       setColumnsRoot(adminMenu)
//     }
//     else if (role.includes("Vendor")) {
//       setColumnsRoot(vendor)

//     }
//     else if (role.includes("Vendor-CV")) {
//       setColumnsRoot(vendorCV)

//     }
//     else if (role.includes("Vendor-PV")) {
//       setColumnsRoot(vendorPV)  
//     }
//     else if (role.includes("Vendor-EV")) {
//       setColumnsRoot(vendorEV) 
//     }

//     else if (role.includes("GDC")) {
//       setColumnsRoot(roleGDC) 
//     }
//     else if (role.includes("Purchase")) {
//       setColumnsRoot(rolePurchase) 
//     }
//     else if (role.includes("Purchase-CV")) {
//       setColumnsRoot(rolePurchaseCV) 
//     }
//     else if (role.includes("Purchase-PV")) {
//       setColumnsRoot(rolePurchasePV) 
//     }
//     else if (role.includes("Product-line")) {
//       setColumnsRoot(roleProductLine)

//     }


//   }, [auth.userRole])

//   // useEffect(() => {
//   //   if (
//   //     authorizedApps.length &&
//   //     !authorizedApps.includes(PPAP_APP_NAME)
//   //   ) {
//   //     showLoading();
//   //     window.location.replace(config.HOME_UI_BASE_URL);
//   //   }
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps


//   // }, [authorizedApps, user.userID]);





//   return (
//     <div className={styles.container}>
//       <div style={{ marginBottom: '1em' }}>
//         <SubHeader title="Production Linked lncentive(PLI)" />
//       </div>
//       <div className={ classFlag=='center'?clsx(styles.columnsContainer,styles.centerIMg):clsx(styles.columnsContainer,styles.rightIMg)}>
//         <div style={{ height: '100%' }} className={styles.columnsContainerLeft}>
//           <ListView
//             title="ACTION"
//             listItems={columnsRoot}
//             handleSelection={handleSelection}
//           />
//           {getArrayOfOptionSetsToRender(selectedColumnsQueue).map(
//             (listItems, index) => (
//               <ListView
//                 key={`${selectedColumnsQueue[0].label}-${listItems[0]?.level}`}
//                 title={index === 1 ? 'Action' : 'Select'}
//                 listItems={listItems}
//                 handleSelection={handleSelection}
//                 changeClass={changeClass}
//               />
//             ),
//           )}
//         </div>
//         {/* <div style={{ height: '100%' }}>
//           <ToolsListView
//             listItems={toolOptions}
//             handleSelection={handleSelection}
//           />
//         </div> */}
//       </div>
//     </div>
//   )
// }

// export default LandingPage
